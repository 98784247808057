import {notification} from 'antd'
import {getToken, customAxios as axios} from '../../../request'
import {
    addCourseUrl,
    listCourseUrl,
    loadCourseIntakesUrl,
    listCourse,
    quickSearchCourse,
    quickSearchLoadMoreCourse,
    exportCourseListUrl,
    courseIsSearchAbleUrl,
    courseIsFeaturedUrl,
    topCourseUpdateUrl,
    courseIntakeListUrl,
    addCourseStructureUrl,
    singleCourseStructureUrl,
    courseStructureListUrl,
    updateCourseStructureUrl,
    deleteCourseStructureUrl
} from '../api/courseApi'
import {showPageLoad, hidePageLoad} from '../../../modules/actions'

export const addCourse = (valData) => async (dispatch) => {
    dispatch({type: 'COURSE_SHOWLOADER'})
    let {data} = await axios.post(addCourseUrl(), valData, getToken())
    if (!data.error) {
        dispatch({type: 'COURSE_HIDELOADER'})

        notification.success({
            message: data.message || 'Success'
        })

    } else {
        dispatch({type: 'COURSE_HIDELOADER'})

        notification.error({
            message: data.message || 'Error'
        })
    }

    return data
}


export const listAllCourse = (filters) => async (dispatch) => {
    dispatch({type: 'COURSE_SHOWLOADER'})
    let config = {
        params: {...filters},
        ...await getToken()
    }
    let {data} = await axios.get(listCourse(), config)
    dispatch({type: 'COURSE_HIDELOADER'})
    if (!data.error) {
        // notification.success({
        //     message: data.message || 'Success'
        // })
        dispatch({type: 'LIST_ALL_COURSE', payload: data.data})

    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    // console.log(data.data)
    return data.data

}
export const getCourse = id => async (dispatch) => {
    dispatch({type: 'COURSE_SHOWLOADER'})
    dispatch({type: 'COURSE_CARD_SHOWLOADER'})
    let config = {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(listCourseUrl(id), config)
    dispatch({type: 'COURSE_HIDELOADER'})
    dispatch({type: 'COURSE_CARD_HIDELOADER'})
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
        dispatch({type: 'COURSE_CARD_HIDELOADER'})

    } else {
        dispatch({type: 'COURSE_CARD_HIDELOADER'})
        dispatch({type: 'LIST_COURSE', payload: data.response})
    }
    return data.data
}
export const updateCourse = (valData, id) => async (dispatch) => {
    dispatch({type: 'COURSE_SHOWLOADER'})
    let {data} = await axios.put(listCourseUrl(id), valData, getToken())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
        dispatch({type: 'COURSE_HIDELOADER'})


    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
        dispatch({type: 'COURSE_HIDELOADER'})

    }
    return data
}
export const listQuickSearchCourse = (filters) => async (dispatch) => {
    dispatch({type: 'COURSE_SHOWLOADER'})
    let config = {
        params: {...filters},
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(quickSearchCourse(), config)
    // console.log(data);
    dispatch({type: 'COURSE_HIDELOADER'})
    dispatch({type: 'LIST_QUICK_SEARCH_COURSE', payload: data})
    return data
}

export const listQuickSearchMoreCourse = (filters) => async (dispatch) => {
    dispatch({type: 'COURSE_SHOWLOADER'})
    let config = {
        params: {...filters},
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(quickSearchLoadMoreCourse(), config)
    dispatch({type: 'COURSE_HIDELOADER'})
    dispatch({type: 'LIST_QUICK_SEARCH_MORE_COURSE', payload: data})
    return data
}


export const loadCourseIntakesFxn = (valData) => async (dispatch) => {
    let {data} = await axios.post(loadCourseIntakesUrl(), valData)
    return data
}


export const exportCourseList = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(exportCourseListUrl(), valData, getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}

export const CourseIsFeatured = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(courseIsFeaturedUrl(), valData, getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}
export const CourseIsSearchAble = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(courseIsSearchAbleUrl(), valData, getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}

export const deleteCourse = (id) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.delete(listCourseUrl(id), getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}
export const topCourseUpdateFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(topCourseUpdateUrl(), valData, getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}

export const courseIntakeListFxn = async (filters) => {
    let config = {
        params: {...filters},
        ...await getToken()
    }
    let {data} = await axios.get(courseIntakeListUrl(), config)
    return data.data

}

export const getSingleCourse = id => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        ...await getToken()
    }
    let {data} = await axios.get(listCourseUrl(id), config)
    dispatch(hidePageLoad())
    return data.response
}

export const addCourseStructureFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addCourseStructureUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const singleCourseStructureFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: valData,
        ...await getToken()
    }
    let {data} = await axios.get(singleCourseStructureUrl(), config)
    dispatch(hidePageLoad())
    return data.response
}

export const updateCourseStructureFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateCourseStructureUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.response
}

export const courseStructureListFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: valData,
        ...await getToken()
    }
    let {data} = await axios.get(courseStructureListUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const deleteCourseStructureFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(deleteCourseStructureUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
