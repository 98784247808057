import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Drawer, Form, Input, notification, Row, Select } from 'antd'
import { InputBox, filterOption } from '../../../components/_utils/appUtils'
import moment from 'moment'
import { DatePicker } from 'antd/es'
import { CountryCodeWithFlag } from '../../../components/_utils/countryUtil'
import { addCallRequest } from '../actions'
import { useDispatch } from 'react-redux'
import { timezones } from '../../../components/_utils/countryUtil'
import momentTimezone from 'moment-timezone'

let { Option } = Select
let initState = {
  name: '',
  countryCode: '',
  phone: '',
  email: '',
  date: moment(),
  timeInterval: '',
  timezone: ''
}
const RequestACallBack = (props) => {
  let { visible, onClose } = props
  let [state, setState] = useState(initState)
  let [dateDisabledFrom, setDateDisabledFrom] = useState(moment())
  let dispatch = useDispatch()

  const convertTime = (zone, callback) => {
    const torontoTime = momentTimezone.tz('2024-05-23 09:00', 'America/Toronto')
    const formattedTime = torontoTime.clone().tz(zone).format('YYYY-MM-DD HH:mm:ss')
    callback(formattedTime)
  }

  useEffect(() => {
    if (state.timezone) {
      convertTime(state.timezone, (time) => {
        setState(prevState => {
          const startTime = moment(time)
          const endTime = startTime.clone().add(5, 'hours')
          return {
            ...prevState,
            timeInterval: `${startTime.format('hh:mm A')} - ${endTime.format('hh:mm A')}`
          }
        })
      })
    }
  }, [state.timezone])

  // let testingTimeZone = () => {
  //   let toronto = 'America/Toronto'
  //   let vancouver = 'America/Vancouver'
  //   let dubai = 'Asia/Dubai'
  //   // let cadTime = moment().hour(9).minute(0)
  //   let cadTime = moment()
  //   console.log(cadTime.format('DD MMM, YYYY hh:mm:a'))
  //
  //   let dateResp = moment.tz('2024-05-23 09:00:00.0000', 'America/Toronto').tz('Asia/Calcutta').format('YYYY/MM/DD HH:mm:ss')
  //   let dateResp5 = moment.tz('2024-05-23 09:15:00.0000', 'America/Toronto').tz(dubai).format('YYYY/MM/DD HH:mm:ss')
  //   let dateRsp1 = moment.tz(cadTime, 'Asia/Calcutta').tz(toronto).format('DD MMM, YYYY hh:mm:a')
  //   let dateRsp2 = moment.tz(cadTime, 'Asia/Calcutta').tz(vancouver).format('DD MMM, YYYY hh:mm:a')
  //   let dateRsp3 = moment.tz(cadTime, dubai).tz('Asia/Calcutta').format('DD MMM, YYYY hh:mm:a')
  //
  //   console.log(dateResp, 'india')
  //   console.log(dateResp5, dubai)
  //   console.log(dateRsp1, toronto)
  //   console.log(dateRsp2, vancouver)
  //   console.log(dateRsp3, dubai)
  //
  // }

  useEffect(() => {
    events.checkDisabledDate()
  }, [state.timezone])

  let events = {
    SubmitRequestACall: async (e) => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err) => {
        if (!err) {
          if (!state.name) {
            return notification.warn({
              message: 'Please enter name.'
            })
          }
          if (!state.email) {
            return notification.warn({
              message: 'Please enter email'
            })
          }
          if (!state.countryCode) {
            return notification.warn({
              message: 'Please select country Code'
            })
          }
          if (!state.phone) {
            return notification.warn({
              message: 'Please enter mobile number'
            })
          }
          if (!state.phone || isNaN(state.phone)) {
            return notification.warn({
              message: 'Please enter a valid mobile number'
            })
          }
          if (!state.timezone) {
            return notification.warn({
              message: 'Please select timezone'
            })
          }
          if (!state.date) {
            return notification.warn({
              message: 'Please enter date'
            })
          }
          if (!state.timeInterval) {
            return notification.warn({
              message: 'Please select time interval'
            })
          }

          let resp = await dispatch(addCallRequest({ ...state }))
          if (resp && resp.success) {
            setState(initState)
            onClose()
          }
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })


    },
    updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    checkDisabledDate: () => {
      if (state.timezone) {
        let currentDate = moment()
        let torontoTime = momentTimezone.tz(currentDate, state.timezone).tz('America/Toronto')
        console.log(torontoTime.format('DD/MM/YYYY hh:mm a'), 'toronto time', torontoTime.hour())
        console.log(currentDate.format('DD/MM/YYYY HH:mm a'), 'date')
        // let torontoTimeString = torontoTime.format('YYYY/MM/DD HH:mm:ss')
        let endTime = torontoTime.hour(13).minutes(45)
        if (currentDate > endTime) {
          events.updateState({date:moment().add(1,'day')})
          setDateDisabledFrom(moment().endOf('day'))
        } else {
          setDateDisabledFrom(moment().subtract(1, 'day').endOf('day'))
        }
      }
    },
    disabledDate: (current) => {
      return current && current < dateDisabledFrom
    }
  }

  const noticeStyle = {
    backgroundColor: '#fffae6',
    border: '1px solid #ffd700',
    borderRadius: '4px',
    color: '#333',
    padding: '10px',
    margin: '10px 0',
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
    textAlign: 'center'
  }

  return (
    <Drawer
      width={'40%'}
      title={'Request A Call Back'}
      placement='right'
      visible={visible}
      onClose={onClose}>
      <Card bordered={false}>
        <Form className={'vertical-form'}>
          <Row className={'row'}>
            <Col className={'col-md-12 mb10'}>
              <InputBox title={'Name'}>
                <Input
                  placeholder={'Name'}
                  value={state.name} name={'name'}
                  className={'form-control'}
                  onChange={({ target }) => {
                    events.updateState({ name: target.value })
                  }}
                />
              </InputBox>
            </Col>
            <Col className={'col-md-12 mb10'}>
              <InputBox title={'Email'}>
                <Input placeholder={'Email'} value={state.email} name={'email'}
                       className={'form-control'}
                       onChange={({ target }) => {
                         events.updateState({ email: target.value })
                       }} />
              </InputBox>
            </Col>
            <Col className={'col-md-4 mb10'}>
              <CountryCodeWithFlag
                countryCode={state.countryCode}
                chooseCode={(val) => {
                  setState({ ...state, countryCode: val })
                }} />
            </Col>
            <Col className={'col-md-8 mb10'} style={{ marginTop: 3 }}>
              <InputBox title={'Mobile Number'}>
                <Input placeholder={'Mobile Number'} value={state.phone} name={'phone'}
                       className={'form-control col-md-12'}
                       onChange={({ target }) => {
                         events.updateState({ phone: target.value })
                       }} />
              </InputBox>
            </Col>
            <Col className={'col-md-8 mb10'}>
              <InputBox title={'Time Zone'}>
                <Select
                  value={state.timezone}
                  showSearch={true}
                  filterOption={filterOption}
                  allowClear={true}
                  onChange={(e) => {
                    setState({ ...state, timezone: e })
                  }}>
                  {timezones.map((tz) => (
                    <Option key={tz.value} value={tz.value}>
                      {tz.name}
                    </Option>
                  ))}
                </Select>
              </InputBox>
            </Col>
            <Col className={'col-md-4 mb10'}>
              <InputBox title={'Date'}>
                <DatePicker
                  disabled={!state.timezone}
                  value={moment(state.date)}
                  disabledDate={events.disabledDate}
                  name={'date'}
                  className={'form-control'}
                  onChange={(date) => {
                    setState({ ...state, date: date._d })
                  }}
                />
              </InputBox>
            </Col>
            {/*<Col className={'col-md-6 mb10'}>
              <InputBox title={'Time Interval'}>
                <select
                  disabled={true}
                  className={'form-control'}
                  value={state.timeInterval}
                  onChange={(e) => {
                    setState({ ...state, timeInterval: e.target.value })
                  }}
                >
                  <option value=''>Select Time Interval</option>
                  {timeInterval.map((tz) => (
                    <option key={tz.interval} value={tz.interval}>
                      {tz.interval}
                    </option>
                  ))}
                </select>
              </InputBox>
            </Col>*/}
            {/* <Col className={'col-md-8 mb10'}>
              <InputBox title={'Time Interval'}>
                <Input placeholder={'Time Interval'} value={state.timeInterval} name={'timeInterval'}
                       className={'form-control'}
                       disabled={true} />
              </InputBox>
            </Col>*/}
            <Col className={'col-md-12'}>
              {
                state.timezone &&
                <div style={noticeStyle}>
                  {/*<h6 style={{ fontWeight: 'bold' }}>Note :- You will receive a call between {state.timeInterval}</h6>*/}
                  <h6 style={{ fontWeight: 'bold' }}>Note: Between {state.timeInterval}, You Will Get A Call</h6>
                </div>
              }
            </Col>

          </Row>
          <div className={'mt20 alignRight'}>
            <Button onClick={onClose} type={'cancel'}>Cancel</Button> {' '}
            <Button type={'success'} onClick={events.SubmitRequestACall}>Submit</Button>
          </div>
        </Form>
      </Card>
    </Drawer>
  )
}

const WrappedHelpComponent = Form.create()(RequestACallBack)
export default WrappedHelpComponent
