import React from 'react'
import '../FrontendComponents/registrationPage.css'
import { Form, Select, Button, Card, notification, Spin, Row, Col, Drawer, Input, Modal } from 'antd'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { useState } from 'react'
import moment from 'moment'
import { SingleEventsFxn, addRegistraionFxn, referenceMarketingFxn, verifyLeadFxn } from './Webinar/action'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ConditionOptions, countryIds, displayDate, displayTime } from '../../components/_utils/appUtils'
import {
  CountryJson
} from '../../components/_utils/countryUtil'


let initModal = {
  message: 'Enter otp to verify Lead.',
  visible: false
}

const singleWebinar = (props) => {
  const { form: { getFieldDecorator, getFieldValue, setFieldsValue } } = props
  const dispatch = useDispatch()
  const [singleEvent, setSingleEvent] = useState([])
  const [referenceList, setRefernceList] = useState([])
  const [eventId, setEventId] = useState('')
  const [open, setOpen] = useState(false)
  const [otp, setOtp] = useState('')
  let [respData, setRespData] = useState({})
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const handleClose = () => {
    setVisible(false)
  }

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }


  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.eventId = eventId
        let resp = await dispatch(addRegistraionFxn(valData))
        if (resp && resp.success) {
          /*if (resp.data.country === 'India') {
            setRespData(resp.data)
            setOpen(true)
          } else {
          }*/

          setVisible(true)
          props.form.setFieldsValue({
            name: '',
            email: '',
            companyName: '',
            address: '',
            country: '',
            mobile: '',
            reference: '',
            persons: '',
            secondPersonName: '',
            designation: '',
            alreadyRegistered: undefined


          })


        } else {
          notification.error({ message: resp.message })
        }
      }
    })
  }

  const verifyOtp = async (e) => {
    const { form } = props
    e.preventDefault()
    if (!otp) {
      notification.warning({ message: 'Enter OTP' })
      return
    }

    let obj = {
      otp,
      agentId: respData.token,
      mobile: respData.mobile
    }
    let resp = await dispatch(verifyLeadFxn(obj))
    if (resp && resp.success) {
      setOpen(false)
      setVisible(true)
      let { data } = resp
      props.form.setFieldsValue({
        name: '',
        email: '',
        companyName: '',
        address: '',
        country: '',
        mobile: '',
        reference: '',
        persons: '',
        secondPersonName: '',
        alreadyRegistered: undefined,
        designation: ''
      })
    } else {
      notification.error({ message: resp.message })
      return
    }

  }

  const getCampusData = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('_id')
    if (id) {
      setEventId(id)
      SingleEventData(id)
    }

  }

  const SingleEventData = async (id) => {

    let data = await dispatch(SingleEventsFxn(id))
    if (data && data._id) {
      setSingleEvent(data)
      setEventId(data._id)
    }

  }


  const handleState = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    let data = await dispatch(referenceMarketingFxn())
    setRefernceList(data)
  }

  useEffect(() => {
    getCampusData()
    handleState()
  }, [])


  let inputTypes = {
    fields: [
      { key: 'name', label: 'Full Name', type: 'text', required: true, placeholder: 'Enter Full Name' },
      { key: 'email', label: 'Your Email', type: 'text', required: true, placeholder: 'Enter Email' },
      {
        key: 'companyName',
        label: 'Company Name',
        type: 'text',
        required: true,
        placeholder: 'Enter Company Name'
      },
      {
        key: 'address',
        label: 'Company Address',
        type: 'text',
        required: true,
        placeholder: 'Enter Company Address'
      },

      {
        key: 'country', label: 'Country', type: 'select', required: true,
        options: CountryJson,
        keyAccessor: x => x.name,
        showSearch: true,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setFieldsValue({
            country: x
          })
        }
      },
      { key: 'mobile', label: 'Phone No', type: 'text', required: true, placeholder: 'Enter Mobile no' },
      {
        key: 'alreadyRegistered',
        label: 'Already Registered with UnizPortal',
        type: 'select',
        required: true,
        options: ConditionOptions,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            alreadyRegistered: x
          })
        }
      },
      {
        key: 'reference',
        label: 'Reference',
        type: 'select',
        required: true,
        showSearch: true,
        hidden: !(singleEvent.showReference) && (singleEvent.showReference) !== true,
        options: referenceList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            reference: x
          })
        }
      },
      {
        key: 'persons',
        label: 'Show Additional Members',
        type: 'select',
        required: true,
        hidden: !(singleEvent.showAdditionalMembers) && (singleEvent.showAdditionalMembers) !== true,
        options: [1, 2],
        keyAccessor: x => x,
        valueAccessor: x => `${x}`,
        onChange: e => {
          setFieldsValue({
            persons: e
          })
        }
      },

      {
        key: 'secondPersonName',
        label: 'Second Person Name',
        type: 'text',
        placeholder: 'Enter Second Person Name',
        required: true,
        hidden: !(getFieldValue('persons') && getFieldValue('persons') !== 1)

      },
      {
        key: 'designation',
        label: 'Second Designation',
        type: 'text',
        required: true,
        hidden: !(getFieldValue('persons') && getFieldValue('persons') !== 1),
        placeholder: 'Enter Designation'
      }

    ]
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <>
      <section className='banner blog-banner'>


        <div className='row align-items-center'>
          <div className='col-lg-12'>
            {/* <div className="content text-center">
        <h1>Meet the experts via <br />our regular webinars</h1>
        <p>In fulfilling our promise to initiate and reinforce networking <br />among our student clientele,
          immigration partners and premier <br />tertiary level institutions, we host webinars where you get the
          chance to establish <br />direct discourse with our expert counselors, immigration consultant
          partners <br /> and representativesfrom plethora of global post-secondary institutions.</p>
      </div> */}
          </div>
          {singleEvent && singleEvent.topBanner && singleEvent && singleEvent.topBanner.path ?
            <div className='col-lg-12'>
              <div className='img text-center'>
                <img src={singleEvent && singleEvent.topBanner &&
                singleEvent.topBanner.path ? singleEvent.topBanner.path : null} alt='no image '
                     width='100%' />
              </div>
            </div> : null}
        </div>
      </section>

      <section className='innovation gap-62 blog-innovation webinar-info'>
        <div className='container'>
          <div className='agent_form'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-container'>
                  <div className='align-center'>
                    <div className='agent-name'>Hey Agent’s
                    </div>
                    <p>Please fill this form to get yourself Registered!</p>
                  </div>

                  {singleEvent.registration && singleEvent.status ?
                    <Form onSubmit={handleSubmit}>
                      <div className=''>

                        <div className=''>
                          <Row gutter={24}>
                            {inputTypes.fields.map((item, key) => {
                              return (
                                <Col span={item.span ? item.span : 24} key={key}>

                                  <GetEachFormFields
                                    item={item}
                                    formItemLayout={formItemLayout}
                                    getFieldDecorator={getFieldDecorator}
                                  />

                                </Col>
                              )
                            })}


                          </Row>
                          <Form.Item>
                            <Button type='primary' htmlType='submit' className='btn_sub'>
                              SUBMIT
                            </Button>
                          </Form.Item>

                        </div>
                      </div>


                    </Form> :
                    <>
                      <div className={'registrationClose'}>
                        <h3>Registration Close</h3>
                      </div>
                    </>
                  }


                  <Modal
                    title='Lead Verification'
                    visible={open}
                    footer={false}

                  >
                    <div className={'text-center'}>
                      <i className={'fa fa-check-circle fa-2x'}
                         style={{ color: '#feb900' }}></i>
                      <h6> A text message with a-4 digit verification
                        code has been sent to your phone
                        number </h6>
                    </div>
                    <br />
                    <div className='mb-4 mt-2'>
                      <input type='text' className='form-control'
                             placeholder='Enter OTP'
                             value={otp}
                             minLength={4}
                             maxLength={4}
                             onChange={({ target }) => {
                               setOtp(target.value)
                             }}
                             name='name' />
                    </div>
                    <div className={'text-right'}>
                      <button
                        className='btn btn-success'
                        onClick={verifyOtp}>Submit
                      </button>
                      <button
                        className='btn btn-info m-lg-2'
                        onClick={handleCancel}>Cancel
                      </button>
                    </div>
                  </Modal>

                  <Modal
                    title='Thank You'
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleClose}
                  >
                    <p>
                      Your registration has been confirmed. You will hear from our support executive within 24 hours.
                      Present your confirmation email at the event to gain entrance.
                    </p>
                  </Modal>
                </div>
              </div>


              <div className='col-md-6'>
                {singleEvent && singleEvent.registrationBanner &&
                singleEvent.registrationBanner.path ?
                  <div className='right_bg'
                       style={{
                         backgroundImage: `url(${singleEvent.registrationBanner.path})`,
                         backgroundSize: 'contain'
                       }}>
                  </div> :
                  <div className='right_bg'
                       style={{ backgroundImage: `url(../assets/image/working_bg.png)` }}>
                  </div>}
              </div>


            </div>
          </div>
        </div>
      </section>

      <section className='section-card'>
        <div className='container'>
          <div className='row info_box ' style={{ backgroundColor: 'white' }}>
            <div className='col-lg-5'>
              <h3> Date:{displayDate(singleEvent.date)}
                <div>
                  Time:{displayTime(singleEvent.time)} Onwards
                </div>
                {singleEvent.location ? <div>Location:{singleEvent.location}</div> : null}
                <br />
              </h3>

            </div>
            <div className='col-lg-7'>
              <p>

                {singleEvent.details}
              </p>
            </div>
          </div>
        </div>
      </section>

    </>


  )
}

export default Form.create()(singleWebinar)
