import React, {useEffect, useRef, useState} from 'react'
import {
    Select,
    Button,
    Tooltip,
    Icon,
    Row,
    Col, Form
} from 'antd'
import {connect, useDispatch} from 'react-redux'
import {
    newFormatDisplayDate, longDisplayDate, filterOption, Intakes, DefaultTablePagination, fcmtUniversity
} from '../../../components/_utils/appUtils'
import {getPushPathWrapper, getUrlPushWrapper} from '../../../routes'
import {TableComp} from 'sz-react-utils-lite'
import {listAllUsers} from '../../users/actions/user'
import {listAllCampus} from "../../campus/actions/campus";
import DocumentDrawer from "../../applications/drawers/studentDocumentDrawer";
import StudentOtherApp from "../../applications/views/StudentOtherApplication";
import AddNote from "../../applications/drawers/createNoteComponent";
import {pendingForVisaAppFxn} from "../actions";
import RevokeComponent from "../../../components/fcmtComponents/revokeComponent";

const statusList = [
    'LOA Generated',
    'File pending for submission',
    'File Submitted'
]
const {Option} = Select
const initialState = {
    applicationList: [],
    applicationObj: {}
}

const OfferActionComponent = (props) => {
    let {file, reCreateOfferLetter, record, events} = props
    let {applications} = record
    return (
        <div>
            {file && file.path ?
                <Tooltip title={'Download Offer Letter'}>
                    <a download={file.fileName} href={file.path}>
                        <img src={'../assets/icons/cloud-computing-download.png'}/>
                    </a></Tooltip> : ''}


        </div>
    )
}

const LoaActionComponent = (props) => {
    let {val} = props
    return (
        val && val.path ?
            <>
                <Tooltip title={'Download LOA'}>
                    <a download={val.fileName} href={val.path}>
                        <img src={'/assets/icons/cloud-computing-download.png'}/>
                    </a></Tooltip>

            </> : ''
    )
}

const RenderComment = (props) => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && <div>
            {item.addedByUserId && item.addedByUserId.name ?
                <div className={'alignRight colorPrimary font12'}>
                    (Added By : {item.addedByUserId.name} )
                </div> : null}
            <div className={hideV ? 'appCommentDotsReplace' : ''}>
                {item && item.text ? item.text : ''}
            </div>
            {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
                {hideV ? <a onClick={() => {
                    setHideV(false)
                }}>Show More</a> : <a onClick={() => {
                    setHideV(true)
                }}>Show Less</a>}
            </div> : ''}

        </div>
    )
}
const PendingForVisaApp = (props) => {
    const [state, setState] = useState(initialState)
    const [studentData, setStudentData] = useState({})
    const [name, setName] = useState('')
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
    const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
    const [totalApplication, setTotalApplication] = useState(0)
    const [currentStatus, setCurrentStatus] = useState('')
    const [status, setStatus] = useState('')
    let {applicationObj} = state
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [campusList, setCampusList] = useState([])
    const [campusId, setCampusId] = useState('')
    const [branchUserId, setBranchUserId] = useState('')
    const [intake, setIntake] = useState('')
    let [courseName, setCourseName] = useState('')
    let [studentShore, setStudentShore] = useState('')
    let [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false)
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    const dispatch = useDispatch()
    const tableAppRef = useRef()

    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await getParamsForApplicationList()

            params = {...params, ...resp, appUniversityId: fcmtUniversity}
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let data = await pendingForVisaAppFxn({
                    ...params,
                    regExFilters: ['name']
                })
                setTotalApplication(data.total)
                setTableSearch(false)
                resolve(data)
            } catch (e) {
            }
        })
    }


    useEffect(() => {
        loadAgentData()
        loadCampusList()
        setFieldByParams()
    }, [])


    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()

        if (resp.campusId) {
            setCampusId(resp.campusId)
        }
        if (resp.status) {
            setStatus(resp.status)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.courseName) {
            setCourseName(resp.courseName)
        }
        if (resp.studentShore) {
            setStudentShore(resp.studentShore)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
        if (resp.name) {
            setName(resp.name)
        }
        if (resp.currentStatus) {
            setCurrentStatus(resp.currentStatus)
        }
    }

    const loadAgentData = async () => {
        let params = {
            results: 12000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }
    const loadCampusList = async () => {
        let searchParams = new URLSearchParams(window.location.search)
        let appUniversityId = searchParams.get('appUniversityId')
        let params = {}
        params.sortField = 'campusName'
        params.sortOrder = 'ascend'
        params.campusUniversity = appUniversityId
        params.results = 50
        params.count = 50
        params.select = ['campusName']
        let {data} = await dispatch(listAllCampus({...params, regExFilters: ['campusAddress', 'campusName']}))
        setCampusList(data)
    }


    const events = {
        reloadFxn: () => {
            tableAppRef.current.reload()
        },
        refreshApplication: () => {
            tableAppRef.current.reload()
        },
        enterName: (value) => {
            setName(value)

        },
        closeOtherAppDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleOtherAppDrawer(false)
        },
        closeAddCommentDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleAddCommentDrawer(false)
        },
        openDocumentDrawer: async (record) => {
            setStudentData(record)
            setVisibleDocumentDrawer(true)
        },
        closeDocumentDrawer: async (record) => {
            setStudentData({})
            setVisibleDocumentDrawer(false)
        },
    }

    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let userId = searchParams.get('userId')
            let name = searchParams.get('name')
            let campusId = searchParams.get('campusId')
            let branchUserId = searchParams.get('branchUserId')

            let countryId = searchParams.get('countryId')
            let status = searchParams.get('status')
            let currentStatus = searchParams.get('currentStatus')
            let agentId = searchParams.get('agentId')
            let courseId = searchParams.get('courseId')
            let note = searchParams.get('note')
            let intake = searchParams.get('intake')
            let toDate = searchParams.get('toDate')
            let fromDate = searchParams.get('fromDate')
            let department = searchParams.get('department')
            let userType = searchParams.get('userType')

            let courseName = searchParams.get('courseName')
            let studentShore = searchParams.get('studentShore')

            let obj = {}
            if (agentId) {
                obj.agentId = agentId
            }
            if (countryId) {
                obj.countryId = countryId
            }
            if (status) {
                obj.status = status
            }
            if (currentStatus) {
                obj.currentStatus = currentStatus
            }
            if (courseId) {
                obj.courseId = courseId
            }
            if (name) {
                obj.name = name
            }
            if (intake) {
                obj.intake = intake
            }
            if (branchUserId) {
                obj.branchUserId = branchUserId
            }

            if (note) {
                obj.note = note
            }
            if (userId) {
                obj.userId = userId
            }
            if (toDate) {
                obj.toDate = toDate
            }
            if (fromDate) {
                obj.fromDate = fromDate
            }
            if (department) {
                obj.department = department
            }
            if (userType) {
                obj.userType = userType
            }


            if (name) {
                obj.name = name
            }

            if (campusId) {
                obj.campusId = campusId
            }
            if (status) {
                obj.status = status
            }
            if (agentId) {
                obj.agentId = agentId
            }

            if (branchUserId) {
                obj.branchUserId = branchUserId
            }
            if (courseName) {
                obj.courseName = courseName
            }
            if (studentShore) {
                obj.studentShore = studentShore
            }

            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let searchParams = new URLSearchParams(window.location.search)
        let obj = {}
        if (name) {
            obj.name = name
        }

        if (campusId) {
            obj.campusId = campusId
        }
        if (status) {
            obj.status = status
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        if (intake) {
            obj.intake = intake
        }
        if (courseName) {
            obj.courseName = courseName
        }
        if (studentShore) {
            obj.studentShore = studentShore
        }
        if (currentStatus) {
            obj.currentStatus = currentStatus
        }
        dispatch(
            getUrlPushWrapper('allPendingForVisaApp', {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)
    }


    const extra = (
        <div className={'mt10'}>
            <Row gutter={12}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round'>
                        <input className='form-control form-control' type='search'
                               placeholder='Search by name...' value={name} onChange={(e) => {
                            events.enterName(e.target.value)
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Status' onChange={(item) => setCurrentStatus(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder={'Current Status'}
                                allowClear={true}
                                value={currentStatus || undefined}>
                            {statusList && statusList.length ? statusList.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>{item}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Status' onChange={(item) => setCampusId(item)}
                                className={'antSelect'}
                                placeholder={'Campus'}
                                allowClear={true}
                                value={campusId || undefined}>
                            {campusList && campusList.length ? campusList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.campusName}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Agent'
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                placeholder={'Agent'}
                                showSearch={true}
                                onChange={(item) => {
                                    setAgentId(item)
                                }}
                                value={agentId || undefined}>
                            {allAgent && allAgent.length ? allAgent.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.companyName}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Intake' onChange={(item) => setIntake(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                showSearch={true}
                                placeholder={'Intake'}
                                value={intake || undefined}>
                            {Intakes && Intakes.length ? Intakes.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>{item}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col span={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <div className='search-box-table round'>
                            <input className='form-control form-control' type='search'
                                   placeholder='course name...' value={courseName} onChange={(e) => {
                                setCourseName(e.target.value)
                            }}/>
                            <img src='/dist/img/search.png' alt=''/>
                        </div>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={12} lg={4}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>

                </Col>


            </Row>

        </div>
    )
    const columns = [

        {
            title: '#',
            key: 'id',
            dataIndex: 'id',
            searchTextName: 'id',
            width: 60,
            render: (item, record, index) => {
                return (
                    <div style={{width: 60}}>
                        {index + 1}
                    </div>
                )
            }
        },
        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            searchTextName: 'applications.id',
            width: 60,
            render: (item) => {
                return (
                    <div style={{width: 60}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>
                        <Tooltip title={'Edit Student'}>
                            <a className={'linkAA'}
                               onClick={() => dispatch(
                                   getPushPathWrapper('student.editStudent', {id: record._id}))
                               }>
                                {item} <Icon type={'edit'}/>
                            </a>
                        </Tooltip>
                        <br/>
                        {newFormatDisplayDate(record.dateOfBirth)}
                        <label className={'label label-tag'}>
                            {record.studentShore ? `(${record.studentShore})` : null}
                        </label>
                    </div>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: (item) => {
                return (
                    <div style={{width: 150}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                    </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 85,
            render: (item, record) => {
                return (
                    <div style={{width: 85}}>
                        {item && item.campusName ? item.campusName : ''}
                    </div>
                )
            }
        },
        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 100,
            render: (val, record) => {
                let {agentId, statusList, status} = record.applications
                return (
                    <div style={{width: 100}}>
                        {val && val.name ? val.name : ''}<br/>
                        <div>
                            Agent : {agentId && agentId.companyName ? agentId.companyName : ''}
                            <br/>
                            {agentId && agentId.mobile ? <> <Icon type={'phone'} style={{
                                position: 'relative',
                                bottom: 3
                            }}/> {agentId.mobile}</> : ''}
                        </div>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </div>
                )
            }
        },
        {
            title: 'Family / Given Name',
            key: 'applications.familyName',
            dataIndex: 'applications.familyName',
            width: 100,
            render: (item, record) => {
                return (
                    <div style={{width: 100}}>
                        {item && item ? <p>Family Name : <p style={{fontWeight: 'bold'}}>{item}</p></p> : ''}
                        {record && record.applications && record.applications.givenName ?
                            <p>Given Name : <p style={{fontWeight: 'bold'}}>{record.applications.givenName}</p>
                            </p> : ''}

                    </div>
                )
            }
        },

        {
            title: 'Conditional Offer Letter',
            key: 'applications.offerReceiveLetter',
            dataIndex: 'applications.offerReceiveLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>

                        <OfferActionComponent file={val} record={record} events={events}/>
                    </div>
                )
            }
        },

        {
            title: 'Download LOA',
            key: 'applications.loaLetter',
            dataIndex: 'applications.loaLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <LoaActionComponent val={val} record={record} events={events}/>
                    </div>
                )
            }
        },
        {
            title: 'Revoke',
            key: 'applications._id',
            dataIndex: 'applications._id',
            width: 150,
            render: (val, record) => {
                return (
                    <div style={{width: 150}}>
                        <RevokeComponent val={val} record={record} reloadFxn={events.reloadFxn} user={user}/>
                    </div>
                )
            }
        },
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 150,
            render: (item, record) => {
                let {applications} = record
                return (
                    <div className={'fcmtDiv'} style={{width: 150}}>
                        {item.includes('File') || item.includes('Visa') || item.includes('Revoked') ? <div>
                            {item}<br/>
                            {applications.statusList && applications.statusList.date ?
                                <div>
                                    Date : {newFormatDisplayDate(applications.statusList.date)}<br/>
                                </div> : null}
                        </div> : null}

                    </div>
                )
            }
        },

        {
            title: 'Marketing',
            dataIndex: 'marketingUser',
            width: 120,
            key: 'marketingUser',
            render: (item, record) => {
                let {marketingUserId} = record.applications
                return (
                    <React.Fragment>
                        {/*  {marketingUserList && marketingUserList.length ?
                            <StudentMarketingUserComponent
                                userId={agentId._id}
                                countryId={universityCountry._id}
                                branchManagers={marketingUserList}/> : null}*/}

                        {marketingUserId && marketingUserId.name ? marketingUserId.name : ""}

                    </React.Fragment>
                )
            }
        },
        {
            title: 'Latest Note',
            key: 'applications.latestComment',
            dataIndex: 'applications.latestComment',
            width: 150,
            render: (val) => {
                return (
                    <div style={{width: 150}}>
                        {val && val.text ? <RenderComment item={val}/> : null}
                    </div>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (val, record) => {
                return (
                    <div className={'actionBtnGroup'}>

                        <div>
                            <a className='btn'
                               onClick={() => events.openDocumentDrawer(record)}>
                                <img src='../dist/img/view.svg' alt=''/>
                            </a>

                        </div>

                    </div>
                )
            }
        }

    ]


    return (
        <React.Fragment>
            <div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center all-student-search'
                                 style={{height: 60}}>
                                <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>Pending Visa / Case Revoked</h5>
                                <div className='search-box-table' style={{backgroundColor: 'transparent'}}>
                                    (Total - {totalApplication})
                                </div>
                                <div className='sort-box-table mark-btn'>
                                    <span className={'sort-box-table-right'}>

                                    </span>
                                </div>
                            </div>
                            <div className='card-body table-responsive'>
                                {extra}
                                <TableComp columns={columns}
                                           rowKey={(item, index) => {
                                               return index
                                           }}
                                           apiRequest={apiRequest}
                                           pagination={{
                                               position: 'top',
                                               pageSizeOptions: ['10', '20', '50'],
                                               defaultPageSize: 10
                                           }}
                                           ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            {visibleOtherAppDrawer ?
                <StudentOtherApp visible={visibleOtherAppDrawer}
                                 applicationObj={state.applicationObj}
                                 onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                                 reloadFxn={events.reloadFxn}
                /> : null}

            {visibleAddCommentDrawer && applicationObj && applicationObj._id ?
                <AddNote visible={visibleAddCommentDrawer} studentData={applicationObj}
                         addedType={'direct'}
                         onClose={() => events.closeAddCommentDrawer()}
                         applicationId={applicationObj.applications._id}/> : null}

            {visibleDocumentDrawer && studentData.applications && studentData.applications._id ?
                <DocumentDrawer onClose={events.closeDocumentDrawer}
                                reloadTable={events.reloadFxn}
                                studentData={studentData}
                                applicationId={studentData.applications._id}
                                studentId={studentData._id}
                                visible={visibleDocumentDrawer}/> : null}
        </React.Fragment>
    )
}
const mapStateToProps = ({global, router, chooseApplication}) => ({})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

const WrappedComponent = Form.create()(PendingForVisaApp)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedComponent)
