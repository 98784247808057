import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { Avatar, Button, Col, Popconfirm, Row, Select, Tooltip } from 'antd'
import {
  commissionCountByStatusFxn,
  getServerDateFxn,
  universityCommissionListFxn,
  updateCommissionStatusFxn
} from '../actions'
import {
  defaultLogoList,
  displayDate,
  filterOption, Intakes, getSemesterList, CommissionStatusList, YearList, longDisplayDate, countryIds
} from '../../../components/_utils/appUtils'
import UpdateStatusDrawer from '../drawers/updateStatusDrawer'
import { listAllCountries } from '../../countries/actions/countries'
import { listAllUniversities } from '../../university/actions/university'
import { getUrlPushWrapper } from '../../../routes'
import ClaimReceivedDrawer from '../ReceiveCommission/claimReceivedDrawer'
import { getBranchManagerAgentList } from '../../users/actions/user'
import RaiseAgentInvoiceDrawer from '../RaiseAgentInvoice/raiseAgentInvoiceDrawer'
import StatusComponent from '../Components/statusComponent'
import ReceivedCommStudentDrawer from '../drawers/receivedCommStudent'
import AgentCommStudentDrawer from '../drawers/agentCommStudent'
import ClaimDrawer from '../ClaimCommission/claimDrawer'
import moment from 'moment'

const { Option } = Select

let initState = {
  status: '',
  visible: false,
  commissionId: ''
}
let initFilter = {
  countryId: '',
  status: '',
  universityId: '',
  intake: '',
  countryList: [],
  universityList: [],
  agentList: [],
  courseName: '',
  semester: ''
}
let claimInit = {
  visible: false,
  record: {}
}
let claimRecInit = {
  visible: false,
  record: {}
}
let raiseInvoiceInit = {
  visible: false,
  record: {}
}
let receivedCommissionInit = {
  visible: false,
  record: {}
}
let agentCommissionInit = {
  visible: false,
  record: {}
}

const ListAllUniversityCommission = (props) => {
  let semesterList = getSemesterList()
  let [totalRecord, setTotalRecord] = useState(0)
  const [itemNo, setItemNo] = useState(1)
  const [state, setState] = useState(initState)
  const [filterState, setFilterState] = useState(initFilter)
  const [tableSearch, setTableSearch] = useState(false)
  const [claimRecState, setClaimRecState] = useState(claimRecInit)
  const [raiseInvoiceState, setRaiseInvoiceState] = useState(raiseInvoiceInit)
  const [receivedCommissionState, setReceivedCommissionState] = useState(receivedCommissionInit)
  const [agentCommissionState, setAgentCommissionState] = useState(agentCommissionInit)
  const [claimState, setClaimState] = useState(claimInit)
  const [countObj, setCountObj] = useState([])
  const tableRef = useRef()
  const dispatch = useDispatch()
  useEffect(() => {
    loadData.loadCountryList()
    loadCommissionCount()
  }, [])
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await events.getParamsForApplicationList()
      let { currentDate } = await getServerDateFxn()
      if (resp.intake) {
        resp['intake.month'] = resp.intake
        delete resp['intake']
      }
      if (resp.intakeYear) {
        resp['intake.year'] = resp.intakeYear
        delete resp['intakeYear']
      }
      params.sortField = 'invoiceDate'
      params.sortOrder = 'descend'
      params.countryId = { $ne: countryIds.canadaOnshore }

      params.invoiceDate = { $lte: moment(currentDate).endOf('day') }
      params = { ...params, ...resp }

      let respData = await dispatch(universityCommissionListFxn({
        ...params,
        regExFilters: ['semester', 'courseName', 'name']
      }))
      setTotalRecord(respData.total)
      resolve(respData)
    })
  }
  const reload = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.reload()
    }
  }
  const events = {
    claimCommission: async (data) => {
      let obj = {
        studentId: data.studentId,
        applicationId: data.applicationId,
        status: 'Claim'
      }
      let resp = await dispatch(updateCommissionStatusFxn(obj))
      if (resp && resp.success) {
        reload()
      }
    },
    updateLocal: (data) => {
      setState({
        ...state,
        ...data
      })
    },
    updateLocalFilter: (data) => {
      try {
        setFilterState({
          ...filterState,
          ...data
        })
      } catch (e) {
      }
    },
    closeDrawer: () => {
      setState({
        ...initState
      })
    },
    getParamsForApplicationList: () => {
      return new Promise((resolve) => {
        let searchParams = new URLSearchParams(window.location.search)
        let countryId = searchParams.get('countryId')
        let agentId = searchParams.get('agentId')
        let universityId = searchParams.get('universityId')
        let name = searchParams.get('name')
        let courseName = searchParams.get('courseName')
        let intake = searchParams.get('intake')
        let semester = searchParams.get('semester')
        let status = searchParams.get('status')
        let intakeYear = searchParams.get('intakeYear')
        let obj = {}
        if (agentId) {
          obj.agentId = agentId
        }
        if (countryId) {
          obj.countryId = countryId
        }
        if (universityId) {
          obj.universityId = universityId
        }
        if (name) {
          obj.name = name
        }
        if (courseName) {
          obj.courseName = courseName
        }
        if (intake) {
          obj.intake = intake
        }
        if (intakeYear) {
          obj.intakeYear = intakeYear
        }
        if (status) {
          obj.status = status
        }
        if (semester) {
          obj.semester = semester
        }
        resolve(obj)
      })
    },
    searchFxn: async () => {
      let obj = {}
      let {
        countryId,
        universityId,
        name,
        courseName,
        agentId,
        intake,
        intakeYear,
        semester,
        status
      } = filterState
      if (countryId) {
        obj.countryId = countryId
      }

      if (name) {
        obj.name = name
      }
      if (courseName) {
        obj.courseName = courseName
      }
      if (universityId) {
        obj.universityId = universityId
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (semester) {
        obj.semester = semester
      }
      if (status) {
        obj.status = status
      }
      if (intake) {
        obj.intake = intake
      }
      if (intakeYear) {
        obj.intakeYear = intakeYear
      }
      dispatch(
        getUrlPushWrapper('commission.universityCommission', {
          ...obj
        }))
      setTableSearch(true)
      setTimeout(() => {
        reload()
      }, 200)
    },
    clearFxn: async () => {
      let obj = {}
      events.updateLocalFilter({
        countryId: '',
        universityId: '',
        name: '',
        intake: '',
        agentId: '',
        courseName: '',
        semester: '',
        intakeYear: '',
        status: ''
      })
      dispatch(
        getUrlPushWrapper('commission.universityCommission', {
          ...obj
        }))
      setTableSearch(true)
      setTimeout(() => {
        reload()
      }, 200)
    },
    showClaimRecDrawer: (record) => {
      setClaimRecState({
        visible: true,
        record: record
      })
    },
    hideClaimRecDrawer: () => {
      setClaimRecState({
        visible: false,
        record: {}
      })
      reload()

    },
    showRaiseInvoiceState: (record) => {
      setRaiseInvoiceState({
        visible: true,
        record: record
      })
    },
    hideRaiseInvoiceState: () => {
      setRaiseInvoiceState({
        visible: false,
        record: {}
      })
      reload()

    },
    showReceivedCommissionDrawer: (record) => {
      setReceivedCommissionState({
        visible: true,
        record: record
      })
    },
    hideReceivedCommissionDrawer: () => {
      setReceivedCommissionState({
        visible: false,
        record: {}
      })
      reload()

    },
    showAgentCommissionDrawer: (record) => {
      setAgentCommissionState({
        visible: true,
        record: record
      })
    },
    hideAgentCommissionDrawer: () => {
      setAgentCommissionState({
        visible: false,
        record: {}
      })
      reload()
    },
    showClaimCommissionDrawer: (record) => {
      setClaimState({
        visible: true,
        record: record
      })
    },
    hideClaimCommissionDrawer: () => {
      setClaimState({
        visible: false,
        record: {}
      })
      reload()

    }
  }

  const loadData = {
    loadCountryList: async () => {
      let params = {
        results: 5000,
        select: ['countryName'],
        sortField: 'countryName',
        sortOrder: 'ascend'
      }
      let { data } = await dispatch(listAllCountries(params))
      let agentResp = await dispatch(getBranchManagerAgentList())
      let obj = {}
      if (data && data.length) {
        obj.countryList = data
      }
      if (agentResp && agentResp.data && agentResp.data.length) {
        obj.agentList = agentResp.data
      }
      events.updateLocalFilter({ ...obj })
    },
    loadUniversityNameList: async (countryId) => {
      let params = {
        results: 5000,
        select: ['universityName'],
        sortField: 'universityName',
        sortOrder: 'ascend',
        regExFilters: ['universityName'],
        showUniversity: [true, false],
        universityCountry: countryId
      }
      let { data } = await dispatch(listAllUniversities(params))
      let obj = {
        universityId: ''
      }
      if (data && data.length) {
        obj.universityList = data
      }
      events.updateLocalFilter({ ...obj, countryId })
    },
    loadAgents: async () => {
      let { data } = await dispatch(getBranchManagerAgentList())
      if (data && data.length) {

      }
    }
  }

  const loadCommissionCount = async () => {
    let { data } = await commissionCountByStatusFxn()
    setCountObj(data)
  }

  let updateStatusFxn = async (commissionId, status) => {
    let obj = {
      commissionId: commissionId,
      status: status
    }
    let resp = await dispatch(updateCommissionStatusFxn(obj))
    if (resp && resp.success) {
      reload()
    }
  }

  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return (
          <a className={'linkPointer'}>
            {(itemNo - 1) * 10 + index + 1}
          </a>
        )
      }
    },
    {
      title: 'App ID',
      key: 'appId',
      dataIndex: 'appId',
      // searchTextName: 'appId',
      width: 80,
      render: (item) => {
        return (
          <div style={{ width: 80 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      width: 120,
      render: (item, record) => {
        return (
          <div style={{ width: 150 }}>
            {item ? displayDate(item) : ''}
          </div>
        )
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 150,
      render: (item, record) => {
        let { studentId } = record
        return (
          <div style={{ width: 150 }}>
            {item}<br />
            (ID : {studentId && studentId.studentId ? studentId.studentId : ''})
          </div>
        )
      }
    },
    {
      title: 'Semester',
      dataIndex: 'semester',
      key: 'semester',
      width: 120
    },
    {
      title: 'Country',
      key: 'countryId',
      dataIndex: 'countryId',
      width: 100,
      render: (item, record) => {
        return (
          <div style={{ width: 100 }}>
            {item && item.countryName ? item.countryName : null}
          </div>
        )
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 170,
      render: (item, record) => {
        return (
          <>
            <StatusComponent record={record} />
          </>
        )
      }
    },
    {
      title: 'University',
      key: 'universityId',
      dataIndex: 'universityId',
      width: 150,
      render: (item, record) => {
        return (
          <div style={{ width: 150 }}>
            {item && item.logo && item.logo.url ?
              <Avatar src={item.logo.url} size={20} />
              : <Avatar src={defaultLogoList.defaultUniversityLogo} size={20} />}
            {item.universityName ? `  ${item.universityName}` : ''}
          </div>
        )
      }
    },

    {
      title: 'Program',
      key: 'courseName',
      dataIndex: 'courseName',
      width: 180,
      render: (item, record) => {
        return (
          <div style={{ width: 180 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Intake',
      key: 'intake',
      dataIndex: 'intake',
      width: 120,
      render: (item, record) => {
        return (
          <div style={{ width: 150 }}>
            {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
          </div>
        )
      }
    },


    {
      title: 'Semester Fee',
      dataIndex: 'semesterFee',
      key: 'semesterFee',
      width: 120,
      render: (item, record) => {
        let { currency } = record
        return (
          <div style={{ width: 150 }}>
            {item}
            {currency ? ` ${currency}` : ''}
          </div>
        )
      }
    },
    {
      title: 'Commission',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      render: (item, record) => {
        let { currency } = record
        return (
          <div style={{ width: 150 }}>
            {item}
            {currency ? ` ${currency}` : ''}
          </div>
        )
      }
    },
    {
      title: 'Received Comm.',
      dataIndex: 'studentCommission',
      key: 'studentCommission',
      width: 150,
      render: (item, record) => {
        let { currency, currencyRate } = record
        return (
          item && <div style={{ width: 150 }}>
            {currency == 'INR' ?
              <>
                {item}
                {currency ? ` ${currency}` : ''}
              </> :
              <>
                {item} {currency} (Rate : {currencyRate})
              </>}

            <div className={'view-btn-box'}>
              <Tooltip title={'View Student List'}>
                <a className='btn-view' onClick={() => {
                  events.showReceivedCommissionDrawer(record)
                }}>
                  <img src='../uniBtn/eye.png' alt='' className={'eyeBtn'} /> View
                </a>
              </Tooltip>
            </div>
          </div>
        )
      }
    },
    {
      title: 'Agent Comm.',
      dataIndex: 'commission',
      key: 'commission',
      width: 150,
      render: (item, record) => {
        let { currency, currencyRate, invoiceAddress } = record
        return (
          item && <div style={{ width: 150 }}>
            {currency == 'INR' ?
              <>
                {item}
                {currency ? ` ${currency}` : ''}
              </> :
              <>
                {item} {currency} (Rate : {currencyRate})
              </>}

            <div className={'colorPrimary font12 textCap'}>
              {invoiceAddress}
            </div>

            <div className={'view-btn-box'}>
              <Tooltip title={'View Student List'}>
                <a className='btn-view' onClick={() => {
                  events.showAgentCommissionDrawer(record)
                }}>
                  <img src='../uniBtn/eye.png' alt='' className={'eyeBtn'} /> View
                </a>
              </Tooltip>
            </div>
          </div>
        )
      }
    },
    {
      title: 'Paid Date',
      key: 'invoicePaidDate',
      dataIndex: 'invoicePaidDate',
      width: 160,
      render: (item, record) => {
        return (
          <div style={{ width: 160 }}>
            <div>{longDisplayDate(record.createdAt)}</div>
          </div>
        )
      }
    },

    {
      title: 'Offer S.ID',
      dataIndex: 'offerLetterStudentId',
      key: 'offerLetterStudentId',
      width: 100,
      render: (item, record) => {
        let { commissionDetails } = record
        return (
          <div style={{ width: 100 }}>
            {commissionDetails.offerLetterStudentId ? commissionDetails.offerLetterStudentId : ''}
          </div>
        )
      }
    },

    /* {
         title: 'Tuition Fee',
         dataIndex: 'tuitionFee',
         key: 'tuitionFee',
         render: (item, record) => {
             let {currency, commissionDetails} = record;
             return (
                 <>
                     {commissionDetails.tuitionFee ? commissionDetails.tuitionFee : ""}
                     {currency ? ` ${currency}` : ""}
                 </>
             )
         }
     },*/
    {
      title: 'Agent',
      key: 'agentId',
      dataIndex: 'agentId',
      width: 220,
      render: (item, record) => {
        return (
          <div style={{ width: 180 }}>
            {item && item.name ? item.name : null}
            {item && item.companyName ? `(${item.companyName})` : null}
          </div>
        )
      }
    },
    {
      title: 'Added By',
      key: 'addedByUser',
      dataIndex: 'addedByUser',
      width: 160,
      render: (item, record) => {
        return (
          <div style={{ width: 160 }}>
            <div className={'colorPrimary font12 textCap'}>
              {item && item.name ? item.name : null}
            </div>
            <div>{longDisplayDate(record.createdAt)}</div>
          </div>
        )
      }
    },

    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: 220,
      fixed: 'right',
      render: (item, record) => {
        let { status } = record
        return (
          <div className={'comBtnGroup'}>
            {status == 'Pending' ?
              <>
                {/* <Popconfirm title={'Are your sure, you want to Claim this Commission?'}
                                            onConfirm={() => }
                                            okText='Yes' cancelText='No'>

                                </Popconfirm>*/}

                <a className={'btn btn-warning btn-sm'}
                   onClick={() => events.showClaimCommissionDrawer(record)}>
                  <img src='../uniBtn/call-to-action.png' />
                  Claim Commission
                </a>

                <a className={'btn btn-danger btn-sm'} onClick={() => {
                  events.updateLocal({
                    visible: true,
                    status: 'Commission Cancelled',
                    commissionId: record._id
                  })
                }}>
                  <img src='../uniBtn/cancel.png' />
                  Cancel Commission
                </a>
              </> : ''}
            {/* {status == 'Commission Claimed' ?
                            <>
                                <a className={'btn btn-success btn-sm'} onClick={() => {
                                    events.showClaimRecDrawer(record)
                                }}>
                                    <img src='../uniBtn/check.png'/>
                                    Receive Commission
                                </a>
                                <a className={'btn btn-danger btn-sm'} onClick={() => {
                                    events.updateLocal({
                                        visible: true,
                                        status: "Commission Cancelled",
                                        commissionId: record._id
                                    })
                                }}>
                                    <img src='../uniBtn/cancel.png'/>
                                    Cancel Commission
                                </a>
                            </> : ""}
*/}
            {status == 'Commission Received' ?
              <>
                <a className={'btn btn-info btn-sm'} onClick={() => {
                  events.showRaiseInvoiceState(record)
                }}>
                  <img src='../uniBtn/invoiceRaise.png' />
                  Raise Agent Invoice
                </a>
              </> : ''}
            {/* {status == 'Agent Invoice Raised' ?
                            <>
                                <a className={'btn btn-paid btn-sm'} onClick={() => {
                                }}>
                                    <img src='../uniBtn/invoicePaid.png'/>
                                    Pay Agent Invoice
                                </a>
                            </> : ""}*/}
          </div>
        )
      }
    }
  ]

  const FilterComponent = (
    <Row gutter={16} className={'mt20'}>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select name='Search By Country'
                  onChange={(item) => {
                    // events.updateLocalFilter({countryId: item})
                    loadData.loadUniversityNameList(item)
                  }}
                  filterOption={filterOption}
                  className={'antSelect'}
                  placeholder='Country'
                  allowClear={true}
                  showSearch={true}
                  value={filterState.countryId || undefined}>
            {filterState.countryList && filterState.countryList.length ?
              filterState.countryList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>{item.countryName}</Option>
                )
              }) : null}
          </Select>
        </div>
      </Col>


      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select name='Search By University' onChange={(item) => {
            events.updateLocalFilter({ universityId: item })
          }}
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  showSearch={true}
                  placeholder={'University'}
                  value={filterState.universityId || undefined}>
            {filterState.universityList && filterState.universityList.length ? filterState.universityList.map((item, key) => {
              return (
                <Option value={item._id} key={key}>{item.universityName}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select name='Intake' onChange={(item) => {
            events.updateLocalFilter({ intake: item })
          }}
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  showSearch={true}
                  placeholder={'Intake Month'}
                  value={filterState.intake || undefined}>
            {Intakes && Intakes.length ? Intakes.map((item, key) => {
              return (
                <Option value={item} key={key}>{item}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select name='Intake' onChange={(item) => {
            events.updateLocalFilter({ intakeYear: item })
          }}
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  showSearch={true}
                  placeholder={'Intake Year'}
                  value={filterState.intakeYear || undefined}>
            {YearList && YearList.length ? YearList.map((item, key) => {
              return (
                <Option value={item} key={key}>{item}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select name='Search By Agent'
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  placeholder={'Agent'}
                  showSearch={true}
                  onChange={(item) => {
                    events.updateLocalFilter({ agentId: item })
                  }} value={filterState.agentId || undefined}>
            {filterState.agentList && filterState.agentList.length ? filterState.agentList.map((item, key) => {
              return (
                <Option value={item._id} key={key}>{item.companyName}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table custom-sort-box-new'>
          <Select name='Search By Semester' onChange={(item) => {
            events.updateLocalFilter({ semester: item })
          }}
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  placeholder={'Semester'}
                  value={filterState.semester || undefined}>
            {semesterList && semesterList.length ? semesterList.map((item, key) => {
              return (
                <Option value={item} key={key}>{item}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table custom-sort-box-new'>
          <Select name='Search By Status' onChange={(item) => {
            events.updateLocalFilter({ status: item })
          }}
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  placeholder={'Status'}
                  value={filterState.status || undefined}>
            {CommissionStatusList && CommissionStatusList.length ? CommissionStatusList.map((item, key) => {
              return (
                <Option value={item} key={key}>{item}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input className='form-control form-control' type='search'
                 placeholder='Program Name...' value={filterState.courseName} onChange={(e) => {
            events.updateLocalFilter({ courseName: e.target.value })
          }} />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input className='form-control form-control' type='search'
                 placeholder='Name...' value={filterState.name} onChange={(e) => {
            events.updateLocalFilter({ name: e.target.value })
          }} />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>


      <Col md={6} sm={6} xs={8} lg={6}>
        <Button onClick={() => events.searchFxn()} className={'roundBtn'}>Search</Button>
        <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
          Clear
        </Button>
      </Col>
    </Row>
  )

  const GroupingTags = (
    <>
      <Row gutter={20} className='mt-2 groupingTags'>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className={'rounded label-info p-2 text-white'}>
            <div className='d-flex align-items-center'>
              <img src='../uniBtn/wall-clock.png' alt='To Be Claimed' />
              <span> {countObj['Pending'] ? countObj['Pending'] : 0}</span>
            </div>
            To Be Claimed
          </div>
        </Col>

        <Col md={4} sm={4} xs={8} lg={4}>
          <div className={'rounded label-success p-2 text-white'}>
            <div className='d-flex align-items-center'>
              <img src='../uniBtn/call-to-action.png' alt='Commission Claimed' />
              <span> {countObj['Commission Claimed'] ? countObj['Commission Claimed'] : 0}</span>
            </div>
            Commission Claimed
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className={'rounded label-info-new p-2 text-white'}>
            <div className='d-flex align-items-center'>
              <img src='../uniBtn/check.png' alt='Commission Received' />
              <span> {countObj['Commission Received'] ? countObj['Commission Received'] : 0}</span>
            </div>
            Commission Received
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className={'rounded label-warning p-2 text-white'}>
            <div className='d-flex align-items-center'>
              <img src='../uniBtn/invoiceRaise.png' alt='Agent Invoice Raised' />
              <span> {countObj['Agent Invoice Raised'] ? countObj['Agent Invoice Raised'] : 0}</span>
            </div>
            Agent Invoice Raised
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className={'rounded label-success-green p-2 px-3 text-white'}>
            <div className='d-flex align-items-center'>
              <img src='../uniBtn/invoicePaid.png' alt=' Agent Invoice Paid' />
              <span> {countObj['Agent Invoice Paid'] ? countObj['Agent Invoice Paid'] : 0}</span>
            </div>
            Agent Invoice Paid
          </div>
        </Col>
      </Row>
    </>
  )

  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <h5>All University Commission: {totalRecord || 0}</h5>

            <div className='sort-box-table mark-btn mark-btn40'>

            </div>
          </div>
          <div className='card-body table-responsive'>
            {FilterComponent}
            {GroupingTags}

            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       pagination={{
                         position: 'top',
                         pageSizeOptions: ['50', '100', '200', '500'],
                         defaultPageSize: 50
                       }}
                       ref={tableRef}
                       extraProps={{ scroll: { x: 1800 } }}
            />
          </div>
        </div>
      </div>
      {state.visible ?
        <UpdateStatusDrawer
          visible={state.visible}
          onClose={events.closeDrawer}
          onSubmit={reload}
          status={state.status}
          commissionId={state.commissionId}
        /> : null}

      {/*{claimRecState.visible ?
                <ClaimReceivedDrawer
                    visible={claimRecState.visible}
                    onClose={events.hideClaimRecDrawer}
                    onSubmit={reload}
                    record={claimRecState.record}
                /> : null}*/}

      {raiseInvoiceState.visible ?
        <RaiseAgentInvoiceDrawer
          visible={raiseInvoiceState.visible}
          onClose={events.hideRaiseInvoiceState}
          onSubmit={reload}
          record={raiseInvoiceState.record}
        /> : null}

      {receivedCommissionState.visible ?
        <ReceivedCommStudentDrawer
          visible={receivedCommissionState.visible}
          onClose={events.hideReceivedCommissionDrawer}
          onSubmit={reload}
          record={receivedCommissionState.record}
        /> : null}

      {agentCommissionState.visible ?
        <AgentCommStudentDrawer
          visible={agentCommissionState.visible}
          onClose={events.hideAgentCommissionDrawer}
          onSubmit={reload}
          record={agentCommissionState.record}
        /> : null}

      {claimState.visible ?
        <ClaimDrawer
          visible={claimState.visible}
          onClose={events.hideClaimCommissionDrawer}
          onSubmit={reload}
          record={claimState.record}
        /> : null}
    </div>
  )
}

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListAllUniversityCommission)
