import React, { useState } from 'react'
import { Drawer, notification, Card, Button } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import { updateFcmtAgentStatusFxn } from '../actions'
import { useDispatch } from 'react-redux'

let initState = {
  status: '',
  reason: '',
  commission: ''
}
let statusList = [
  { name: 'Approved', value: 'Approved' },
  { name: 'Rejected', value: 'Rejected' }
]
const UpdateStatusDrawer = (props) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  let dispatch = useDispatch()
  let { visible, onClose, agentId, onSubmit } = props
  let [state, setState] = useState(initState)
  let events = {
    _updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    submitHandle: async (e) => {
      e.preventDefault()
      if (!state.status) {
        notification.warning({ message: 'Choose status.' })
        return
      }
      if ((user.userType == 'fcmtBranchUser' || user.userType == 'fcmtMarketingManager') && state.status == 'Approved') {
        if (!state.commission) {
          notification.warning({ message: 'Enter commission.' })
          return
        }
        if (state.commission && state.commission > 100) {
          notification.warning({ message: 'Enter valid commission.' })
          return
        }
      }
      if (state.status == 'Rejected' && !state.reason) {
        notification.warning({ message: 'Enter reason.' })
        return
      }

      let resp = await dispatch(updateFcmtAgentStatusFxn({ ...state, agentId }))
      if (resp && resp.success) {
        notification.success({ message: resp.message })
        onSubmit()
      }
    }
  }
  return (
    <>
      <Drawer title={'Approved/Reject Agent'} width={'35%'} onClose={onClose} visible={visible}>
        <Card>
          <InputBox title={'Status'}>
            <select
              className='form-control'
              onChange={({ target }) => {
                events._updateState({ status: target.value })
              }}
              value={state.status}>
              <option value={''}>Choose Status</option>
              {statusList && statusList.length ? statusList.map((item, key) => {
                return (
                  <option key={key} value={item.value}>{item.name}</option>
                )
              }) : null}
            </select>
          </InputBox>
          {(user.userType == 'fcmtBranchUser' || user.userType == 'fcmtMarketingManager') && state.status == 'Approved' ?
            <InputBox title={'Commission'}>
              <input className='form-control' type={'number'}
                     onChange={({ target }) => {
                       events._updateState({ commission: target.value })
                     }}
                     value={state.commission}>
              </input>
            </InputBox> : null}
          <InputBox title={'Reason'}>
                        <textarea
                          className='form-control'
                          onChange={({ target }) => {
                            events._updateState({ reason: target.value })
                          }}
                          value={state.reason}>
                        </textarea>
          </InputBox>
          <Button
            onClick={events.submitHandle}
            type='primary'
            className={`btn btn-default mt10`}>
            Update
          </Button>
        </Card>
      </Drawer>
    </>
  )
}
export default UpdateStatusDrawer
