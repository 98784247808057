import {
  Form, Icon, Modal, notification, Popconfirm, Table, Tooltip
} from 'antd'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { displayDate, statusColors } from '../../components/_utils/appUtils'
import { addCollaborationAction, updateCollaborationAction, userTravelPlanListFxn } from './actions'
import { push } from 'connected-react-router'
import TextArea from 'antd/es/input/TextArea'


const TravelPlan = () => {
  const [total, setTotal] = useState(0)
  let tableRef = useRef()
  const dispatch = useDispatch()
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null

  const [collaborationModal, setAddCollaborationModal] = useState({
    visible: false,
    id: '',
    userId:''
  })
  const initialState = {
    visible: false,
    travelPlanId: '',
    user: '',
    status: ''
  }
  const [detailsModal, setDetailsModal] = useState(initialState)
  const [cancelModal, setCancelModal] = useState(initialState)
  const [details, setDetails] = useState('')
  const [reason, setReason] = useState('')
  const [userData, setUserData] = useState([])
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 150,
      render: item => {
        return item ? displayDate(item) : ''
      }
    },
    {
      title: 'From State',
      dataIndex: 'state',
      searchTextName: 'state',
      key: 'state'
    },
    {
      title: 'From City',
      dataIndex: 'city',
      searchTextName: 'city',
      key: 'city'
    },
    {
      title: 'From Place',
      dataIndex: 'fromPlace',
      searchTextName: 'fromPlace',
      key: 'fromPlace'
    },

    {
      title: 'To State',
      dataIndex: 'toState',
      searchTextName: 'toState',
      key: 'toState'
    },
    {
      title: 'To City',
      dataIndex: 'toCity',
      searchTextName: 'toCity',
      key: 'toCity'
    },
    {
      title: 'To Place',
      dataIndex: 'toPlace',
      searchTextName: 'toPlace',
      key: 'toPlace'
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 80,
      render: (text, record) => {
        return (
          <React.Fragment>
            <>
                  <Tooltip title='Collaborating Requests'>
                    <button
                      className={'btn'}
                      style={{ marginRight: 6 }}
                      onClick={() => {
                        setAddCollaborationModal({ visible: true, id: record._id, userId: record.userId })
                        setUserData(record.users)
                      }}>
                      <Icon type={'user-add'} />
                    </button>
                  </Tooltip>

            {/*  {
                user._id == record.userId._id ?
                  <Tooltip title='Collaborating Requests'>
                    <button
                      className={'btn'}
                      style={{ marginRight: 6 }}
                      onClick={() => {
                        setAddCollaborationModal({ visible: true, id: record._id })
                        setUserData(record.users)
                      }}>
                      <Icon type={'user-add'} />
                    </button>
                  </Tooltip> : null
              }*/}

              {
                user._id != record.userId._id ?
                  <Tooltip title='Add Collaboration'>
                    <Popconfirm
                      title={'Are your sure, you want to Collaborate with this user?'}
                      onConfirm={() => {
                        addCollaborationFxn(record._id)
                      }}
                      okText='Yes'
                      cancelText='No'>
                      <button className='btn'>
                        <Icon type={'plus'} />
                      </button>
                    </Popconfirm>
                  </Tooltip> : null
              }
            </>
          </React.Fragment>
        )
      }
    }
  ]
  const columns2 = [
    {
      title: 'Users',
      dataIndex: 'user',
      key: 'user',
      width: 150,
      render: item => {
        return item ? item.name : ''
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (item, record) => {
        return (
          <React.Fragment>
            <div style={{width: 150}}>
              <div
                className={'label label1'}
                style={{
                  background: statusColors(item),
                  width: 80,
                  padding: 3
                }}>
                {item}
              </div>
            </div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 80,
      render: (text, record) => {
        return (
          <React.Fragment>
            {(record.status == 'pending' && collaborationModal.userId._id == user._id) &&
            <>
              <Tooltip title='Approve Collaboration'>
                <button className='btn'
                        onClick={() => {
                          setDetailsModal({
                            visible: true,
                            travelPlanId: collaborationModal.id,
                            user: record.user._id,
                            status: 'approved'
                          })
                        }}
                >
                  <Icon type={'check'} />
                </button>
              </Tooltip>

              <Tooltip title='Reject Collaboration'>
                  <button className='btn'
                          onClick={() => {
                            setCancelModal({
                              visible: true,
                              travelPlanId: collaborationModal.id,
                              user: record.user._id,
                              status: 'rejected'
                            })
                          }}>
                    <Icon type={'cross'} />
                  </button>
              </Tooltip>
            </>

            }
          </React.Fragment>
        )
      }
    }


  ]

  const apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await dispatch(userTravelPlanListFxn({ ...params, userId: user._id }))
      setTotal(resp.total)
      resolve(resp)
    })
  }

  const addCollaborationFxn = async (id) => {
    await dispatch(addCollaborationAction({ travelPlanId: id }))
  }

  const approveCollaborationFxn = async (travelPlanId, userId, status) => {
    const valaData = {
      travelPlanId: travelPlanId,
      user: userId,
      status: status
    }
    if (!details){
      notification.warning({
        message:"Please Enter Details"
      })
      return
    }
    if (details){
      valaData.details = details
    }
    const x = await dispatch(updateCollaborationAction(valaData))
    if (x.success) {
      setAddCollaborationModal({
        visible: false,
        id: ''
      })
      setCancelModal(initialState)
      setDetailsModal(initialState)
      tableRef.current.reload()
    }
  }

  const rejectCollaborationFxn = async (travelPlanId, userId, status) => {
    const valaData = {
      travelPlanId: travelPlanId,
      user: userId,
      status: status
    }
    if (!reason){
      notification.warning({
        message:"Please Enter Reason"
      })
      return
    }
    if (reason){
      valaData.reason = reason
    }
    const x = await dispatch(updateCollaborationAction(valaData))
    if (x.success) {
      setAddCollaborationModal({
        visible: false,
        id: ''
      })
      setCancelModal(initialState)
      setDetailsModal(initialState)
      tableRef.current.reload()
    }
  }


  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Travel Plans ({total})</h5>
              <div className='search-box-table round' />
              <div className='sort-box-table mark-btn'>
                <a className='btn' onClick={() => dispatch(push('/travelPlan/add-travel-plan'))}>
                  <img src={'../dist/icons/plus.png'} className={'plus'} /> Add Travel Plan</a>
              </div>
            </div>
            <div className='card-body table-responsive'>
              <TableComp
                columns={columns}
                apiRequest={apiRequest}
                ref={tableRef}
                defaultSortOrder='descend'
              />
            </div>
            {
              collaborationModal.visible ? (
                <Modal
                  width={'40%'}
                  title='Collaboration Requests'
                  visible={collaborationModal.visible}
                  onCancel={() => {
                    setAddCollaborationModal({ ...collaborationModal, visible: false })
                  }}
                >
                  <Table
                    columns={columns2}
                    size={'small'}
                    bordered
                    dataSource={userData}
                    pagination={false}
                  />

                </Modal>
              ) : null
            }
            {
              detailsModal.visible ? (
                <Modal
                  title='Enter Detail'
                  visible={detailsModal.visible}
                  onOk={() => {
                    approveCollaborationFxn(detailsModal.travelPlanId, detailsModal.user , detailsModal.status)
                  }}
                  onCancel={() => {
                    setDetailsModal({ ...detailsModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                    placeholder='Enter Details..'
                    required={true}
                  />
                </Modal>
              ) : null
            }
            {
              cancelModal.visible ? (
                <Modal
                  title='Enter Reason'
                  visible={cancelModal.visible}
                  onOk={() => {
                    rejectCollaborationFxn(cancelModal.travelPlanId, cancelModal.user , cancelModal.status)
                  }}
                  onCancel={() => {
                    setCancelModal({ ...cancelModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder='Enter reason for Cancel'
                    required={true}
                  />
                </Modal>
              ) : null
            }
          </div>
        </div>
      </div>
    </>
  )
}

const WrappedTravelPlan = Form.create()(TravelPlan)
export default WrappedTravelPlan
