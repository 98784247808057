import React, { useEffect, useState } from 'react'
import { Popconfirm, Tooltip, Tag, Modal } from 'antd'
import UploadTuitionFees from './uploadTuitionFees'
import UploadTravelDocument from './uploadTravelDocument'
import {
  closeTuitionFeesDocumentFxn,
  updateTuitionFeesDirectUniversityFxn,
  verifyTuitionFeesDocumentFxn
} from '../actions/fcmtAction'
import { useDispatch } from 'react-redux'
import UploadedTuitionFeesList from './uploadedTuitionFeesList'
import { departmentObj, displayDate, newFormatDisplayDate } from '../../../components/_utils/appUtils'
import _ from 'lodash'

let init = {
  visible: false,
  applicationId: '',
  studentId: ''
}

const AgentUniversityDealClose = (props) => {
  let { dealClose, universityId } = props
  let [dealAmount, setDealAmount] = useState(0)
  let findDealFxn = () => {
    let findDoc = _.find(dealClose, (item) => {
      return item.universityId == universityId
    })
    if (findDoc) {
      setDealAmount(findDoc.amount)
    }
  }
  useEffect(() => {
    findDealFxn()
  }, [dealClose])
  return (
    dealAmount ? <label className={'label label-default xs'}>
      Deal : CAD {dealAmount}
    </label> : null
  )
}

const allowReGenerateEnrollment = (user) => {
  let { email, userType } = user
  return (userType == 'admin' || email == 'ashu@unizportal.com')
}
const showToUser = (user) => {
  let { email, userType } = user
  return (userType == 'admin' || email == 'sandeep@unizportal.com')
}
const showUploadTT = (user) => {
  let { email, userType, department } = user
  return (userType == 'admin' || email == 'ashu@unizportal.com' || userType == 'marketingManager' || department == departmentObj.marketing || department == departmentObj.offerDepartment)
}
const UploadedDocuments = (props) => {
  let { applications, _reGenerateEnrollmentFunc, user } = props
  let {
    academicSchedule,
    enrolmentLetter,
    enrollmentLetter,
    verifiedTuitionFeeDocuments,
    tuitionFeeDocumentStatus
  } = applications
  return (
    <>
      {verifiedTuitionFeeDocuments ? (
        <div className={'mb10 mt10 alignCenter'}>
          {tuitionFeeDocumentStatus == 'Approved' ?
            <>
              <img src={'/assets/icons/doneCheck.png'} style={{ height: 30 }} />
              <div className={'mt5'}>
                Approved
              </div>
            </> :
            <>
              {/*<img src={'/assets/icons/pendingCheck.png'} style={{height:30}}/>*/}
              <div className={'mt5'}>
                Pending
              </div>
            </>
          }
        </div>
      ) : null}


      <div className={'fcmtDiv alignCenter'}>
        {enrolmentLetter && enrolmentLetter.path ? <>
          <Tag><a href={enrolmentLetter.path} target={'_blank'}>Enrolment Letter</a></Tag>
          <br />
        </> : null}

        {enrollmentLetter && enrollmentLetter.path ? <>
          <Tag><a href={enrollmentLetter.path} target={'_blank'}>Enrolment Letter</a></Tag>
        </> : null}
        <br />
        {academicSchedule && academicSchedule.path ? <>
          <Tag style={{ marginTop: 5 }}><a href={academicSchedule.path} target={'_blank'}>Academic Schedule</a></Tag>
        </> : null}

        {enrollmentLetter && enrollmentLetter.path && allowReGenerateEnrollment(user) ?
          <div className={'mt10'}>
            <Popconfirm
              title={'Are you sure, you want to Re-Generate Enrolment Letter?'}
              okText={'Yes'}
              cancelText={'No'}
              onConfirm={() => {
                _reGenerateEnrollmentFunc()
              }}>
              <a className={'btn btn-info btn-xs'}>
                Re-generate
              </a>
            </Popconfirm>
          </div> : null}

      </div>
    </>
  )
}

const UploadTTComponent = (props) => {
  let dispatch = useDispatch()
  let { student, reloadTable, user } = props
  let { applications } = student
  let { tuitionFeeDocumentStatus, verifiedTuitionFeeDocuments, tuitionFeeDocument, closedTT } = applications
  let [state, setState] = useState(init)
  let [travelState, setTravelState] = useState(init)
  let [detailState, setDetailState] = useState({
    visible: false,
    details: ''
  })
  let [visibleTTList, setVisibleTTList] = useState(false)
  const ttEvents = {
    showDrawer: () => {
      setState((prevState) => {
        return {
          visible: true,
          applicationId: applications._id,
          studentId: student._id
        }
      })
    },
    hideDrawer: () => {
      setState((prevState) => {
        return {
          ...prevState,
          ...init
        }
      })
    },
    showTravelDrawer: () => {
      setTravelState((prevState) => {
        return {
          visible: true,
          applicationId: applications._id,
          studentId: student._id
        }
      })
    },
    hideTravelDrawer: () => {
      setTravelState((prevState) => {
        return {
          ...prevState,
          ...init
        }
      })
    },
    cancelTuitionFees: async () => {
      let obj = {
        applicationId: applications._id,
        studentId: student._id,
        status: 'Cancel'
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      let resp = await dispatch(updateTuitionFeesDirectUniversityFxn(fd))
      if (resp && resp.success) {
        reloadTable()
      }
    },
    showDetailModal: (details) => {
      setDetailState((prevState) => {
        return {
          visible: true,
          details: details
        }
      })
    },
    hideDetailModal: () => {
      setDetailState((prevState) => {
        return {
          ...prevState,
          visible: false
        }
      })
    },
    verifiedTTFxn: async (data) => {
      let obj = {
        applicationId: applications._id,
        studentId: student._id
      }
      let resp = await dispatch(verifyTuitionFeesDocumentFxn(obj))
      if (resp && resp.success) {
        reloadTable()
      }
    }
  }

  return (
    <>


      {tuitionFeeDocument && tuitionFeeDocument.amount ? <div className={'mb10'}>
        Tuition Fees : {tuitionFeeDocument.amount}<br />
        Date : {displayDate(tuitionFeeDocument.date)}<br />
        TT :
        <> {tuitionFeeDocument && tuitionFeeDocument.document && tuitionFeeDocument.document.path ?
          <a href={tuitionFeeDocument.document.path}>
            Download
          </a> : null}</><br />
        {tuitionFeeDocument.details ?
          <>Details : <a className={'bs-link'}
                         onClick={() => ttEvents.showDetailModal(tuitionFeeDocument.details)}>View</a><br /></> : null}

        {/* Status : <>
                {tuitionFeeDocument.status == 'Pending' ?
                    <label className={'label label-xs label-warning'}>Pending</label> : null}
                {tuitionFeeDocument.status == 'Cancel' ?
                    <label className={'label label-xs label-danger'}>Cancel</label> : null}
                {tuitionFeeDocument.status == 'Approved' ?
                    <label className={'label label-xs label-success-green'}>Approved</label> : null}

            </>*/}
      </div> : null}

      {closedTT ?
        <div className={'mb10'}>
          Closed By Admin
        </div> : null}


      <div>

        {((verifiedTuitionFeeDocuments !== true && !(tuitionFeeDocument && tuitionFeeDocument.amount)) && showUploadTT(user)) ?
          <div className={'mt10'}>
            {student.applications && student.applications.status == 'Visa Approved' ? (
              <Tooltip title={'Upload TT'}>
                <a onClick={() => ttEvents.showDrawer()}>
                  <img src={'/assets/icons/cloud-computing-upload.png'} />
                </a>
              </Tooltip>
            ) : null}
          </div> : null
        }

        {/*{verifiedTuitionFeeDocuments !== true && tuitionFeeDocuments && tuitionFeeDocuments.length ?
                    <Tooltip title={'Verified TT'}>
                        <Popconfirm
                            title={'Are you sure, you want to Verified TT?'}
                            onConfirm={() => {
                                ttEvents.verifiedTTFxn()
                            }}>
                            <a className={'bs_btn bs-default mt10 bs-xs mr10'}>
                                Verified TT
                            </a>
                        </Popconfirm>
                    </Tooltip> : null}*/}
      </div>

      {state.visible ?
        <UploadTuitionFees
          {...state}
          onClose={ttEvents.hideDrawer}
          onSubmit={() => {
            ttEvents.hideDrawer()
            reloadTable()
          }} />
        : null}
      {travelState.visible ?
        <UploadTravelDocument
          {...travelState}
          onClose={ttEvents.hideTravelDrawer}
          onSubmit={() => {
            ttEvents.hideTravelDrawer()
            reloadTable()
          }} />
        : null}

      {detailState.visible ? <Modal
        visible={detailState.visible}
        title='Details'
        onCancel={ttEvents.hideDetailModal}
        onOk={ttEvents.hideDetailModal}
        footer={null}>
        {detailState.details}
      </Modal> : null}


      {visibleTTList ?
        <UploadedTuitionFeesList
          visible={visibleTTList}
          application={applications}
          onClose={() => {
            setVisibleTTList(false)
          }} /> : null}

    </>
  )
}

const TotalTTComponent = (props) => {
  let dispatch = useDispatch()
  let { student, reloadTable, user, allowDeleteTT = false, showAgentDeal = false } = props
  let { applications } = student
  let {
    tuitionFeeDocuments,
    verifiedTuitionFeeDocuments,
    universityCountry,
    totalTTAmount,
    tuitionFeeDocumentStatus,
    agentId,
    courseUniversity
  } = applications
  let [state, setState] = useState(init)
  let [travelState, setTravelState] = useState(init)
  let [detailState, setDetailState] = useState({
    visible: false,
    details: ''
  })
  let [visibleTTList, setVisibleTTList] = useState(false)
  const ttEvents = {
    showDrawer: () => {
      setState((prevState) => {
        return {
          visible: true,
          applicationId: applications._id,
          studentId: student._id
        }
      })
    },
    hideDrawer: () => {
      setState((prevState) => {
        return {
          ...prevState,
          ...init
        }
      })
    },
    showTravelDrawer: () => {
      setTravelState((prevState) => {
        return {
          visible: true,
          applicationId: applications._id,
          studentId: student._id
        }
      })
    },
    hideTravelDrawer: () => {
      setTravelState((prevState) => {
        return {
          ...prevState,
          ...init
        }
      })
    },
    cancelTuitionFees: async () => {
      let obj = {
        applicationId: applications._id,
        studentId: student._id,
        status: 'Cancel'
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      let resp = await dispatch(updateTuitionFeesDirectUniversityFxn(fd))
      if (resp && resp.success) {
        reloadTable()
      }
    },
    showDetailModal: (details) => {
      setDetailState((prevState) => {
        return {
          visible: true,
          details: details
        }
      })
    },
    hideDetailModal: () => {
      setDetailState((prevState) => {
        return {
          ...prevState,
          visible: false
        }
      })
    },
    verifiedTTFxn: async (data) => {
      let obj = {
        applicationId: applications._id,
        studentId: student._id
      }
      let resp = await dispatch(verifyTuitionFeesDocumentFxn(obj))
      if (resp && resp.success) {
        reloadTable()
      }
    },
    closeTTFxn: async (data) => {
      let obj = {
        applicationId: applications._id,
        studentId: student._id
      }
      let resp = await dispatch(closeTuitionFeesDocumentFxn(obj))
      if (resp && resp.success) {
        reloadTable()
      }
    }
  }

  return (
    <>
      <div>
        {universityCountry && universityCountry.currency && totalTTAmount ? universityCountry.currency : ''} {totalTTAmount ? totalTTAmount : ''}
        <br />
        <a className={'bs_btn bs-default mt5 bs-xxs'}
           style={{ width: 120 }}
           onClick={() => {
             setVisibleTTList(true)
           }}>
          View TT
        </a>

        <br />
        {tuitionFeeDocuments && tuitionFeeDocuments.length && verifiedTuitionFeeDocuments ? (
          <div className={'mb10'}>
            {showToUser(user) ? (
              <div>
                {tuitionFeeDocumentStatus !== 'Approved' ? (
                  <>
                    <a className={`bs_btn bs-default mt5 bs-xxs`} style={{ width: 120 }}
                       onClick={() => {
                         ttEvents.showTravelDrawer()
                       }}>
                      Upload Travel Docs
                    </a>

                  </>
                ) : null}
              </div>
            ) : (
              ''
            )}
          </div>
        ) : null}

        {verifiedTuitionFeeDocuments !== true && tuitionFeeDocuments && tuitionFeeDocuments.length && user.email == 'ashu@unizportal.com' ?
          <Tooltip title={'Verified Funds'}>
            <Popconfirm
              title={'Did you verify from Bank Account?'}
              onConfirm={() => {
                ttEvents.verifiedTTFxn()
              }}>
              <a className={'bs_btn bs-default mt5 bs-xxs'} style={{ width: 120 }}>
                Verified TT
              </a>
            </Popconfirm>
          </Tooltip> : null}

        {((user.userType == 'admin' || user.email == 'kamal@ciracollege.com') && verifiedTuitionFeeDocuments !== true) ?
          <Tooltip title={'Close Funds'}>
            <Popconfirm
              title={'Are you sure, you want to close TT?'}
              onConfirm={() => {
                ttEvents.closeTTFxn()
              }}>
              <a className={'bs_btn bs-primary mt5 bs-xxs'} style={{ width: 120 }}>
                Close TT
              </a>
            </Popconfirm>
          </Tooltip> : null}
      </div>
      {showAgentDeal && (user.userType == 'admin' || user.email == 'ashu@unizportal.com') ?
        <div className={'mt5'}>
          {agentId && agentId.dealClose && agentId.dealClose.length ?
            <AgentUniversityDealClose dealClose={agentId.dealClose} universityId={courseUniversity} /> : null}
        </div> : null}


      {state.visible ?
        <UploadTuitionFees
          {...state}
          onClose={ttEvents.hideDrawer}
          onSubmit={() => {
            ttEvents.hideDrawer()
            reloadTable()
          }} />
        : null}
      {travelState.visible ?
        <UploadTravelDocument
          {...travelState}
          onClose={ttEvents.hideTravelDrawer}
          onSubmit={() => {
            ttEvents.hideTravelDrawer()
            reloadTable()
          }} />
        : null}

      {detailState.visible ? <Modal
        visible={detailState.visible}
        title='Details'
        onCancel={ttEvents.hideDetailModal}
        onOk={ttEvents.hideDetailModal}
        footer={null}>
        {detailState.details}
      </Modal> : null}


      {visibleTTList ?
        <UploadedTuitionFeesList
          visible={visibleTTList}
          application={applications}
          studentId={student._id}
          dispatch={dispatch}
          user={user}
          allowDeleteTT={allowDeleteTT}
          onSubmit={() => {
            reloadTable()
            setVisibleTTList(false)
          }}
          onClose={() => {
            setVisibleTTList(false)
          }} /> : null}

    </>
  )
}

const TotalTTViewComponent = (props) => {
  let dispatch = useDispatch()
  let { student, reloadTable, user, allowDeleteTT = false } = props
  let { applications } = student
  let {
    universityCountry,
    totalTTAmount,
    tuitionFeeDocument
  } = applications
  let [detailState, setDetailState] = useState({
    visible: false,
    details: ''
  })
  let [visibleTTList, setVisibleTTList] = useState(false)

  const ttEvents = {
    showDetailModal: (details) => {
      setDetailState((prevState) => {
        return {
          visible: true,
          details: details
        }
      })
    },
    hideDetailModal: () => {
      setDetailState((prevState) => {
        return {
          ...prevState,
          visible: false
        }
      })
    }

  }

  return (
    <>
      <div>
        {universityCountry && universityCountry.currency && totalTTAmount ? universityCountry.currency : ''} {totalTTAmount ? totalTTAmount : ''}
        <br />
        <a className={'bs_btn bs-default mt10 bs-xs'}
           style={{ width: 120 }}
           onClick={() => {
             setVisibleTTList(true)
           }}>
          View TT
        </a>
      </div>

      {tuitionFeeDocument && tuitionFeeDocument.amount ? <div className={'mt10'}>
        Tuition Fees : {tuitionFeeDocument.amount}<br />
        Date : {displayDate(tuitionFeeDocument.date)}<br />
        TT :
        <> {tuitionFeeDocument && tuitionFeeDocument.document && tuitionFeeDocument.document.path ?
          <a href={tuitionFeeDocument.document.path}>
            Download
          </a> : null}</><br />
        {tuitionFeeDocument.details ?
          <>Details : <a className={'bs-link'}
                         onClick={() => ttEvents.showDetailModal(tuitionFeeDocument.details)}>View</a><br /></> : null}
      </div> : null}

      {visibleTTList ?
        <UploadedTuitionFeesList
          visible={visibleTTList}
          application={applications}
          studentId={student._id}
          dispatch={dispatch}
          user={user}
          allowDeleteTT={allowDeleteTT}
          onSubmit={() => {
            reloadTable()
            setVisibleTTList(false)
          }}
          onClose={() => {
            setVisibleTTList(false)
          }} /> : null}


      {detailState.visible ? <Modal
        visible={detailState.visible}
        title='Details'
        onCancel={ttEvents.hideDetailModal}
        onOk={ttEvents.hideDetailModal}
        footer={null}>
        {detailState.details}
      </Modal> : null}

    </>
  )
}

UploadTTComponent.UploadedDocuments = UploadedDocuments
UploadTTComponent.TotalTTComponent = TotalTTComponent
UploadTTComponent.TotalTTViewComponent = TotalTTViewComponent
export default UploadTTComponent
