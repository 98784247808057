import React from "react"
import ReceivedCommissionList from "../privateCollege/receivedCommissionList";
import {fcmtUniversity} from "../../../components/_utils/appUtils";

const FcmtPaidApplicationList = () => {
    return (
        <ReceivedCommissionList
            appName={'FCMT College'}
            redirectKey={'fcmt.fcmtReceivedCommission'}
            universityId={fcmtUniversity}/>
    )
}
export default FcmtPaidApplicationList
