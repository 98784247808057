import {
  Button,
  Col,
  Form,
  Row,
  notification
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {
  LoadState
} from '../../components/_utils/countryUtil'
import {
  getCitiesFxn,
  getPlaceFxn,
  getStatesFxn
} from '../dailyExpense/actions/dailyExpense'
import { addTravelPlanFxn } from './actions'
import { departmentObj } from '../../components/_utils/appUtils'
import { listAllUsers } from '../users/actions/user'

let initialState = {
  uploadKey: moment(),

  allStates: [],
  allCities: []
}

const AddTravelPlan = props => {
  const {
    form: { getFieldDecorator, getFieldValue }, user
  } = props
  const dispatch = useDispatch()
  const [state, setState] = useState(initialState)
  const [indianStates, setIndianStates] = useState([])
  const [indianCities, setIndianCities] = useState([])
  const [fromPlace, setFromPlace] = useState([])
  const [toState, setToState] = useState([])
  const [toCities, setToCity] = useState([])
  const [toPlace, setToPlace] = useState([])
  const [marketingUserList, setMarketingUserList] = useState([])

  useEffect(
    () => {
      setState({
        ...state,
        allStates: 101 ? LoadState(101) : []
      })
    },
    []
  )

  const [searchResults, setSearchResults] = useState([])


  const [position, setPosition] = useState({ fromPlace: '', toPlace: '' })


  useEffect(
    () => {
      setSearchResults([])
    },
    [position]
  )

  useEffect(() => {
    getIndianStates()
    getToStates()
  }, [])

  useEffect(() => {
    if (getFieldValue('state')) {
      getIndianCities()
    }
  }, [getFieldValue('state')])

  useEffect(() => {
    if (getFieldValue('toState')) {
      getToCities()
    }
  }, [getFieldValue('toState')])

  useEffect(() => {
    if (getFieldValue('city')) {
      getFromPlace()
    }
  }, [getFieldValue('city')])

  useEffect(() => {
    if (getFieldValue('toCity')) {
      getToPlace()
    }
  }, [getFieldValue('toCity')])

  const getIndianStates = async () => {
    let data = await dispatch(getStatesFxn({ results: 1000 }))
    setIndianStates(data)
  }
  const getIndianCities = async (params = {}) => {
    if (getFieldValue('state')) {
      params.state = getFieldValue('state')
    }
    let data = await dispatch(getCitiesFxn({ results: 1000, ...params }))
    setIndianCities(data)
  }

  const getToStates = async () => {
    let data = await dispatch(getStatesFxn({ results: 1000 }))
    setToState(data)
  }

  const getToCities = async (params = {}) => {
    if (getFieldValue('toState')) {
      params.state = getFieldValue('toState')
    }
    let data = await dispatch(getCitiesFxn({ results: 1000, ...params }))
    setToCity(data)
  }

  const getFromPlace = async (params = {}) => {
    if (getFieldValue('city')) {
      params.city = getFieldValue('city')
    }
    let data = await dispatch(getPlaceFxn({ results: 1000, ...params }))
    setFromPlace(data)
  }

  const getToPlace = async (params = {}) => {
    if (getFieldValue('toCity')) {
      params.city = getFieldValue('toCity')
    }
    let data = await dispatch(getPlaceFxn({ results: 1000, ...params }))
    setToPlace(data)
  }

  const inputTypes = {
    dateField: [
      {
        key: 'date',
        label: 'Choose Travel Date',
        type: 'date',
        required: true,
        span: 8,
        placeholder: 'Choose Travel Date'
      },
      {
        key: 'users',
        // required: true,
        label: 'Collaborating Users',
        placeholder: 'Users',
        type: 'select',
        showSearch: true,
        allowClear: true,
        mode: 'multiple',
        options: marketingUserList,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            users: x
          })
        },
        span: 8
      }
    ],
    fields: [
      {
        key: 'state',
        required: true,
        label: 'From State',
        placeholder: 'State Name',
        type: 'select',
        showSearch: true,
        allowClear: true,
        options: indianStates,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            fromPlace: '',
            city: '',
            state: x
          })
        },
        span: 8
      },
      {
        key: 'city',
        allowClear: true,
        label: 'From City',
        required: true,
        placeholder: 'City Name',
        showSearch: true,
        type: 'select',
        options: indianCities,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            fromPlace: '',
            city: x
          })
        },
        span: 8
      },
      {
        key: 'fromPlace',
        allowClear: true,
        label: 'From Place',
        placeholder: 'From Place',
        type: 'select',
        showSearch: true,
        options: fromPlace,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            fromPlace: x
          })
        },
        span: 8
      },
      {
        key: 'toState',
        allowClear: true,
        label: 'To State',
        required: true,
        placeholder: 'State Name',
        type: 'select',
        showSearch: true,
        options: toState,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            toPlace: '',
            toCity: '',
            toState: x
          })
        },
        span: 8
      },
      {
        key: 'toCity',
        allowClear: true,
        label: 'To City',
        required: true,
        placeholder: 'City Name',
        type: 'select',
        showSearch: true,
        options: toCities,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            toPlace: '',
            toCity: x
          })
        },
        span: 8
      },
      {
        key: 'toPlace',
        allowClear: true,
        label: 'To Place',
        placeholder: 'To Place',
        type: 'select',
        showSearch: true,
        options: toPlace,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            toPlace: x
          })
        },
        span: 8
      }


    ]
  }


  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (!valData.date) {
          notification.warning({
            message: 'Please choose date.'
          })
          return
        }
        // valData.users = valData.users.map(id => ({ user: id }))
        let data = await dispatch(addTravelPlanFxn(valData))
        if (data && !data.error) {
          form.resetFields()
          setPosition({ fromPlace: '', toPlace: '' })
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email', 'mobile'],
      block: false,
      _id: { $ne: user._id }
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }

  useEffect(() => {
    loadMarketingUsers()
  }, [])

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Form onSubmit={handleSubmit}>
          <div className='form-box mt-4'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Add Travel Plan</h5>
            </div>
            <div className='card unizportal'>
              <Row gutter={24}>
                {inputTypes.dateField.map((item, key) => {
                  return (
                    <Col span={item.span ? item.span : 8}
                         key={key}
                         className={'mb10'}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}
                      />
                    </Col>
                  )
                })}
              </Row>
              <Row gutter={24} className={'wrapBox'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <Col span={item.span ? item.span : 8}
                         md={item.span ? item.span : 8}
                         sm={12}
                         xs={24}
                         key={key}
                         className={'mb10'}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}
                      />
                    </Col>
                  )
                })}
              </Row>
              <Form.Item>
                <Button type='primary' htmlType='submit' className='btn'>
                  SAVE
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

const WrappedTravelPlan = Form.create()(AddTravelPlan)
export default WrappedTravelPlan
