import React from "react"
import PaidApplicationListComponent from "../privateCollege/privateCollegePaidApplicationList";
import {cambieUniversity} from "../../../components/_utils/appUtils";

const CambiePaidApplicationList = () => {
    return (
        <PaidApplicationListComponent
          appName={'Cambie College'}
          redirectKey={'cambie.cambiePaidTT'}
          universityId={cambieUniversity} />
    )
}
export default CambiePaidApplicationList
