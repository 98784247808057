import {
  Button,
  Col,
  Icon, Popconfirm,
  Row,
  Select, Tag,
  Tooltip
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  Intakes,
  departmentObj,
  fcmtUniversity,
  filterOption,
  longDisplayDate,
  newFormatDisplayDate
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'
import { listAllCampus } from '../../campus/actions/campus'
import { listAllUsers } from '../../users/actions/user'
import DocumentDrawer from '../drawers/studentDocumentDrawer'
import { fcmtOnShoreApplicationListFxn } from '../../fcmtApplications/actions'
import EditStudentDrawer from '../cambieOnshoreApplicationList/editStudentDrawer'
import { reCreateFcmtOnshoreLoaLetterFxn, ReCreateFcmtOfferLetter } from '../actions/chooseStudent'
import GenerateFcmtOnshoreReceipt from './receiptDrawer'
import GenerateFcmtOnshoreLoa from './loaDrawer'
import { fcmtOnshoreEnrollmentLetterFxn, fcmtOnshorePaymentReceiptFxn } from './actions'
import { allowReCreateOffer } from '../../../components/_utils/utils'

const { Option } = Select
const OfferActionComponent = props => {
  let { file, record, events } = props
  let { applications } = record
  return (
    <div>
      {file && file.path ? (
        <Tooltip title={'Download Offer Letter'}>
          <a download={file.fileName} href={file.path}>
            <img src={'/assets/icons/cloud-computing-download.png'} />
          </a>
        </Tooltip>
      ) : (
        ''
      )}
      <div className={'sprBox'}>
        {allowReCreateOffer(applications) ?
          <Popconfirm
            title={'Are your sure, you want to Re-Create Offer Letter?'}
            onConfirm={() => events.reCreateOfferLetter(record)}
            okText='Yes'
            cancelText='No'>
            <a className={'bs-link bs-xs'}>Re-Create Offer Letter</a>
          </Popconfirm> : null}

      </div>
    </div>
  )
}

const OnShoreFcmtApplicationList = (props) => {
  const [studentData, setStudentData] = useState({})
  const [name, setName] = useState('')
  const [allAgent, setAllAgent] = useState([])
  const [agentId, setAgentId] = useState('')
  const [totalApplication, setTotalApplication] = useState(0)
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [campusList, setCampusList] = useState([])
  const [campusId, setCampusId] = useState('')
  const [intake, setIntake] = useState('')
  let [courseName, setCourseName] = useState('')
  let [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false)
  let [marketingUserList, setMarketingUserList] = useState([])
  let [marketingUserId, setMarketingUserId] = useState('')
  let [receiptState, setReceiptState] = useState({
    visible: false,
    studentId: '',
    applicationId: '',
    receiptAmount: ''
  })
  let [studentState, setStudentState] = useState({
    visible: false,
    studentId: '',
    familyName: '',
    applicationId: '',
    courseId: '',
    application: {}
  })
  let [loaState, setLoaState] = useState({
    visible: false,
    studentId: '',
    applicationId: '',
    receiptAmount: '',
    feesPrepaid: undefined
  })


  const dispatch = useDispatch()

  useEffect(() => {
    loadAgentData()
    setFieldByParams()
    loadMarketingUsers()
    loadCampusList()
    // loadUniversity()
  }, [])
  const tableAppRef = useRef()


  let apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await getParamsForApplicationList()

      params = { ...params, ...resp }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      try {
        setFilters(params)
        let resp = await fcmtOnShoreApplicationListFxn({ ...params })
        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }
    })
  }

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()

    if (resp.campusId) {
      setCampusId(resp.campusId)
    }

    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.courseName) {
      setCourseName(resp.courseName)
    }
    if (resp.marketingUserId) {
      setMarketingUserId(resp.marketingUserId)
    }

    if (resp.name) {
      setName(resp.name)
    }

  }

  const loadAgentData = async () => {
    let params = {
      results: 10000,
      userType: 'agent',
      sortField: 'companyName',
      sortOrder: 'ascend',
      select: ['name', 'companyName']
    }
    let { data } = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setAllAgent(data)
    }
  }

  const loadCampusList = async () => {
    let params = {}
    params.sortField = 'campusName'
    params.sortOrder = 'ascend'
    params.results = 50
    params.count = 50
    params.campusUniversity = fcmtUniversity
    params.select = ['campusName']
    let { data } = await dispatch(
      listAllCampus({
        ...params,
        regExFilters: ['campusAddress', 'campusName']
      })
    )
    setCampusList(data)
  }

  const events = {
    reloadFxn: () => {
      tableAppRef.current.reload()
    },

    refreshApplication: () => {
      tableAppRef.current.reload()
    },

    openDocumentDrawer: async record => {
      setStudentData(record)
      setVisibleDocumentDrawer(true)
    },
    closeDocumentDrawer: async record => {
      setStudentData({})
      setVisibleDocumentDrawer(false)
    },
    generateEnrollment: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(fcmtOnshoreEnrollmentLetterFxn(obj))
      if (resp && resp.success) {

        if (tableAppRef && tableAppRef.current) {
          tableAppRef.current.reload()
        }
      }
    },
    showReceiptDrawer: async (record) => {
      setReceiptState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id,
        receiptAmount: record.applications.fcmtOnshoreReceiptAmount ? record.applications.fcmtOnshoreReceiptAmount : ''
      })
    },
    hideReceiptDrawer: async () => {
      setReceiptState({
        visible: false,
        studentId: '',
        applicationId: '',
        receiptAmount: ''
      })
    },
    generateLoaLetterFxn: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(reCreateFcmtOnshoreLoaLetterFxn(obj))
      if (resp && resp.success) {

        if (tableAppRef && tableAppRef.current) {
          tableAppRef.current.reload()
        }
      }
    },
    showEditStudent: (record) => {
      let obj = {
        visible: true,
        studentId: record._id,
        familyName: record.applications && record.applications.familyName ? record.applications.familyName : '',
        applicationId: record.applications && record.applications._id ? record.applications._id : '',
        application: record.applications
      }
      if (record.applications && record.applications.courseId && record.applications.courseId._id) {
        obj.courseId = record.applications.courseId._id
      }
      setStudentState(obj)
    },
    hideEditStudent: () => {
      setStudentState({
        visible: false,
        studentId: '',
        familyName: '',
        applicationId: '',
        courseId: '',
        application: {}
      })
    },
    reCreateOfferLetter: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(ReCreateFcmtOfferLetter(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    },
    showLoaDrawer: async (record) => {
      setLoaState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id,
        receiptAmount: record.applications.fcmtOnshoreReceiptAmount ? record.applications.fcmtOnshoreReceiptAmount : '',
        feesPrepaid: record.applications.feesPrepaid ? record.applications.feesPrepaid : undefined
      })
    },
    hideLoaDrawer: async () => {
      setLoaState({
        visible: false,
        studentId: '',
        applicationId: '',
        receiptAmount: '',
        feesPrepaid: undefined
      })
    },
    generateFeeReceiptFxn: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(fcmtOnshorePaymentReceiptFxn(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    }
  }

  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let name = searchParams.get('name')
      let campusId = searchParams.get('campusId')
      let agentId = searchParams.get('agentId')
      let intake = searchParams.get('intake')
      let courseName = searchParams.get('courseName')
      let marketingUserId = searchParams.get('marketingUserId')
      let obj = {}

      if (name) {
        obj.name = name
      }

      if (campusId) {
        obj.campusId = campusId
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (intake) {
        obj.intake = intake
      }
      if (courseName) {
        obj.courseName = courseName
      }
      if (marketingUserId) {
        obj.marketingUserId = marketingUserId
      }

      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let obj = {}
    if (name) {
      obj.name = name
    }
    if (campusId) {
      obj.campusId = campusId
    }
    if (agentId) {
      obj.agentId = agentId
    }
    if (intake) {
      obj.intake = intake
    }
    if (courseName) {
      obj.courseName = courseName
    }
    if (marketingUserId) {
      obj.marketingUserId = marketingUserId
    }
    dispatch(
      getUrlPushWrapper('onshoreFcmtAppList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let obj = {}
    setName('')
    setCampusId('')
    setAgentId('')
    setIntake('')
    setCourseName('')
    setMarketingUserId('')
    dispatch(
      getUrlPushWrapper('onshoreFcmtAppList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const columns = [
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      searchTextName: 'applications.id',
      width: 60,
      render: item => {
        return <div style={{ width: 60 }}>{item}</div>
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 150,
      render: (item, record) => {
        return (
          <div style={{ width: 150 }}>
            {/*{item}*/}

            <Tooltip title={'Edit Student'}>
              <a className={'linkAA'}
                 onClick={() => {
                   events.showEditStudent(record)
                 }}>
                {item} <Icon type={'edit'} />
              </a>
            </Tooltip>

            <br />
            {newFormatDisplayDate(record.dateOfBirth)}
          </div>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 220,
      render: item => {
        return <div style={{ width: 200 }}>{item}</div>
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      width: 80,
      render: (item, record) => {
        return (
          <div style={{ width: 80 }}>
            {item && item.month && item.year
              ? `${item.month}, ${item.year}`
              : ''}
          </div>
        )
      }
    },
    {
      title: 'Campus',
      key: 'applications.campusId',
      dataIndex: 'applications.campusId',
      width: 85,
      render: (item, record) => {
        return (
          <div style={{ width: 85 }}>
            {item && item.campusName ? item.campusName : ''}
          </div>
        )
      }
    },
    {
      title: 'Current User',
      key: 'applications.assignedUserId',
      dataIndex: 'applications.assignedUserId',
      width: 100,
      render: (val, record) => {
        let { agentId, statusList, status } = record.applications
        return (
          <div style={{ width: 100 }}>
            {val && val.name ? val.name : ''}
            <br />
            <div>{longDisplayDate(statusList.date)}</div>
          </div>
        )
      }
    },

    {
      title: 'Conditional Offer Letter',
      key: 'applications.offerReceiveLetter',
      dataIndex: 'applications.offerReceiveLetter',
      width: 150,
      render: (val, record) => {
        return (
          <div style={{ width: 150 }} className={'fcmtDiv'}>
            <OfferActionComponent file={val} record={record} events={events} />
          </div>
        )
      }
    },
    {
      title: 'Enrollment Letter',
      key: 'applications.enrollmentLetter',
      dataIndex: 'applications.enrollmentLetter',
      width: 200,
      render: (val, record) => {
        let { loaLetter } = record.applications
        return (
          <div style={{ width: 200 }} className={'fcmtDiv'}>
            {val && val.path ? <>
              <Tag>
                <Icon type={'download'} /> &nbsp;
                <a href={val.path} target={'_blank'}>Download Enrolment</a></Tag>
              <br />

            </> : null}
            {loaLetter && loaLetter.path ? <Popconfirm
              title={'Are your sure, you want to generate Enrollment Letter?'}
              onConfirm={() => events.generateEnrollment(record)}
              okText='Yes'
              cancelText='No'>
              <button className={`bs_btn bs-default mt10 bs-xs`}>
                Generate
              </button>
            </Popconfirm> : null}
          </div>
        )
      }
    },
    {
      title: 'LOA',
      key: 'applications.loaLetter',
      dataIndex: 'applications.loaLetter',
      width: 180,
      render: (val, record) => {
        return (
          <div style={{ width: 180 }} className={'fcmtDiv'}>
            {val && val.path ? <>
              <Tag>
                <Icon type={'download'} /> &nbsp;
                <a href={val.path} target={'_blank'}>Download LOA</a></Tag>
              <br />

            </> : null}
            {/*<Popconfirm
              title={'Are your sure, you want to generate LOA Letter?'}
              onConfirm={() => events.generateLoaLetterFxn(record)}
              okText='Yes'
              cancelText='No'>

            </Popconfirm>*/}
            <button className={`bs_btn bs-default mt10 bs-xs`} onClick={() => {
              events.showLoaDrawer(record)
            }}>
              Generate
            </button>
          </div>
        )
      }
    },
    {
      title: 'Receipt',
      key: 'applications.receipt',
      dataIndex: 'applications.receipt',
      width: 180,
      render: (val, record) => {
        let { feesPrepaid, cambieOnshoreReceiptAmount } = record.applications
        return (
          <div style={{ width: 180 }} className={'fcmtDiv'}>
            {val && val.path ? <>
              <Tag>
                <Icon type={'download'} /> &nbsp;
                <a href={val.path} target={'_blank'}>Download Receipt</a></Tag>
              <br />
            </> : null}
            {(feesPrepaid !== undefined || cambieOnshoreReceiptAmount) ?
              <Popconfirm
                title={'Are you sure, you want to generate Receipt?'}
                onConfirm={() => {
                  events.generateFeeReceiptFxn(record)
                }}>
                <button className={`bs_btn bs-default mt10 bs-xs`}>
                  Generate
                </button>
              </Popconfirm>
              : null}
          </div>
        )
      }
    },
    {
      title: 'Status',
      key: 'applications.status',
      dataIndex: 'applications.status',
      width: 140,
      render: (item, record) => {
        return (
          <div className={'fcmtDiv'} style={{ width: 140 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Referral Code',
      key: 'referralCode',
      dataIndex: 'referralCode',
      width: 120,
      render: (item, record) => {
        return (
          <div className={'fcmtDiv'} style={{ width: 120 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Added By',
      key: 'fcmtAgentId',
      dataIndex: 'fcmtAgentId',
      width: 150,
      render: (item, record) => {
        return (
          <div className={'fcmtDiv'} style={{ width: 120 }}>
            {item && item.name ? item.name : ''}<br />
            {item && item.email ? `(${item.email})` : ''}
          </div>
        )
      }
    },
    {
      title: 'Marketing User',
      key: 'applications.marketingUserId',
      dataIndex: 'applications.marketingUserId',
      width: 120,
      render: (item, record) => {
        return (
          <div className={'fcmtDiv'} style={{ width: 120 }}>
            {item && item.name ? item.name : ''}
          </div>
        )
      }
    },
    {
      title: 'Documents',
      key: 'document',
      dataIndex: 'document',
      width: 120,
      render: (val, record) => {
        return (
          <div className={'actionBtnGroup'}>
            <button
              className={`bs_btn bs-default mt10 bs-xs`}
              onClick={() => events.openDocumentDrawer(record)}>
              View Documents
            </button>
            <br />
            <Tooltip title={'View Application'}>
              <a className='bs_btn bs-default mt10 bs-xs'
                 href={`/application/view?appId=${record.applications._id}&studentId=${record._id}`}
              ><img src='/dist/img/view.svg' alt='' className={'eyeBtn'} style={{ height: '100%' }} /></a>

            </Tooltip>
          </div>
        )
      }
    }
  ]


  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'cambieBranchUser',
      results: 100,
      select: ['name']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }

  const extra = (
    <div className={'mt10'}>
      <Row gutter={12}>
        <Col md={5} sm={5} xs={8} lg={5}>
          <div className='search-box-table round'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Search by name...'
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Status'
              onChange={item => setCampusId(item)}
              className={'antSelect'}
              placeholder={'Campus'}
              allowClear={true}
              value={campusId || undefined}>
              {campusList && campusList.length
                ? campusList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.campusName}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>

        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Intake'
              onChange={item => setIntake(item)}
              filterOption={filterOption}
              className={'antSelect'}
              allowClear={true}
              showSearch={true}
              placeholder={'Intake'}
              value={intake || undefined}>
              {Intakes && Intakes.length
                ? Intakes.map((item, key) => {
                  return (
                    <Option value={item} key={key}>
                      {item}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Search By Marketing User'
              filterOption={filterOption}
              className={'antSelect'}
              allowClear={true}
              placeholder={'Marketing User'}
              onChange={item => setMarketingUserId(item)}
              value={marketingUserId || undefined}>
              {marketingUserList && marketingUserList.length
                ? marketingUserList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>
        <Col span={5}>
          <div className='sort-box-table custom-sort-box-new'>
            <div className='search-box-table round'>
              <input
                className='form-control form-control'
                type='search'
                placeholder='course name...'
                value={courseName}
                onChange={e => {
                  setCourseName(e.target.value)
                }}
              />
              <img src='/dist/img/search.png' alt='' />
            </div>
          </div>
        </Col>


        <Col md={4} sm={4} xs={12} lg={4}>
          <Button onClick={() => searchFxn()} className={'roundBtn'}>
            Search
          </Button>
          <Button onClick={() => clearFxn()} className={'roundBtn'}>
            Clear
          </Button>
        </Col>
      </Row>
    </div>
  )

  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>
                <Button
                  onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                  className={'roundGoBackBtn'}
                  icon={'arrow-left'}>
                  Back
                </Button>
                <h5>Application List</h5>

                <div className='sort-box-table mark-btn'>
                  Application List (Total - {totalApplication})
                </div>
              </div>
              <div className='card-body table-responsive'>
                {extra}
                <TableComp
                  columns={columns}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    defaultPageSize: 10,
                    current: filters.page ? filters.page : 1
                  }}
                  extraProps={{ scroll: { x: 2200 } }}
                  ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>

        {visibleDocumentDrawer &&
        studentData.applications &&
        studentData.applications._id ? (
          <DocumentDrawer
            onClose={events.closeDocumentDrawer}
            reloadTable={events.reloadFxn}
            studentData={studentData}
            applicationId={studentData.applications._id}
            studentId={studentData._id}
            visible={visibleDocumentDrawer}
          />
        ) : null}
      </div>
      {receiptState.visible ?
        <GenerateFcmtOnshoreReceipt
          {...receiptState}
          onClose={events.hideReceiptDrawer}
          onSubmit={() => {
            events.hideReceiptDrawer()
            events.reloadFxn()
          }}
        /> : null}

      {studentState.visible ?
        <EditStudentDrawer
          {...studentState}
          onClose={events.hideEditStudent}
          onSubmit={() => {
            events.hideEditStudent()
            events.reloadFxn()
          }}
        /> : ''}

      {loaState.visible ?
        <GenerateFcmtOnshoreLoa
          {...loaState}
          onClose={events.hideLoaDrawer}
          onSubmit={() => {
            events.hideLoaDrawer()
            events.reloadFxn()
          }}
        /> : null}

    </React.Fragment>
  )
}

export default OnShoreFcmtApplicationList
