import React from "react"
import PrivateCollegePendingTTApplicationList from "../privateCollege/privateCollegePendingTTApplicationList"
import {cambieUniversity} from "../../../components/_utils/appUtils";

const CambiePendingTTApplicationList = () => {
    return (
        <PrivateCollegePendingTTApplicationList
            appName={'Cambie College'}
            universityId={cambieUniversity}
            redirectKey={'cambie.cambieUploadTT'}
        />
    )
}
export default CambiePendingTTApplicationList
