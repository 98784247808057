import { apiUrl2 } from '../../../settings'

export const travelPlanUrl = () => {
  return apiUrl2 + '/travel-plan'
}

export const userTravelPlanUrl = (userId) => {
  return `${apiUrl2}/travel-plan/${userId}`
}

export const allTravelPlanUrl = () => {
  return `${apiUrl2}/api/getAllTravelPlan`
}

export const allTravelPlan = () => {
  return `${apiUrl2}/all-travel-plan`
}

export const addCollaborationUrl = () => {
  return `${apiUrl2}/add-Collaboration`
}
export const updateCollaborationUrl = () => {
  return `${apiUrl2}/update-Collaboration`
}
