import React, {useEffect, useRef, useState} from 'react'
import {
    Select,
    Button,
    Icon,
    Row,
    Col,
    Form,
    Drawer,
    Input, notification, Popconfirm
} from 'antd'
import {connect, useDispatch} from 'react-redux'
import {
    calculateCommissionByIntakeFxn, getPaidTTApplicationFxn,
    reCreateEnrollmentLetterFxn
} from '../actions/chooseStudent'
import {
    newFormatDisplayDate, filterOption, Intakes, longDisplayDate, fcmtUniversity, directUniCommission
} from '../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import {TableComp} from 'sz-react-utils-lite'
import {
    reCalculateCommission, updateApplicationTuitionFees
} from '../../student/actions/student'

import {
    addCommission,
} from '../actions/commision'
import UploadTTComponent from "../directUniDrawers/uploadTTComponent";
import DirectUniCommissionDrawer from "./directUniCommissionDrawer"
import {getPendingFCMTApplications} from "../actions/chooseStudent";

const intakeYears = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
]
let {TotalTTViewComponent} = UploadTTComponent;
const {Option} = Select


const ReceivedCommissionList = (props) => {
    let {universityId, appName} = props
    const [totalApplication, setTotalApplication] = useState(0)
    const [visibleFeesModal, setVisibleFeesModal] = useState(false)
    const [feesObj, setFeesObj] = useState(commissionObj)
    const [currentStatus, setCurrentStatus] = useState('')
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    const [selectedIntake, setSelectedIntake] = useState({
        month: "",
        year: "",
    });
    const [commissionObj, setCommissionObj] = useState({})
    const [visibleCommissionList, setVisibleCommissionList] = useState(false)
    const dispatch = useDispatch()
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    const tableAppRef = useRef()


    const calculateCommissionFxn = async () => {
        if (!selectedIntake.month) {
            notification.warn({message: "Choose intake month."})
            return
        }
        if (!selectedIntake.year) {
            notification.warn({message: "Choose intake year."})
            return
        }
        let obj = {
            appUniversityId: fcmtUniversity,
            intakeMonth: selectedIntake.month,
            intakeYear: selectedIntake.year,
            results: totalApplication,
            count: totalApplication,
            verifiedTuitionFeeDocuments: true,
        }
        let data = await dispatch(calculateCommissionByIntakeFxn(obj))
        setCommissionObj(data)
    }

    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.appUniversityId = universityId;
            params.verifiedTuitionFeeDocuments = true;
            params.commissionReceived = true;
            params.sortBy = "verifiedTuitionFeeDate";

            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }

            try {
                setFilters(params)
                let resp = await dispatch(getPendingFCMTApplications({...params}))
                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }
        })
    }

    const events = {
        reloadFxn: () => {
            tableAppRef.current.reload()
        },
        refreshApplication: () => {
            tableAppRef.current.reload()
        },

        setCommissionLocal: (data) => {
            setFeesObj({
                ...feesObj,
                ...data
            })
        },
        reCalculateCommissionFxn: async () => {
            let {commissionAmount} = await dispatch(reCalculateCommission(feesObj))
            if (commissionAmount) {
                events.setCommissionLocal({commissionAmount})
            }

        },
        updateApplicationTuitionFeesFxn: async () => {
            let {success, message} = await dispatch(updateApplicationTuitionFees(feesObj))
            if (success) {
                setFeesObj(commissionObj)
                setVisibleFeesModal(false)
                events.reloadFxn()
            }
        },
        reGenerateEnrollmentFunc: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(reCreateEnrollmentLetterFxn(obj));
            if (resp && resp.success) {
                tableAppRef.current.reload();
            }
        },
        searchData: () => {
            if (!selectedIntake.month) {
                notification.warn({message: "Choose intake month."})
                return
            }
            if (!selectedIntake.year) {
                notification.warn({message: "Choose intake year."})
                return
            }
            tableAppRef.current.reload()
            calculateCommissionFxn()
        },
        showCommissionList: () => {
            setVisibleCommissionList(true)
        },
        hideCommissionList: () => {
            setVisibleCommissionList(false)
        },
    }

    const columns = [

        {
            title: '#',
            key: '_id',
            dataIndex: '_id',
            width: 60,
            render: (item, record, index) => {
                return (
                    <div style={{width: 60}}>
                        {index + 1}
                    </div>
                )
            }
        },
        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            width: 60,
            render: (item) => {
                return (
                    <div style={{width: 60}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>

                        {item}
                        <br/>
                        {newFormatDisplayDate(record.dateOfBirth)}
                    </div>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: (item) => {
                return (
                    <div style={{width: 150}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                    </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 85,
            render: (item, record) => {
                return (
                    <div style={{width: 85}}>
                        {item && item.campusName ? item.campusName : ''}
                    </div>
                )
            }
        },

        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 100,
            render: (val, record) => {
                let {agentId, statusList, status} = record.applications
                return (
                    <div style={{width: 100}}>
                        {val && val.name ? val.name : ''}
                        <br/>
                        <div>
                            Agent :{' '}
                            {agentId && agentId.companyName ? agentId.companyName : ''}
                            <br/>
                            {agentId && agentId.mobile ? (
                                <>
                                    {' '}
                                    <Icon
                                        type={'phone'}
                                        style={{
                                            position: 'relative',
                                            bottom: 3
                                        }}
                                    />{' '}
                                    {agentId.mobile}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </div>
                )
            }
        },
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 150,
            render: (item, record) => {
                let {applications} = record
                return (
                    <div className={'fcmtDiv'} style={{width: 150}}>
                        {item.includes('File') || item.includes('Visa') || item.includes('Revoked') ? (
                            <div>
                                {item}
                                <br/>
                                {applications.statusList && applications.statusList.date ? (
                                    <div>
                                        Date : {newFormatDisplayDate(applications.statusList.date)}
                                        <br/>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                )
            }
        },


        {
            title: 'Total TT Amount',
            key: 'applications.totalTTAmount',
            dataIndex: 'applications.totalTTAmount',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}} className={'fcmtDiv'}>
                        <TotalTTViewComponent
                            student={record}
                            user={user}
                            reloadTable={events.reloadFxn}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Marketing',
            dataIndex: 'marketingUser',
            width: 120,
            key: 'marketingUser',
            render: (item, record) => {
                let {marketingUserId} = record.applications
                return (
                    <React.Fragment>
                        {marketingUserId && marketingUserId.name
                            ? marketingUserId.name
                            : ''}
                    </React.Fragment>
                )
            }
        },

        {
            title: 'Commission',
            dataIndex: 'commission',
            width: 120,
            key: 'commission',
            render: (item, record) => {
                let {totalTTAmount, tuitionFeeDocument} = record.applications;
                let ourCommission = 0
                let totalCommission = 0
                if (totalTTAmount) {
                    totalCommission = totalTTAmount;
                    ourCommission = totalTTAmount - directUniCommission.fcmt
                } else if (tuitionFeeDocument && tuitionFeeDocument.amount) {
                    totalCommission = tuitionFeeDocument.amount;
                    ourCommission = tuitionFeeDocument.amount - directUniCommission.fcmt
                }
                return (
                    <>
                        Total TT : {totalCommission}<br/>
                        Our Commission : {ourCommission >= 0 ? ourCommission : 0}
                    </>
                )
            }
        }
    ]

    return (
        <React.Fragment>
            <div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center all-student-search'
                                 style={{height: 60}}>
                                <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>{appName} All Received Commission </h5>
                                <div className='search-box-table' style={{ backgroundColor: 'transparent' }}>
                                  {currentStatus} (Total - {totalApplication})
                                </div>
                              <div className='sort-box-table mark-btn'>
                                {/*<a className={'roundBtn btn'}
                                       href={`/generate-commission/${universityId}`}>Generate Commission</a>*/}

                              </div>
                            </div>
                            <div className='card-body table-responsive'>

                                <TableComp
                                    columns={columns}
                                    rowKey={(item, index) => {
                                        return index
                                    }}
                                    apiRequest={apiRequest}
                                    pagination={{
                                        position: 'top',
                                        showSizeChanger: true,
                                        pageSizeOptions: ['20', '30', '50', '1000'],
                                        defaultPageSize: 20,
                                        current: filters.page ? filters.page : 1
                                    }}
                                    ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const ReceivedCommissionListWrapped = Form.create()(ReceivedCommissionList)
export default connect(
    null,
    mapDispatchToProps
)(ReceivedCommissionListWrapped)

