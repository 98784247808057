import React, { useEffect, useState } from 'react'
import { Drawer, notification, Form, Button } from 'antd'
import { useDispatch } from 'react-redux'
import { InputBox } from '../../../components/_utils/appUtils'
import { fcmtOnshorePaymentReceiptFxn } from './actions'

const GenerateFcmtOnshoreReceipt = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, onSubmit, studentId, applicationId, receiptAmount } = props
  let [cambieOnshoreReceiptAmount, setCambieOnshoreReceiptAmount] = useState('')
  const handleSubmit = async () => {
    if (!cambieOnshoreReceiptAmount) {
      notification.warning({ message: 'Enter Receipt amount.' })
      return
    }
    let resp = await dispatch(fcmtOnshorePaymentReceiptFxn({ cambieOnshoreReceiptAmount, applicationId, studentId }))
    if (resp && resp.success) {
      onSubmit()
    }
  }

  useEffect(() => {
    setCambieOnshoreReceiptAmount(receiptAmount ? receiptAmount : '')
  }, [receiptAmount])

  return (
    <>
      <Drawer title={'Generate Receipt'} visible={visible} width={'45%'} onClose={onClose}>
        <div className='form-box'>
          <div className='card unizportal'>
            <Form>
              <InputBox title={'Receipt Amount'}>
                <input className={'form-control'}
                       type={'number'}
                       placeholder={'Receipt Amount'}
                       value={cambieOnshoreReceiptAmount}
                       onChange={({ target }) => {
                         setCambieOnshoreReceiptAmount(target.value)
                       }} />
              </InputBox>


              <Button type='primary' htmlType='submit' className='btn' onClick={handleSubmit}>
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default GenerateFcmtOnshoreReceipt
