import React, { useEffect, useState } from 'react'
import { Drawer, notification, Form, Button, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { ConditionOptions, InputBox } from '../../../components/_utils/appUtils'
import { cambieOnshoreLoaLetterFxn, cambieOnshorePaymentReceiptFxn } from './actions'

const GenerateCambieOnshoreLoa = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, onSubmit, studentId, applicationId, receiptAmount, feesPrepaid: oldFeesPrepaid } = props
  let [cambieOnshoreReceiptAmount, setCambieOnshoreReceiptAmount] = useState('')
  let [feesPrepaid, setFeesPrepaid] = useState(undefined)
  const handleSubmit = async () => {
    if (feesPrepaid == undefined) {
      notification.warning({ message: 'Choose fee type.' })
      return
    }
    if (feesPrepaid) {
      if (!cambieOnshoreReceiptAmount) {
        notification.warning({ message: 'Enter Fees amount.' })
        return
      }
    }
    let obj = {
      feesPrepaid, cambieOnshoreReceiptAmount,
      applicationId, studentId
    }
    let resp = await dispatch(cambieOnshoreLoaLetterFxn(obj))
    if (resp && resp.success) {
      onSubmit()
    }
  }

  useEffect(() => {
    setCambieOnshoreReceiptAmount(receiptAmount ? receiptAmount : '')
    setFeesPrepaid(oldFeesPrepaid ? oldFeesPrepaid : '')
  }, [receiptAmount])

  return (
    <>
      <Drawer title={'Generate LOA'} visible={visible} width={'45%'} onClose={onClose}>
        <div className='form-box'>
          <div className='card unizportal'>
            <Form>
              <InputBox title={'Fees Prepaid'}>
                <Select
                  value={feesPrepaid}
                  placeholder={'Choose Fees Type'}
                  onChange={(e) => {
                    setFeesPrepaid(e)
                  }}>
                  {ConditionOptions.map((item, index) => {
                    return (
                      <option value={item.value} key={item.name}>{item.name}</option>
                    )
                  })}
                </Select>
              </InputBox>
              {feesPrepaid ?
                <InputBox title={'Fees Amount'}>
                  <input className={'form-control'}
                         type={'number'}
                         placeholder={'Fees Amount'}
                         value={cambieOnshoreReceiptAmount}
                         onChange={({ target }) => {
                           setCambieOnshoreReceiptAmount(target.value)
                         }} />
                </InputBox> : null}

              <Button type='primary' htmlType='submit' className='btn' onClick={handleSubmit}>
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default GenerateCambieOnshoreLoa
