import React from 'react'
import CommissionList from '../privateCollege/commissionList'
import { fcmtUniversity, RoyalArtsCollege } from '../../../components/_utils/appUtils'

const FcmtGenerateCommission = () => {
  return (
    <CommissionList
      appName={'FCMT College'}
      redirectKey={'fcmt.fcmtGenerateCommission'}
      universityId={fcmtUniversity} />
  )
}
export default FcmtGenerateCommission
