import React, { PureComponent } from 'react'
import {
  Form,
  Button,
  Col,
  Row,
  Drawer,
  notification,
  Card
} from 'antd'
import _ from 'lodash'
import { hideLoader, hidePageLoad } from '../../../modules/actions'
import { connect } from 'react-redux'
import { listAllCountries } from '../../countries/actions/countries'
import {
  countryIds,
  departmentObj,
  InputBox
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { updateUser, listAllUsers, getUser } from '../actions/user'
import {
  CountryCodeWithFlag,
  LoadAllCountry,
  LoadCity,
  LoadState
} from '../../../components/_utils/countryUtil'

@Form.create()
class EditOtherUser extends PureComponent {
  events = {

    chooseCountry: (name) => {
      const { setFieldsValue } = this.props.form
      let countryName = _.find(this.state.allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        setFieldsValue({
          stateName: undefined,
          cityName: undefined
        })
        this.setState({
          countryName: countryName.name ? countryName.name : '',
          stateName: '',
          cityName: '',
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      const { setFieldsValue } = this.props.form
      let stateName = _.find(this.state.allStates, (item) => {
        return item.name == name
      })
      setFieldsValue({
        cityName: undefined
      })
      if (stateName) {
        this.setState({
          stateName: stateName.name ? stateName.name : '',
          cityName: '',
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      this.setState({
        cityName: name ? name : ''
      })
    }
  }

  conditionalFxn = {
    assignMarketingUserRequirement: () => {
      const { userType } = this.state
      if (userType && (userType == 'onshoreManager')) {
        return true
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      universityList: [],
      countryList: [],

      userData: {},
      countryCode: '',
      stateList: [],
      cityList: [],
      allCountries: LoadAllCountry(),
      allStates: [],
      allCities: [],
      marketingUserList: []
    }
  }

  async loadUserData() {
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null
    this.setState({ userData: user })
  }

  componentDidMount() {
    this.loadCountry()
    this.getUserData()
    this.loadUserData()
    this.loadMarketingUsers()
    let { dispatch } = this.props
    dispatch({ type: 'USER_HIDELOADER' })
    dispatch(hideLoader())
    dispatch(hidePageLoad())
  }

  loadMarketingUsers = async () => {
    let { dispatch } = this.props
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email', 'mobile']
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({
      marketingUserList: data
    })
  }

  async loadCountry() {
    let { dispatch } = this.props
    let countryFilter = {
      results: 10,
      sortField: 'countryName',
      sortOrder: 'ascend',
      active: [true, false]
    }
    let { data } = await dispatch(listAllCountries(countryFilter))
    this.setState({
      countryList: data
    })
  }


  handleSubmit = e => {
    const { dispatch, form, onClose, reloadTable } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { countryCode } = this.state
        if (!countryCode) {
          notification.error({
            message: 'Please choose County Code'
          })
          return
        }
        valData.countryISOCode = countryCode

        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))


        let data = await dispatch(updateUser(fd, this.state.userId))
        if (data && !data.error) {

          onClose()
          if (reloadTable) {
            reloadTable()
          }
        }
      }
    })
  }

  loadMarketingUsers = async () => {
    let { dispatch } = this.props
    let obj = {
      results: 100,
      select: ['name', 'email', 'mobile'],
      department: departmentObj.marketing,
      block: false
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({
      marketingUserList: data
    })
  }

  async getUserData() {
    let {
      dispatch,
      form: { setFieldsValue },
      userData
    } = this.props
    if (userData && userData._id) {
      let resp = await dispatch(getUser(userData._id))
      if (resp) {
        let obj = {
          userId: resp._id,
          userType: resp.userType,
          name: resp.name,
          countryCode: resp.countryISOCode ? resp.countryISOCode : ''
        }

        this.setState({
          ...obj
        })

        setFieldsValue({
          name: resp.name,
          mobile: resp.mobile,
          email: resp.email,
          userType: resp.userType,
          address: resp.address,
          countryName: resp.countryName ? resp.countryName : undefined,
          cityName: resp.cityName ? resp.cityName : undefined,
          pincode: resp.pincode ? resp.pincode : undefined,
          stateName: resp.stateName ? resp.stateName : undefined,
          postName: resp.postName,
          countryId: resp.countryId ? resp.countryId._id : undefined,
          assignedMarketingUsers: resp.assignedMarketingUsers ? resp.assignedMarketingUsers : []

        })
        setTimeout(() => {
          setFieldsValue({
            universities: resp.universities
          })
        }, 100)
      }
    }
  }

  render() {
    const { visible, onClose, form: { getFieldDecorator, setFieldsValue } } = this.props
    const { marketingUserList } = this.state


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    let inputTypes = {
      fields: [
        {
          label: 'Contact Person Name',
          key: 'name',
          required: true
        },
        {
          key: 'countryCode',
          span: 3,
          customField: (
            <div style={{ marginTop: 12 }}>
              <CountryCodeWithFlag
                countryCode={this.state.countryCode}
                chooseCode={val => {
                  this.setState({
                    countryCode: val
                  })
                }}
              />
            </div>
          )
        },
        {
          span: 5,
          label: 'Mobile no',
          key: 'mobile',
          type: 'number',
          required: true
        },
        {
          label: 'Email',
          key: 'email',
          required: true
        },

        {
          key: 'countryName',
          label: 'Country Name',
          type: 'select',
          showSearch: true,
          options: this.state.allCountries,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            this.events.chooseCountry(x)
            setFieldsValue({
              countryName: x
            })
          }
        },

        {
          key: 'stateName',
          label: 'State Name',
          type: 'select',
          showSearch: true,
          options: this.state.allStates,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            this.events.chooseState(x)
            setFieldsValue({
              stateName: x
            })
          }
        },

        {
          key: 'cityName',
          label: 'City Name',
          type: 'select',
          showSearch: true,
          options: this.state.allCities,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            setFieldsValue({
              cityName: x
            })
          }
        },


        {
          label: 'Pincode',
          key: 'pincode',
          // required: true,
          type: 'input',
          span: 8
        },
        {
          label: 'Address',
          key: 'address'
        },

        {
          label: 'Assign Marketing Users',
          key: 'assignedMarketingUsers',
          type: 'select',
          span: 8,
          showSearch: true,
          mode: 'multiple',
          required: true,
          options: marketingUserList,
          hidden: !this.conditionalFxn.assignMarketingUserRequirement(),
          valueAccessor: x => `${x.name}`,
          keyAccessor: x => x._id,
          onChange: async (v) => {
            setFieldsValue({
              assignedMarketingUsers: v
            })
          }
        }

      ]
    }

    return (
      <Drawer
        title={`Update - ${this.state.name}`}
        visible={visible}
        placement='right'
        closable={true}
        onClose={onClose}
        width={'80%'}>
        <div className='form-box'>
          <div className='card unizportal'>
            <Form onSubmit={this.handleSubmit}>
              <Card>
                <Row gutter={16} className={'wrapBox'}>
                  {inputTypes.fields.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item.customField
                          ? <Col span={item.span ? item.span : 8}>
                            {item.customField}
                          </Col>
                          : <Col
                            span={item.span ? item.span : 8}
                            key={key}
                            style={
                              item.extraBox1 ? { paddingBottom: 1 } : {}
                            }>
                            <InputBox
                              title={item.label1 ? item.label1 : ''}
                              extra={item.extraBox1}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout}
                              />
                            </InputBox>
                          </Col>}
                      </React.Fragment>
                    )
                  })}
                </Row>
              </Card>

              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={this.props.loading}
                  className={'btn'}>
                  UPDATE
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOtherUser)
