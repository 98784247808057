import React, {useEffect, useRef, useState} from "react";
import {getCommission, updateCommission} from "../actions/commision";
import {Button, Drawer, Icon, Card, Form, Input, notification} from "antd";
import {TableComp} from "sz-react-utils-lite";
import {useDispatch} from "react-redux";
import {InputBox} from "../../../components/_utils/appUtils";

const {TextArea} = Input
let initState = {
    details: ""
}
const UpdateCommissionDrawer = (props) => {
    let dispatch = useDispatch()
    let {commissionId, onClose, visible, onSubmit} = props;
    let [state, setState] = useState(initState);
    let events = {
        setLocalState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        handleSubmit: async (e) => {
            e.preventDefault()
            if (!state.details) {
                notification.warn({message: "Enter transaction details"})
                return
            }
            let resp = await dispatch(updateCommission({...state, commissionId}))
            if (resp && resp.success) {
                onSubmit()
            }
        }
    }
    return (
        <Drawer visible={visible} onClose={onClose} title={'Update Status'} width={'45%'}>
            <Card bordered={false}>
                <Form>
                    <Form.Item>
                        <InputBox title={'Transaction Details'}>
                            <TextArea
                                rows={7}
                                onChange={({target}) => {
                                    events.setLocalState({details: target.value})
                                }} name={'details'}
                                className={'form-control customTextArea'}
                                placeholder={'Transaction details'} value={state.details}/>
                        </InputBox>
                    </Form.Item>
                    <Form.Item className={'mt20 alignRight'}>
                        <Button type='primary' onClick={events.handleSubmit}>
                            SUBMIT
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Drawer>
    )
}

const DirectUniCommissionDrawer = (props) => {
    let tableAppRef = useRef()
    let dispatch = useDispatch()
    let {visible, onClose, universityId} = props;
    let [commissionState, setCommissionState] = useState({
        commissionId: "",
        visible: false
    })
    const getCommissionData = async (params) => {
        return new Promise(async (resolve) => {
            params.universityId = universityId;
            const response = await dispatch(getCommission(params));
            resolve(response)
        })
    };

    const columns = [
        {
            title: 'Intake',
            dataIndex: 'intakeMonth',
            key: 'intakeMonth',
            render: (text, record) => {
                return (
                    <>
                        {text}, {record.intakeYear}
                    </>
                )
            }
        },
        {
            title: 'Total Students',
            dataIndex: 'totalStudent',
            key: 'totalStudent',
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                )
            }
        },
        {
            title: 'Total Tuition Fee',
            dataIndex: 'totalTuitionFee',
            key: 'totalTuitionFee',
            render: (text, record) => {
                return (
                    <>
                        CAD {text}
                    </>
                )
            }
        },
        {
            title: 'Total Commission',
            dataIndex: 'totalCommission',
            key: 'totalCommission',
            render: (text, record) => {
                return (
                    <>
                        CAD {text}
                    </>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                let {status} = record;
                return (
                    <>
                        {status == 'Pending' ? <>
                            <a className={'btn btn-xs btn-default'} onClick={() => {
                                events.showDrawer(record._id)
                            }}>Update Status</a>
                        </> : ""}
                    </>
                )
            }
        },
    ];

    const events = {
        showDrawer: (commissionId) => {
            setCommissionState({
                commissionId,
                visible: true
            })
        },
        hideDrawer: () => {
            setCommissionState({
                commissionId: "",
                visible: false
            })
        },
        hideAndUpdateDrawer: () => {
            setCommissionState({
                commissionId: "",
                visible: false
            })
            tableAppRef.current.reload()
        },

    }

    return (
        <Drawer
            title={`Commission List`}
            width={'80%'}
            onClose={onClose}
            visible={visible}>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='card-body table-responsive'>
                            <TableComp
                                ref={tableAppRef}

                                columns={columns}
                                apiRequest={getCommissionData}
                                rowKey={(record) => record._id}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {commissionState.visible ?
                <UpdateCommissionDrawer
                    {...commissionState}
                    onSubmit={events.hideAndUpdateDrawer}
                    onClose={events.hideDrawer}/>
                : ""}
        </Drawer>
    );
};
export default DirectUniCommissionDrawer;
