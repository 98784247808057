import _ from 'lodash'
import { apiUrl, casitaUrl, housematesUrl, partnerCode } from '../../settings'

export const singleCountryUrl = () => {
  let url = `student-accommodation/uk?partnerCode=${partnerCode}`
  return `${casitaUrl}/${url}`
}
export const universityListUrl = () => {
  return apiUrl + '/unizhome/universityList'
}

export const accommodationListUrl = data => {
  let { property_slug } = data
  return housematesUrl + `/api/properties/${property_slug}`
}
export const addEnquiryUrl = () => {
  return apiUrl + '/unizhome/enquiry'
}
export const searchDataUrl = () => {
  let url = `student-accommodation/search-data?partnerCode=${partnerCode}`
  return `${casitaUrl}/${url}`
}
export const singleRoomUrl = data => {
  // console.log(data, 'this is single data')
  let { room_slug } = data
  if (!room_slug) {
    room_slug = data
  }
  return housematesUrl + `/api/rooms/${room_slug}`
}
// export const propertiesListUrl = (data) => {
//     let {
//         page,
//         provider_type_id,
//         city,
//         roomType,
//         cancellationPolicy,
//         facilities,
//         covid,
//         novisa,
//         noplace,
//     } = data;

//     let baseUrl = `/student-accommodation/uk/${city}?partnerCode=${partnerCode}&page=${page}`;
//     if (provider_type_id) {
//         baseUrl += `&provider_type_id=${provider_type_id}`;
//     }

//     if (roomType) {
//         baseUrl += `&room-types=${roomType}`;
//     }

//     if (facilities) {
//         baseUrl += `&facilities=${facilities}`;
//     }

//     if (covid || novisa || noplace) {
//         baseUrl += `&covid=${covid}&no-visa=${novisa}&no-place=${noplace}`;
//     }
//     return casitaUrl + baseUrl;
// };

export const propertiesListUrl = data => {
  // console.log('you are inside property url')
  let {
    page,
    provider_type_id,
    city,
    roomType,
    cancellationPolicy,
    facilities,
    covid,
    novisa,
    noplace,
    per_page
  } = data
  // console.log('this is city', city)
  let baseUrl = `/api/properties/?filter[city]=${city}&per_page=50`
  // console.log('this is url', baseUrl)

  if (provider_type_id) {
    baseUrl += `&provider_type_id=${provider_type_id}`
  }

  if (roomType) {
    baseUrl += `&room-types=${roomType}`
  }

  if (facilities) {
    baseUrl += `&facilities=${facilities}`
  }

  if (covid || novisa || noplace) {
    baseUrl += `&covid=${covid}&no-visa=${novisa}&no-place=${noplace}`
  }
  // console.log(baseUrl, 'baseurlllllllll')
  return housematesUrl + baseUrl
}
export const singleRoomBookingPeriodUrl = roomId => {
  return housematesUrl + `/api/rooms/${roomId}/booking-periods`
}

export const startCheckOutUrl = roomId => {
  return housematesUrl + `/api/rooms/${roomId}/checkout/start`
}
export const submitOnNoGuaranterUrl = () => {
  return housematesUrl + `/api/enquiries`
}
export const finalCheckOutUrl = roomId => {
  return housematesUrl + `/api/rooms/${roomId}/checkout`
}
export const roomsListUrl = data => {
  let { city, amenities, minPrice, maxPrice, date } = data
  let baseUrl = `/api/rooms?filter[city]=${city}&per_page=${data.per_page}&page=${data.page}`
  if (amenities && amenities.length) {
    // let listA = ''
    // _.each(amenities, item => {
    //   console.log(item, 'ddddddddddddddd')
    //   listA = `${item}=true`
    // })
    let listA = amenities.map(item => `${item}=true`).join(',')

    baseUrl += `&filter[amenities]=[${listA}]`
  }
  if (date) {
    baseUrl += `&filter[move_in_date]=${date}`
  }
  if (minPrice !== undefined || maxPrice !== undefined) {
    const priceRangeFilter = []

    if (minPrice !== undefined) {
      priceRangeFilter.push(`min=${minPrice}`)
    }

    if (maxPrice !== undefined) {
      priceRangeFilter.push(`max=${maxPrice}`)
    }

    baseUrl += `&filter[price_range]=${`[${priceRangeFilter.join(',')}]`}`
  }

  return housematesUrl + baseUrl
}

export const cityListUrl = () => {
  return housematesUrl + `/api/cities?per_page=1000`
}

export const getBookingPeriodsUrl = roomId => {
  return housematesUrl + `/api/rooms/${roomId}/booking-periods`
}

export const getRoomDataUrl = roomId => {
  return housematesUrl + `/api/rooms/${roomId}`
}
