import {
    Button,
    Col,
    Icon,
    Row,
    Select,
    Tooltip,
} from 'antd'
import lodash from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {
    Intakes,
    departmentObj,
    fcmtUniversity,
    filterOption,
    longDisplayDate,
    newFormatDisplayDate
} from '../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import {listAllCampus} from '../../campus/actions/campus'
import {listAllUsers} from '../../users/actions/user'
import DocumentDrawer from '../drawers/studentDocumentDrawer'
import {canadaFcmtVisaApprovedAppListFxn} from "../../fcmtApplications/actions";

const {Option} = Select
const RenderComment = props => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && (
            <div>
                {item.addedByUserId && item.addedByUserId.name ? (
                    <div className={'alignRight colorPrimary font12'}>
                        (Added By : {item.addedByUserId.name} )
                    </div>
                ) : null}
                <div className={hideV ? 'appCommentDotsReplace' : ''}>
                    {item && item.text ? item.text : ''}
                </div>
                {item && item.text.length && item.text.length > 50 ? (
                    <div className={'alignRight'}>
                        {hideV ? (
                            <a
                                onClick={() => {
                                    setHideV(false)
                                }}>
                                Show More
                            </a>
                        ) : (
                            <a
                                onClick={() => {
                                    setHideV(true)
                                }}>
                                Show Less
                            </a>
                        )}
                    </div>
                ) : (
                    ''
                )}
            </div>
        )
    )
}
const OfferActionComponent = props => {
    let {file} = props
    return (
        <div>
            {file && file.path ? (
                <Tooltip title={'Download Offer Letter'}>
                    <a download={file.fileName} href={file.path}>
                        <img src={'/assets/icons/cloud-computing-download.png'}/>
                    </a>
                </Tooltip>
            ) : (
                ''
            )}
        </div>
    )
}
const LoaRequestActionComponent = props => {
    let {val} = props
    return (
        <div>
            {val ? (
                <Tooltip title={val}>
                    <a>
                        <img
                            src={`${
                                val == 'Pending'
                                    ? '/assets/icons/pendingCheck.png'
                                    : '/assets/icons/doneCheck.png'
                            }`}
                            className={'sm'}
                        />
                    </a>
                </Tooltip>
            ) : null}
        </div>
    )
}
const LoaActionComponent = props => {
    let {val} = props
    return val && val.path ? (
        <>
            <Tooltip title={'Download LOA'}>
                <a download={val.fileName} href={val.path}>
                    <img src={'/assets/icons/cloud-computing-download.png'}/>
                </a>
            </Tooltip>
        </>
    ) : (
        ''
    )
}
const FcmtVisaApproved = (props) => {
    const [studentData, setStudentData] = useState({})
    const [name, setName] = useState('')
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [totalApplication, setTotalApplication] = useState(0)
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [campusList, setCampusList] = useState([])
    const [campusId, setCampusId] = useState('')
    const [intake, setIntake] = useState('')
    let [courseName, setCourseName] = useState('')
    let [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false)
    let [marketingUserList, setMarketingUserList] = useState([])
    let [marketingUserId, setMarketingUserId] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        loadAgentData()
        setFieldByParams()
        loadMarketingUsers()
        loadCampusList()
        // loadUniversity()
    }, [])
    const tableAppRef = useRef()


    let apiRequest = params => {
        return new Promise(async resolve => {
            let resp = await getParamsForApplicationList()
            params.appUniversityId = fcmtUniversity

            params = {...params, ...resp}
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let resp = await canadaFcmtVisaApprovedAppListFxn({...params})
                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }
        })
    }

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()

        if (resp.campusId) {
            setCampusId(resp.campusId)
        }

        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.courseName) {
            setCourseName(resp.courseName)
        }
        if (resp.marketingUserId) {
            setMarketingUserId(resp.marketingUserId)
        }

        if (resp.name) {
            setName(resp.name)
        }

    }

    const loadAgentData = async () => {
        let params = {
            results: 10000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }

    const loadCampusList = async () => {
        let params = {}
        params.sortField = 'campusName'
        params.sortOrder = 'ascend'
        params.results = 50
        params.count = 50
        params.campusUniversity = fcmtUniversity
        params.select = ['campusName']
        let {data} = await dispatch(
            listAllCampus({
                ...params,
                regExFilters: ['campusAddress', 'campusName']
            })
        )
        setCampusList(data)
    }

    const events = {
        reloadFxn: () => {
            tableAppRef.current.reload()
        },

        refreshApplication: () => {
            tableAppRef.current.reload()
        },

        openDocumentDrawer: async record => {
            setStudentData(record)
            setVisibleDocumentDrawer(true)
        },
        closeDocumentDrawer: async record => {
            setStudentData({})
            setVisibleDocumentDrawer(false)
        },
        enterName: (value) => {
            setName(value)
        }
    }

    const getParamsForApplicationList = () => {
        return new Promise(resolve => {
            let searchParams = new URLSearchParams(window.location.search)
            let name = searchParams.get('name')
            let campusId = searchParams.get('campusId')
            let agentId = searchParams.get('agentId')
            let intake = searchParams.get('intake')
            let courseName = searchParams.get('courseName')
            let marketingUserId = searchParams.get('marketingUserId')
            let obj = {}

            if (name) {
                obj.name = name
            }

            if (campusId) {
                obj.campusId = campusId
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (intake) {
                obj.intake = intake
            }
            if (courseName) {
                obj.courseName = courseName
            }
            if (marketingUserId) {
                obj.marketingUserId = marketingUserId
            }

            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let obj = {}
        if (name) {
            obj.name = name
        }
        if (campusId) {
            obj.campusId = campusId
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (intake) {
            obj.intake = intake
        }
        if (courseName) {
            obj.courseName = courseName
        }
        if (marketingUserId) {
            obj.marketingUserId = marketingUserId
        }
        dispatch(
            getUrlPushWrapper('fcmtVisaApproved', {
                ...obj
            })
        )
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)
    }
    const clearFxn = async () => {
        let obj = {}
        setName('')
        setCampusId('')
        setAgentId('')
        setIntake('')
        setCourseName('')
        setMarketingUserId('')
        dispatch(
            getUrlPushWrapper('fcmtVisaApproved', {
                ...obj
            })
        )
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }

    const columns = [
        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            searchTextName: 'applications.id',
            width: 60,
            render: item => {
                return <div style={{width: 60}}>{item}</div>
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>
                        {item}
                        <br/>
                        {newFormatDisplayDate(record.dateOfBirth)}
                        <label className={'label label-tag'}>
                            {record.studentShore ? `(${record.studentShore})` : null}
                        </label>
                    </div>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: item => {
                return <div style={{width: 150}}>{item}</div>
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.month && item.year
                            ? `${item.month}, ${item.year}`
                            : ''}
                    </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 85,
            render: (item, record) => {
                return (
                    <div style={{width: 85}}>
                        {item && item.campusName ? item.campusName : ''}
                    </div>
                )
            }
        },
        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 100,
            render: (val, record) => {
                let {agentId, statusList, status} = record.applications
                return (
                    <div style={{width: 100}}>
                        {val && val.name ? val.name : ''}
                        <br/>
                        <div>
                            Agent :{' '}
                            {agentId && agentId.companyName ? agentId.companyName : ''}
                            <br/>
                            {agentId && agentId.mobile ? (
                                <>
                                    {' '}
                                    <Icon
                                        type={'phone'}
                                        style={{
                                            position: 'relative',
                                            bottom: 3
                                        }}
                                    />{' '}
                                    {agentId.mobile}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </div>
                )
            }
        },
        {
            title: 'Family / Given Name',
            key: 'applications.familyName',
            dataIndex: 'applications.familyName',
            width: 100,
            render: (item, record) => {
                return (
                    <div style={{width: 100}}>
                        {item && item ? (
                            <div>
                                Family Name : <span style={{fontWeight: 'bold'}}>{item}</span>
                            </div>
                        ) : (
                            ''
                        )}
                        {record && record.applications && record.applications.givenName ? (
                            <div>
                                Given Name :{' '}
                                <span style={{fontWeight: 'bold'}}>
                  {record.applications.givenName}
                </span>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )
            }
        },

        {
            title: 'Conditional Offer Letter',
            key: 'applications.offerReceiveLetter',
            dataIndex: 'applications.offerReceiveLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <OfferActionComponent file={val} record={record} events={events}/>
                    </div>
                )
            }
        },
        {
            title: 'Rqst LOA',
            key: 'applications.loaRequested',
            dataIndex: 'applications.loaRequested',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <LoaRequestActionComponent
                            val={val}
                            record={record}
                            events={events}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Download LOA',
            key: 'applications.loaLetter',
            dataIndex: 'applications.loaLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <LoaActionComponent val={val} record={record} events={events}/>
                    </div>
                )
            }
        },


        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 150,
            render: (item, record) => {
                let {applications} = record
                return (
                    <div className={'fcmtDiv'} style={{width: 150}}>
                        {item.includes('File') || item.includes('Visa') || item.includes('Revoked') ? (
                            <div>
                                {item}
                                <br/>
                                {applications.statusList && applications.statusList.date ? (
                                    <div>
                                        Date : {newFormatDisplayDate(applications.statusList.date)}
                                        <br/>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                )
            }
        },

        {
            title: 'GCKey',
            key: 'applications.gcKey',
            dataIndex: 'applications.gcKey',
            width: 150,
            render: (val, record) => {
                return (
                    <div style={{width: 150}}>
                        {val ? <RenderComment item={{text: val}}/> : null}
                    </div>
                )
            }
        },

        {
            title: 'Marketing',
            dataIndex: 'marketingUser',
            width: 120,
            key: 'marketingUser',
            render: (item, record) => {
                let {marketingUserId} = record.applications
                return (
                    <React.Fragment>
                        {marketingUserId && marketingUserId.name ? marketingUserId.name : ""}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Documents',
            key: 'document',
            dataIndex: 'document',
            width: 120,
            render: (val, record) => {
                return (
                    <div className={'fcmtDiv'}>
                        <button
                            className={`bs_btn bs-default mt10 bs-xs`}
                            onClick={() => events.openDocumentDrawer(record)}>
                            View Documents
                        </button>
                    </div>
                )
            }
        },
    ]

    const loadColumns = () => {
        let columnArr = []
        lodash.each(columns, item => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }

    const loadMarketingUsers = async () => {
        let obj = {
            userType: 'branchUser',
            department: departmentObj.marketing,
            results: 100,
            select: ['name', 'agents', 'assignStateCountry']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setMarketingUserList(data)
    }

    const extra = (
        <div className={'mt10'}>
            <Row gutter={12}>
                <Col md={5} sm={5} xs={8} lg={5}>
                    <div className="search-box-table round">
                        <input
                            className="form-control form-control"
                            type="search"
                            placeholder="Search by name..."
                            value={name}
                            onChange={e => {
                                events.enterName(e.target.value)
                            }}
                        />
                        <img src="/dist/img/search.png" alt=""/>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Status"
                            onChange={item => setCampusId(item)}
                            className={'antSelect'}
                            placeholder={'Campus'}
                            allowClear={true}
                            value={campusId || undefined}>
                            {campusList && campusList.length
                                ? campusList.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>
                                            {item.campusName}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Search By Agent"
                            filterOption={filterOption}
                            className={'antSelect'}
                            allowClear={true}
                            placeholder={'Agent'}
                            showSearch={true}
                            onChange={item => setAgentId(item)}
                            value={agentId || undefined}>
                            {allAgent && allAgent.length
                                ? allAgent.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>
                                            {item.companyName}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Search By Marketing User"
                            filterOption={filterOption}
                            className={'antSelect'}
                            allowClear={true}
                            placeholder={'Marketing User'}
                            onChange={item => setMarketingUserId(item)}
                            value={marketingUserId || undefined}>
                            {marketingUserList && marketingUserList.length
                                ? marketingUserList.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>
                                            {item.name}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Intake"
                            onChange={item => setIntake(item)}
                            filterOption={filterOption}
                            className={'antSelect'}
                            allowClear={true}
                            showSearch={true}
                            placeholder={'Intake'}
                            value={intake || undefined}>
                            {Intakes && Intakes.length
                                ? Intakes.map((item, key) => {
                                    return (
                                        <Option value={item} key={key}>
                                            {item}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>
                <Col span={5}>
                    <div className="sort-box-table custom-sort-box-new">
                        <div className="search-box-table round">
                            <input
                                className="form-control form-control"
                                type="search"
                                placeholder="course name..."
                                value={courseName}
                                onChange={e => {
                                    setCourseName(e.target.value)
                                }}
                            />
                            <img src="/dist/img/search.png" alt=""/>
                        </div>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={12} lg={4}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>
                        Search
                    </Button>
                    <Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>
            </Row>
        </div>
    )

    return (
        <React.Fragment>
            <div>
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="card">
                            <div
                                className="table-head d-flex align-items-center all-student-search"
                                style={{height: 60}}>
                                <Button
                                    onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                    className={'roundGoBackBtn'}
                                    icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>Fcmt Approved Application List</h5>

                                <div className="sort-box-table mark-btn">
                                    Visa Approved (Total - {totalApplication})
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                {extra}
                                <TableComp
                                    columns={loadColumns()}
                                    rowKey={(item, index) => {
                                        return index
                                    }}
                                    apiRequest={apiRequest}
                                    pagination={{
                                        position: 'top',
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '50', '100'],
                                        defaultPageSize: 10,
                                        current: filters.page ? filters.page : 1
                                    }}
                                    extraProps={{scroll: {x: 2000}}}
                                    ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {visibleDocumentDrawer &&
                studentData.applications &&
                studentData.applications._id ? (
                    <DocumentDrawer
                        onClose={events.closeDocumentDrawer}
                        reloadTable={events.reloadFxn}
                        studentData={studentData}
                        applicationId={studentData.applications._id}
                        studentId={studentData._id}
                        visible={visibleDocumentDrawer}
                    />
                ) : null}
            </div>
        </React.Fragment>
    )
}

export default FcmtVisaApproved
