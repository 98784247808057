import React, { useEffect, useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { connect, useDispatch } from 'react-redux'
import { useRef } from 'react/index'
import { getCommissionReportTransactionsFxn, getGicCommissionTotalPendingBalance } from '../actions'
import { Col, Row, Select } from 'antd'
import { filterOption, InputBox } from '../../../components/_utils/appUtils'
import { loadUserListFxn } from '../../users/actions/user'

const { Option } = Select
const GicCommissionReport = () => {
  let tableAppRef = useRef()
  let dispatch = useDispatch()
  let [filters, setFilters] = useState({})
  let [total, setTotal] = useState(0)
  let [gicTransactionData, setGicTransactionData] = useState(0)
  let [agentList, setAgentList] = useState([])
  let [agentId, setAgentId] = useState(0)


  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      if (agentId) {
        params.agentId = agentId
      }
      let resp = await dispatch(getCommissionReportTransactionsFxn({ ...params }))
      setTotal(resp.total)
      resolve(resp)
    })
  }


  const getGicTotalCurrentBalance = async () => {
    let obj = {}
    if (agentId) {
      obj.agentId = agentId
    }
    let resp = await dispatch(getGicCommissionTotalPendingBalance(obj))
    setGicTransactionData(resp)
  }

  let getAgentList = async (params = {}) => {
    params.userType = ['agent']
    params.results = 100000
    params.count = 100000
    let resp = await dispatch(
      loadUserListFxn({
        ...params,
        select: ['name', 'companyName']
      })
    )
    setAgentList(resp.data)
  }

  useEffect(() => {
    getGicTotalCurrentBalance()
    getAgentList()
  }, [])


  let columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (ite, record, index) => {
        return (
          <React.Fragment>
            {index + 1}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Name',
      key: '_id',
      dataIndex: '_id',
      render: (item, record, index) => {
        return (
          <React.Fragment>
            {item.name}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Email',
      key: '_id',
      dataIndex: '_id',
      render: (item, record, index) => {
        return (
          <React.Fragment>
            {item.email}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Phone',
      key: '_id',
      dataIndex: '_id',
      render: (item, record, index) => {
        return (
          <React.Fragment>
            {item.mobile}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Company Name',
      key: '_id',
      dataIndex: '_id',
      render: (item, record, index) => {
        return (
          <React.Fragment>
            {item.companyName}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Gic Funded Amount($)',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (item, record, index) => {
        return (
          <React.Fragment>
            {item ? `${item} CAD` : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Gic Withdrawal($)',
      key: '_id',
      dataIndex: '_id',
      render: (item, record, index) => {
        return (
          <React.Fragment>
            {(record.totalAmount && item.gicWallet) ? (`${record.totalAmount - item.gicWallet} CAD`) : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Gic Current balance($)',
      key: '_id',
      dataIndex: '_id',
      render: (item, record, index) => {
        return (
          <React.Fragment>
            {item && item.gicWallet ? `${item.gicWallet} CAD` : null}
          </React.Fragment>
        )
      }
    }

  ]


  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Gic Commission Report{total && `(${total}) `}</h5>
              <div className='search-box-table'>

              </div>

              <div className='sort-box-table mark-btn d-flex'>

              </div>
            </div>
            <div className='card-body table-responsive'>
              <Row gutter={12} className={'filter_box'}>
                <Col span={8}>
                  <InputBox title={'Search by Agent'}>
                    <Select
                      name='Agent Name'
                      filterOption={filterOption}
                      allowClear={true}
                      showSearch={true}
                      placeholder={'Agents'}
                      onChange={(value) => {
                        setAgentId(value)
                      }}
                      value={agentId || undefined}
                    >
                      {agentList.map((item, key) => {
                        return (
                          <Option value={item._id} key={key}>
                            {item.name}
                          </Option>
                        )
                      })}
                    </Select>
                  </InputBox>
                </Col>
                <Col span={16} className={'alignRight'}>
                  <div className={'expenseAmtBox'}>
                    Total GIC Funded : <span
                    className={'totalAmt '}>
                  {gicTransactionData && gicTransactionData.total ? gicTransactionData.total : null} CAD
                  </span><br />
                    Total GIC Withdrawal : <span
                    className={'pendingAmt'}>
                    {gicTransactionData && gicTransactionData.withdrawal ? gicTransactionData.withdrawal : null} CAD
                  </span><br />
                    Total Pending Balance : <span
                    className={'successAmt'}>
                    {gicTransactionData && gicTransactionData.balance ? gicTransactionData.balance : null} CAD
                  </span><br />
                  </div>
                </Col>
                <Col span={24}>
                  <div className={'btn_group'}>
                    <a className={'default_btn'}
                       onClick={() => {
                         tableAppRef.current.reload()
                         getGicTotalCurrentBalance()
                       }}
                    >Search</a>
                    <a className={'default_btn'}
                       onClick={() => {
                         setAgentId('')
                         setTimeout(() => {
                           tableAppRef.current.reload()
                           getGicTotalCurrentBalance()
                         }, 500)
                       }}
                    >Clear</a>
                  </div>
                </Col>
              </Row>
              <TableComp
                columns={columns}
                pagination=
                  {{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    defaultPageSize: 20,
                    current: filters.page ? filters.page : 1
                  }}
                apiRequest={apiRequest}
                ref={tableAppRef}
                extraProps={{ scroll: { x: 1000 } }}
              />
            </div>
          </div>
        </div>
      </div>

    </div>)
}


const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
})
export default connect(
  mapStateToProps,
  null
)(GicCommissionReport)
