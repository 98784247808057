import {apiUrl2, apiUrl} from '../../settings'

export const IntakesUrl = () => {
    return apiUrl + '/intake'
}
export const singleIntakesUrl = id => {
    return apiUrl + '/singleIntake/' + id
}
export const updateIntakeUrl = id => {
    return apiUrl + '/updateIntake/' + id
}
export const deleteIntakesUrl = id => {
    return apiUrl + '/deleteIntake/' + id
}
export const addCourseIntakeUrl = () => {
    return apiUrl + '/api/addCourseIntake'
}
export const addCampusIntakeInCourseUrl = () => {
    // 11 aug 2023 update
    return apiUrl + '/api/addCampusIntakeInCourse'
}

export const campusIntakesListUrl = () => {
    // 28 sep 2023 update
    return apiUrl + '/api/campusIntakesList'
}
export const updateCampusIntakeTimeUrl = () => {
    return apiUrl + '/api/updateCampusIntakeTime'
}
export const canadaOnshoreManagerUrl = () => {
    return apiUrl + '/api/canada-onshore-manager'
}

export const onshoreCanadaCommissionUrl = () => {
  return apiUrl + '/onshore-canada-commission'
}

export const onshoreCanadaQueryUrl = () => {
  return apiUrl + '/onshore-canada/query-add'
}

export const addCallRequestUrl = () => {
  return apiUrl2 + '/requestACallBack'
}
export const requestedCallBackListUrl = () => {
  return apiUrl2 + '/requestedCallBackList'
}

export const updateRequestedCallBackUrl = () => {
  return apiUrl2 + '/updateRequestACallBack'
}
