import React from "react"
import PrivateCollegePendingTTApplicationList from "../privateCollege/privateCollegePendingTTApplicationList"
import {RoyalArtsCollege} from "../../../components/_utils/appUtils";

const CiraPendingTTApplicationList = () => {
    return (
        <PrivateCollegePendingTTApplicationList
            appName={'Cira College'}
            universityId={RoyalArtsCollege}
            redirectKey={'cira.ciraUploadTT'}
        />
    )
}
export default CiraPendingTTApplicationList
