import { apiUrl } from "../../../settings";

export const addCommissionUrl = () => {
    return apiUrl + '/directUni/addCommission'
}

export const getCommissionUrl = () => {
    return apiUrl + '/directUni/commissionList'
}

export const updateCommissionUrl = () => {
    return apiUrl + '/directUni/commissionUpdate'
}