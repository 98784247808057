import React from 'react'
import PaidApplicationListComponent from '../privateCollege/privateCollegePaidApplicationList'
import { RoyalArtsCollege } from '../../../components/_utils/appUtils'

const CiraPaidApplicationList = () => {
  return (
    <PaidApplicationListComponent
      appName={'Cira College'}
      redirectKey={'cira.ciraPaidTT'}
      universityId={RoyalArtsCollege} />
  )
}
export default CiraPaidApplicationList
