import React, { Component } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import {
  Tooltip, Icon, Form, Row, Col, Modal, notification
} from 'antd'
import { connect } from 'react-redux'
import { listAllUsers, uploadRegCertFxn } from '../actions/user'
import EditSubAgent from './editSubAgent'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import FileInput from '../../../components/_utils/fileInput'

class AllUsers extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedUser: {},
      visibleUpdateSubAgent: false,
      certificateFileModal: {
        open: false,
        id: ''
      },
      registrationCertificate: null
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {
  }

  apiRequest = (params) => {
    let { dispatch } = this.props
    return new Promise(async (resolve) => {
      params.userType = 'subAgent'
      params.sortField = 'name'
      params.sortOrder = 'ascend'
      params.block = [false, true]
      resolve(dispatch(listAllUsers({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email']
      })))
    })
  }

  closeEditSubAgent = () => {
    this.setState({
      selectedUser: {},
      visibleUpdateSubAgent: false
    }, () => {
      this.tableRef.current.reload()
    })
  }

  handleCertificateFileSubmit = async (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    if (!(this.state.registrationCertificate && this.state.registrationCertificate.name)) {
      notification.warning({
        message: 'Choose Registration Certificate'
      })
      return
    }
    let obj = {}
    let fd = new FormData()
    obj._id = this.state.certificateFileModal.id
    obj.block = false
    fd.append('obj', JSON.stringify(obj))
    if (this.state.registrationCertificate && this.state.registrationCertificate.name) {
      fd.append('agentCertificate', this.state.registrationCertificate)
    }
    let x = await dispatch(uploadRegCertFxn(fd))
    if (x && x.success) {
      this.setState({ certificateFileModal: { open: false, id: '' }, registrationCertificate: null })
      this.tableRef.current.reload()
    }
  }

  handleFileChange = (e) => {
    if (e && e.target && e.target.files) {
      const { files } = e.target
      this.setState({ registrationCertificate: files[0] || null })
    }
  }

  render() {
    let { selectedUser, visibleUpdateSubAgent } = this.state
    // const { form: { getFieldDecorator, formItemLayout } } = this.props
    const columns = [
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email'
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile'
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        searchTextName: 'address'
      },
      {
        title: 'City/State/Country',
        dataIndex: 'cityName',
        key: 'cityName',
        render: (item, record) => {
          return (
            <React.Fragment>
              {item ? item : ''}
              {record && record.stateName ? <span>, {record.stateName}</span> : ''}
              {record && record.countryName ? <span>, {record.countryName}</span> : ''}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 170,
        render: (item, record) => {
          let { block } = record
          return (
            <div style={{ textAlign: 'left' }}>
              <div>
                {block ? (
                  <label className='label label-danger sm'>Not Activated</label>
                ) : null}
                {!block ? (
                  <label className='label label-success-green sm'>Activated</label>
                ) : null}
              </div>
            </div>
          )
        }
      },
      /*{
        title: 'Logo',
        dataIndex: 'logo',
        key: 'logo',
        render: (val) => {
          return (
            <React.Fragment>
              {val && val.url ? <img src={val.url} className={'imgSm'}/> : null}
            </React.Fragment>
          )
        }
      },*/
      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        render: (val, record) => {
          let { block } = record
          return (
            <React.Fragment>
              <Tooltip title='Edit'>
                <a
                  className={'btn btn-default sm roundNew'}
                  onClick={() => {
                    this.setState({
                      visibleUpdateSubAgent: true,
                      selectedUser: record
                    })
                  }}>
                  Edit
                </a>
              </Tooltip> &nbsp;
              {block ? (
                <>
                  <a className={'btn btn-default sm roundNew'}
                     onClick={() => {
                       this.setState({ certificateFileModal: { open: true, id: record._id } })
                     }}>
                    Upload Certificate
                  </a>
                </>
              ) : null}
            </React.Fragment>
          )
        }
      }
    ]

    return (
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>All Sub Agents</h5>
                <div className='search-box-table'>
                </div>
                <CheckMyRights rightList={['agent']}>
                  <div className='sort-box-table mark-btn'>
                    <a className='btn' href={'/subAgent/add'}>
                      <img src={'../dist/icons/plus.png'} className={'plus'} />
                      Add Sub Agent</a>
                  </div>
                </CheckMyRights>
              </div>
              <div className='card-body table-responsive'>
                <TableComp columns={columns}
                           ref={this.tableRef}
                           apiRequest={this.apiRequest} />
              </div>
              <Modal
                visible={this.state.certificateFileModal.open}
                onCancel={() => {
                  this.setState({ certificateFileModal: { open: false, id: '' } })
                }}
                open={this.state.certificateFileModal.open}
                width={'40%'}
                placement='right'
                title={'Upload Registration Certificate'}
                footer={null}
              >
                <div>
                  <Form onSubmit={this.handleCertificateFileSubmit}>
                    <Row gutter={24}>
                      <Col span={24} md={24} sm={24} xs={24}>
                        <FileInput
                          name='registrationCertificate'
                          label='Registration Certificate'
                          chooseDocument={(target) => this.handleFileChange(target)}
                        />
                      </Col>
                    </Row>
                    <button
                      className={'btn btn-success roundNew mt10'}>
                      Submit
                    </button>
                  </Form>
                </div>
              </Modal>
            </div>
          </div>
        </div>
        {visibleUpdateSubAgent ? <EditSubAgent
          visible={visibleUpdateSubAgent}
          onClose={() => this.closeEditSubAgent()}
          userData={selectedUser} /> : ''}
      </div>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUsers)
