import React from 'react'
import CommissionList from '../privateCollege/commissionList'
import { RoyalArtsCollege } from '../../../components/_utils/appUtils'

const CiraGenerateCommission = () => {
  return (
    <CommissionList
      appName={'Cira College'}
      redirectKey={'cira.ciraGenerateCommission'}
      universityId={RoyalArtsCollege} />
  )
}
export default CiraGenerateCommission
