import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import {
  addComplaintFeedbackUrl,
  addFeedbackCommentUrl,
  addFeedbackUrl,
  assignToUrl, closeFeedbackUrl,
  deleteFeedbackUrl, feedbackCommentsUrl,
  listFeedbackUrl, singleFeedbackApiUrl,
  singleFeedbackUrl
} from '../../feedback/api/feedBackApis'
import { addInstituteCommentUrl, instituteEventsCommentsUrl } from '../../institutes/api'


export const addFeedback = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addFeedbackUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const listFeedback = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(listFeedbackUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}


export const deleteFeedback = (id) => async (dispatch) => {
  const valData = { _id: id }
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteFeedbackUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deleted Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const assignToUserFxn = (valdata) => async (dispatch) => {

  dispatch(showPageLoad())
  let { data } = await axios.post(assignToUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deleted Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}


export const closeFeedbackFxn = (valdata) => async (dispatch) => {

  dispatch(showPageLoad())
  let { data } = await axios.post(closeFeedbackUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Feedback closed successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const addFeedbackCommentFxn = (feedbackId, comments) => async dispatch => {
  const valData = {
    feedbackId: feedbackId,
    comment: comments
  }
  dispatch(showPageLoad())
  let { data } = await axios.post(addFeedbackCommentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getFeedbackCommentsFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(feedbackCommentsUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const addComplaintFeedbackFxn = (feedbackId, agentFeedbackStatus) => async dispatch => {
  const valData = {
    feedbackId: feedbackId,
    agentFeedbackStatus: agentFeedbackStatus
  }
  dispatch(showPageLoad())
  let { data } = await axios.post(addComplaintFeedbackUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const singleFeedbackFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleFeedbackApiUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}


