import React, { useEffect, useRef, useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { connect, useDispatch } from 'react-redux'
import { DefaultTablePagination, displayDate, displayTime } from '../../../components/_utils/appUtils'
import { Col, Icon, notification, Popconfirm, Select, Tooltip } from 'antd'
// import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { deleteInstituteEventFxn, instituteEventListFxn, updateInstituteEventStatusFxn } from '../action'
import InstituteEventDrawer from './instituteEventDrawer'
import StudentDrawer from './studentDrawer'

const { Option } = Select
const InstituteEventsList = (props) => {
  let { currentUser } = props
  const [totalInstitutes, setTotalInstitutes] = useState('')
  const [selectedStatus, setSelectedStatus] = useState(undefined)
  const [total, setTotal] = useState(0)
  const [updateEventSlot, setUpdateEventSlot] = useState({
    visible: false,
    eventId: ''
  })
  const [showStudentDrawer, setShowStudentDrawer] = useState({
    visible: false,
    eventId: ''
  })


  const tableRef = useRef(null)
  const dispatch = useDispatch()

  const apiRequest = async (params) => {
    return new Promise(async (resolve) => {
      if (selectedStatus) {
        params.status = selectedStatus
      }
      const resp = await dispatch(instituteEventListFxn(params))
      setTotal(resp.total)
      resolve(resp)
    })
  }
  useEffect(() => {
    tableRef.current.reload()
  }, [selectedStatus])

  const handleStatusChange = async (value) => {
    setSelectedStatus(value)
  }


  const events = {
    showUpdateState: (eventId) => {
      setUpdateEventSlot({
        visible: true,
        eventId: eventId
      })
    },
    hideUpdateState: async () => {
      tableRef.current.reload()
      setUpdateEventSlot({
        visible: false,
        eventId: ''
      })

    },
    submitUpdateState: () => {
      tableRef.current.reload()
      setUpdateEventSlot({
        visible: false,
        eventId: ''
      })


    },
    openStudentDrawer: (eventId) => {
      setShowStudentDrawer({
        visible: true,
        eventId: eventId
      })
    },
    closeStudentDrawer: async () => {

      setShowStudentDrawer({
        visible: false,
        eventId: ''
      })

    },
    shareLink: (eventId) => {
      let url = `https://unizportal.com/event-and-student-registration?eventId=${eventId}`
      let message = encodeURIComponent(url)
      let whatsapp_url = 'whatsapp://send?text=' + message
      window.open(whatsapp_url, '_blank')
    },
    copyFxn: (eventId) => {
      let url = `https://unizportal.com/event-and-student-registration?eventId=${eventId}`
      navigator.clipboard.writeText(url)
      notification.success({ message: 'Event share link copied successfully.' })
    }
  }

  const columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (ite, record, index) => {
        return <React.Fragment>{index + 1}</React.Fragment>
      }
    },
    {
      title: 'Institute',
      dataIndex: 'instituteId',
      key: 'instituteId',
      render: (item, record) => {
        return (
          <>
            {item && item.companyName ? item.companyName : ''}
          </>
        )
      }
    },
    {
      title: 'Venue',
      dataIndex: 'venue',
      key: 'venue'

      // searchTextName: 'venue'
    },
    {
      title: 'State',
      key: 'state',
      sorter: true,
      dataIndex: 'state'
      // searchTextName: 'state'
    },
    {
      title: 'City',
      key: 'city',
      sorter: true,
      dataIndex: 'city'
      // searchTextName: 'city'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      // searchTextName: 'date'
      render: (item, record) => {
        return (
          <>
            {item ? displayDate(item) : ''}
          </>
        )
      }
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      // searchTextName: 'date'
      render: (item, record) => {
        return (
          <>
            {item ? displayTime(item) : ''}
          </>
        )
      }
    },

    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      width: 250
      // searchTextName: 'details'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (item) => {
        return (
          <>
            {item == 'Pending' ? <label className={'label label-success label-sm'}>Pending</label> : ''}
            {item == 'Approved' ? <label className={'label label-success-green  label-sm'}>Approved</label> : ''}
            {item == 'Cancel' ? <label className={'label label-danger  label-sm'}>Cancel</label> : ''}
            {item == 'Reject' ? <label className={'label label-danger label-sm'}>Rejected</label> : ''}
          </>
        )
      }
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      width: 120,
      render: (val, record) => {
        return (
          <div className={'d-flex align-items-center justify-content-between'}>

            <Tooltip title='View Event'>
              <button className='button btn-view sm' onClick={() => events.showUpdateState(record._id)}>
                View
              </button>
            </Tooltip>
            <Tooltip title='View Students'>
              <button className='button btn-view sm ml5' onClick={() => events.openStudentDrawer(record._id)}>
                Students
              </button>
            </Tooltip>
            <Tooltip title='Share Link'>
              <button className={'button btn-view ml5 mt-0'} onClick={() => {
                events.shareLink(record._id)
              }}>
                <Icon type='share-alt' />
              </button>
            </Tooltip>
            <Tooltip title='Copy Link'>
              <button className={'button btn-view ml5 mt-0'} onClick={() => {
                events.copyFxn(record._id)
              }}>
                <Icon type='copy' />
              </button>
            </Tooltip>
            {/*            <Tooltip title='View Students'>
              <button className='btn' onClick={() => events.openStudentDrawer(record._id)}>
                <Icon type={'user'} style={{ fontSize: '17px', color: '#666' }} />
              </button>
            </Tooltip>*/}

          </div>
        )
      }
    }
  ]

  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center justify-content-between'>
            <h5>All Institutes Events <small>(Total : {total})</small></h5>
          </div>
          <Col md={6} sm={6} xs={7} lg={6}>
            <div className='sort-box-table custom-sort-box-new mt5'>
              <Select
                name='Status'
                placeholder={'Filter by status'}
                onChange={handleStatusChange} value={selectedStatus} className={'antSelect'}
                allowClear={true}>
                <Option value='Pending'>Pending</Option>
                <Option value='Approved'>Approved</Option>
                <Option value='Cancel'>Cancel</Option>
                <Option value='Reject'>Rejected</Option>
              </Select>
            </div>
          </Col>


          <TableComp
            columns={columns}
            ref={tableRef}
            pagination={DefaultTablePagination()}
            apiRequest={apiRequest}
          />
        </div>
      </div>

      {updateEventSlot.visible ?
        <InstituteEventDrawer
          {...updateEventSlot}
          dispatch={dispatch}
          onSubmit={events.submitUpdateState}
          onClose={() => {
            events.hideUpdateState()
          }}
          currentUser={currentUser}

        /> : ''}

      {showStudentDrawer.visible ?
        <StudentDrawer
          {...showStudentDrawer}
          onClose={() => events.closeStudentDrawer()}
          eventId={showStudentDrawer.eventId}
        /> : ''}


    </div>
  )
}


const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
})
export default connect(
  mapStateToProps,
  null
)(InstituteEventsList)
