import React, { useState, useEffect, useRef } from 'react'
import {
  Input,
  Button,
  Select,
  List,
  Popconfirm,
  Icon,
  DatePicker,
  Modal,
  Table,
  notification, Tooltip
} from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'antd/dist/antd.css'
import '../style.css'
import {
  taskLists,
  addTask,
  updateTask,
  deleteTask,
  getTaskGrouped,
  taskUsersFxn,
  assignedUserList
} from '../actions/taskManager'
import { Collapse } from 'antd'
import {
  DefaultTablePagination,
  displayDate, InputBox,
  taskDeleteRights,
  taskUpdateRights
} from '../../../components/_utils/appUtils'
import RenderTask from './taskComponent'
import CommentDrawer from './commentDrawer'
import { listAllUsers} from "../../users/actions/user";

const { Option } = Select
let { TextArea } = Input
let initFilter = {
  addedBy: undefined,
  addedByUserId: undefined
}

const TaskManager = (props) => {
  let { currentUser } = props
  let conRef = useRef()
  const [newTask, setNewTask] = useState('')
  const [deadline, setDeadline] = useState(null)
  const [assignee, setAssignee] = useState([])
  const [priority, setPriority] = useState('Normal')
  const [taskList, setTaskList] = useState([])
  const [showCompleteTasks, setShowCompleteTasks] = useState(undefined)
  const [audioStream, setAudioStream] = useState(null)
  const [videoStream, setVideoStream] = useState(null)
  const [screenStream, setScreenStream] = useState(null)
  const [audioRecording, setAudioRecording] = useState(false)
  const [screenRecording, setScreenRecording] = useState(false)
  const [audioFile, setAudioFile] = useState(null)
  const [screenRecordingFile, setScreenRecordingFile] = useState(null)
  const [videoOn, setVideoOn] = useState(true)
  const [audioOn, setAudioOn] = useState(true)
  const [showScreenRecordingModal, setShowScreenRecordingModal] = useState(false)
  const [screenRecordingDataUrl, setScreenRecordingDataUrl] = useState(null)
  const [showAudioRecordingModal, setShowAudioRecordingModal] = useState(false)
  const [audioRecordingDataUrl, setAudioRecordingDataUrl] = useState(null)
  let [screenChunkArr, setScreenChunkArr] = useState([])
  let [audioChunkArr, setAudioChunkArr] = useState([])
  let [filters, setFilters] = useState(initFilter)
  let [assignedUsers, setAssignedUsers] = useState([])
  let [assigneeState, setAssigneeState] = useState({
    visible: false,
    taskId: ''
  })
  let [assigneeStates, setAssigneeStates] = useState({
    visible: false,
    taskId: ''
  })
  let [screenPlayState, setScreenPlayState] = useState({
    screenUrl: '',
    visible: false
  })
  let [audioPlayState, setAudioPlayState] = useState({
    audioUrl: '',
    visible: false
  })
  let [pageState, setPageState] = useState({ count: 10, page: 1, groupCount: 10, groupPage: 1 })
  let [commentState, setCommentState] = useState({
    visible: false,
    taskId: ''
  })
  let [queryByState, setQueryByState] = useState({
    visible: false,
    taskId: ''
  })
  let [userList, setUserList] = useState([])
  const [priorityFilter, setPriorityFilter] = useState(undefined)
  const [statusFilter, setStatusFilter] = useState(undefined)
  const [deadlineFilter, setDeadlineFilter] = useState(null)
  const [queryByUser, setQueryByUser] = useState([])
  const [selectedUser, setSelectedUser] = useState(undefined)
  const [sortFilter, setSortFilter] = useState(undefined)
  const audioRecorder = useRef(null)
  const screenRecorder = useRef(null)
  const liveVideoRef = useRef(null)


  let [deadLineState, setDeadLineState] = useState({
    visible: false,
    taskId: '',
    deadline: ''
  })
  let [priorityState, setPriorityState] = useState({
    visible: false,
    taskId: ''
  })
  let [showGroupTask, setShowGroupTask] = useState(undefined)
  let [groupedTask, setGroupedTask] = useState([])

  const dispatch = useDispatch()
  const history = useHistory()
  const tableRef = useRef()
  const { Panel } = Collapse
  const tableStyles = {
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px'
  }

  const fetchAssignedUsers = async () => {
    const { data } = await dispatch(assignedUserList())
    setAssignedUsers(data)
  }

  const handleDeadlineFilterChange = (date) => {
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null
    events._updateFilter({ deadline: formattedDate })
    setDeadlineFilter(formattedDate)

  }
  const getQueryByUser = () => {
    return new Promise(async (resolve) => {
     const params = {
       userType :  ['branchUser', 'branchManager', 'marketingManager'],
       results: 200,
       count: 200,
     }

      let resp = await dispatch(listAllUsers({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email']
      }));

      setQueryByUser(resp.data)
      console.log(resp.data, "resp.data")
      resolve(resp);
    });
  }


  useEffect(() => {
    if (screenRecording) {
      liveStreamEvents.startLiveVideo()
    } else {
      liveStreamEvents.stopLiveVideo()
    }
  }, [screenRecording])

  useEffect(() => {
    if (!videoOn) {
      if (liveVideoRef.current) {
        liveVideoRef.current.srcObject = null
      }
    } else {
      if (liveVideoRef.current) {
        liveVideoRef.current.srcObject = videoStream
      }
    }
  }, [videoOn, videoStream])

  useEffect(() => {
    const toggleSystemAudio = () => {
      if (audioStream && audioStream.getAudioTracks) {
        audioStream.getAudioTracks().forEach(track => {
          track.enabled = audioOn
        })
      }
    }
    toggleSystemAudio()
  }, [audioOn, audioStream])

  useEffect(() => {
    screenEvents.stopScreenRecordingFxn()
  }, [screenChunkArr])
  useEffect(() => {
    audioEvents.setAudioRecordingFxn()
  }, [audioChunkArr])

  useEffect(() => {
    events.loadDistinctUser()
    fetchAssignedUsers()
    getQueryByUser()
  }, [])

  const handleOpenAssigneeModal = (taskId) => {
    setAssigneeStates({
      taskId: taskId,
      visible: true
    })
  }

  const handleSubmitAssigneeModal = async () => {
    try {
      const result = await dispatch(updateTask(assigneeStates.taskId, { assignee }))
      if (!result.error) {
        events.reloadGroup()
        setAssignee([])
        setAssigneeStates({
          taskId: '',
          visible: false
        })
      } else {
      }
    } catch (error) {
      console.error('Error updating task assignee:', error)
    }
  }
  const handleSubmitQueryBy = async (queryBy) => {
    console.log(queryBy, "queryby00000s")
    try {
      const result = await dispatch(updateTask(queryByState.taskId, {queryBy}))
      if (!result.error) {
        events.reloadGroup()
        setSelectedUser([])
        setQueryByState({
          taskId: '',
          visible: false
        })
      } else {
      }
    } catch (error) {
      console.error('Error updating task user:', error)
    }
  }
  const liveStreamEvents = {
    startLiveVideo: async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true })
        setVideoStream(stream)
        const videoElement = liveVideoRef.current
        if (videoElement) {
          videoElement.srcObject = stream
        }
      } catch (error) {
        console.error('Error accessing video media devices:', error)
      }
    },
    stopLiveVideo: () => {
      const videoElement = liveVideoRef.current
      if (videoElement) {
        videoElement.srcObject = null
      }
      if (videoStream) {
        videoStream.getTracks().forEach(track => track.stop())
        setVideoStream(null)
      }
    }
  }
  const screenEvents = {
    startScreenRecording: async () => {
      let videoTrack, audioTrack, stream
      try {
        let audioConstraint = audioOn ? true : false
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: { exact: true, suppressLocalAudioPlayback: true },
          selfBrowserSurface: 'include'
        })
        setScreenStream(screenStream)
        setScreenRecording(true)
        let chunks = []
        let startTime = Date.now()

        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        [videoTrack] = screenStream.getVideoTracks();
        [audioTrack] = audioStream.getAudioTracks()
        stream = new MediaStream([videoTrack, audioTrack]) // do stuff
        const recorder = new MediaRecorder(stream)
        screenRecorder.current = recorder
        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data)
          }
        }

        recorder.onstop = () => {

          /*  let endTime = Date.now()
            let duration = (endTime - startTime) / 1000
            if (duration >= 120) {
              console.log('Screen recording time limit (2 minutes) reached.')
              return
            }*/
          setScreenChunkArr((prevChunk) => {
            return [
              ...chunks
            ]
          })
        }


        /* videoTrack[0].addEventListener('ended', ()=>{

         })*/

        recorder.start()
        setVideoOn(true)
      } catch (error) {
        console.error('Error accessing screen media devices:', error)
      }
    },
    stopScreenRecording: () => {
      if (screenRecorder.current && screenRecording) {
        screenRecorder.current.stop()
      }
    },
    stopScreenRecordingFxn: () => {
      if (screenRecording && screenChunkArr && screenChunkArr.length) {
        setScreenRecording(false)
        const screenBlob = new Blob(screenChunkArr, { type: 'video/mp4' })
        setScreenRecordingFile(screenBlob)
      }
    },
    downloadScreenRecording: () => {
      if (screenChunkArr && screenChunkArr.length > 0) {
        const blob = new Blob(screenChunkArr, { type: screenChunkArr[0].type })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'screen_recording.mp4'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
      }
    },
    playScreenRecording: () => {
      if (screenChunkArr && screenChunkArr.length > 0) {
        const blob = new Blob(screenChunkArr, { type: 'video/mp4' })
        const dataUrl = URL.createObjectURL(blob)
        setScreenRecordingDataUrl(dataUrl)
        screenEvents.toggleScreenRecordingModal()
      }
    },
    deleteScreenRecording: () => {
      setScreenChunkArr([])
      setScreenRecordingFile(null)
      setScreenRecordingDataUrl(null)
      setShowScreenRecordingModal(false)
    },
    toggleVideo: () => {
      setVideoOn(!videoOn)
    },
    toggleScreenRecordingModal: () => {
      setShowScreenRecordingModal(!showScreenRecordingModal)
    }
  }
  const tableEvents = {
    openScreenModal: (url) => {
      setScreenPlayState({
        visible: true,
        screenUrl: url
      })
    },
    closeScreenModal: () => {
      setScreenPlayState({
        visible: false,
        screenUrl: ''
      })
    },
    openAudioModal: (url) => {
      setAudioPlayState({
        audioUrl: url,
        visible: true
      })
    },
    closeAudioModal: () => {
      setAudioPlayState({
        audioUrl: '',
        visible: false
      })
    }
  }

  const audioEvents = {
    startAudioRecording: async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        setAudioStream(stream)
        const recorder = new MediaRecorder(stream)
        audioRecorder.current = recorder
        let chunk = ''
        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunk = event.data
          }
        }

        recorder.onstop = () => {
          setAudioChunkArr((prevChunk) => {
            return [chunk]
          })
        }

        recorder.start()
        setAudioRecording(true)
      } catch (error) {
        console.error('Error accessing audio media devices:', error)
      }
    },
    stopAudioRecording: () => {
      if (audioRecorder.current && audioRecording) {
        audioRecorder.current.stop()
      }
    },
    deleteAudioRecording: () => {
      setAudioChunkArr([])
      setAudioRecordingDataUrl(null)
      setAudioFile(null)
      setShowAudioRecordingModal(false)
    },
    toggleAudio: () => {
      setAudioOn(!audioOn)
    },
    toggleAudioRecordingModal: () => {
      setShowAudioRecordingModal(!showAudioRecordingModal)
    },
    setAudioRecordingFxn: () => {
      if (audioRecording && audioChunkArr && audioChunkArr.length > 0) {
        const audioBlob = new Blob(audioChunkArr, { type: 'audio/mp3' })
        const audioUrl = URL.createObjectURL(audioBlob)
        setAudioRecordingDataUrl(audioUrl)
        setAudioRecording(false)
        setAudioFile(audioBlob)
        audioEvents.toggleAudioRecordingModal()
      }
    },
    playAudioRecording: () => {
      if (audioRecordingDataUrl) {
        audioEvents.toggleAudioRecordingModal()
      }
    }
  }

  const priorityOptions = [
    {
      key: 'Urgent',
      label: 'Urgent',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#b13a41', fontSize: '16px' }} />
    },
    {
      key: 'High',
      label: 'High',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#eca500', fontSize: '16px' }} />
    },
    {
      key: 'Normal',
      label: 'Normal',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#4466ff', fontSize: '16px' }} />
    },
    {
      key: 'Low',
      label: 'Low',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: 'gray', fontSize: '16px' }} />
    },
    { key: 'Clear', label: 'Clear', icon: <Icon type={'stop'} style={{ color: 'gray', fontSize: '16px' }} /> }
  ]

  const handleCreateTask = async () => {
    if (!newTask) {
      notification.warning({ message: 'Enter task.' })
      return
    }
    const formData = new FormData()
    const newTaskObject = {
      text: newTask,
      status: 'pending',
      deadline,
      priority: priority,
      assignee: assignee,
      queryBy : selectedUser,
      // screenRecord: audioFile,
      // audioRecord: screenRecordingFile
    }
    formData.append('obj', JSON.stringify(newTaskObject))
    if (audioFile) {
      formData.append('audioRecord', audioFile)
    }
    if (screenRecordingFile) {
      formData.append('screenRecord', screenRecordingFile)

    }

    try {
      const result = await dispatch(addTask(formData))

      if (!result.error) {
        setTaskList((prevData) => {
          return [
            result.data,
            ...prevData
          ]
        })
        setNewTask('')
        setDeadline(null)
        setAssignee([])
        setScreenRecordingDataUrl(null)
        setAudioRecordingDataUrl(null)
        setScreenChunkArr([])
        setAudioChunkArr([])
        setScreenRecordingFile(null)
        setAudioFile(null)
        setSelectedUser(null)
        events.reloadGroup()
      } else {
        // console.error(result.message);
      }
    } catch (error) {
      console.error('Error adding task:', error)
    }
  }
  useEffect(() => {
    if (showCompleteTasks !== undefined ||
      showGroupTask !== undefined ||
      filters.addedBy !== undefined ||
      filters.addedByUserId !== undefined ||
      priorityFilter !== undefined ||
      statusFilter !== undefined ||
      sortFilter !== undefined) {
      events.reloadGroup()
    }
  }, [showCompleteTasks, showGroupTask, filters, priorityFilter, statusFilter, deadlineFilter, sortFilter])


  const handleShowCompleteTasks = () => {
    setShowCompleteTasks(prevShowCompleteTasks => !prevShowCompleteTasks)
  }

  const deadlineEvents = {
    chooseDate: (date) => {
      setDeadLineState((prevState) => {
        return {
          ...prevState,
          ...date
        }
      })
    },
    showDeadlineModal: (data) => {
      setDeadLineState({
        visible: true,
        deadline: data.deadline,
        taskId: data._id
      })
    },
    hideDeadlineModal: () => {
      setDeadLineState({
        visible: false,
        deadline: '',
        taskId: ''
      })
    },
    handleDeadlineChange: async (data) => {
      if (data.taskId) {
        const result = await dispatch(updateTask(data.taskId, data))
        if (!result.error) {
          events.reloadGroup()
          deadlineEvents.hideDeadlineModal()
        } else {
          console.error(result.message)
        }
      }
    }
  }
  const updateAssigneeForTask = (taskId, assignee) => {
    const updatedTaskList = taskList.map((task) =>
      task._id === taskId ? { ...task, assignee } : task
    )
    setTaskList(updatedTaskList)
  }


  const getAssigneeInitials = (assignee) => {
    const initials = assignee
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase()
    return initials
  }

  const events = {
    reloadGroup: () => {
      if (conRef.current && conRef.current.props && conRef.current.props.reload) {
        conRef.current.props.reload()
      }
      if (tableRef.current && tableRef.current.reload) {
        tableRef.current.reload()
      }
    },
    handleDeleteTask: async (taskId) => {
      const response = await dispatch(deleteTask(taskId))
      if (!response.error) {
        events.reloadGroup()
      }
    },
    handleSaveEdit: (taskId, newText) => {
      const updatedTaskList = taskList.map((task) =>
        task.id === taskId ? { ...task, text: newText, isEditing: false } : task
      )
      setTaskList(updatedTaskList)
    },
    handleOpenPriorityModal: (taskId = '') => {
      setPriorityState({
        taskId: taskId,
        visible: true
      })
    },
    handleClosePriorityModal: () => {
      setPriorityState({
        taskId: '',
        visible: false
      })
    },
    handleOpenModal: (taskId = '') => {
      setAssigneeState({
        taskId: taskId,
        visible: true
      })
    },
    handleCloseModal: () => {
      setAssigneeState({
        taskId: '',
        visible: false
      })
    },
    getPriorityColor: (priority) => {
      const priorityOption = priorityOptions.find((option) => option.key === priority)
      return priorityOption ? priorityOption.icon.props.style.color : ''
    },
    handleShowFutureTasks: () => {
      history.push('/future-tasks')
    },
    handleSelectPriority: async (priority) => {
      events.handleClosePriorityModal()
      if (priorityState.taskId) {
        try {
          const result = await dispatch(updateTask(priorityState.taskId, { priority }))
          if (!result.error) {
            events.reloadGroup()
          } else {
          }
        } catch (error) {
          console.error('Error updating task priority:', error)
        }
      } else {
        setPriority(priority)
      }
    },
    handleStatusChange: async (taskId, status) => {
      const result = await dispatch(updateTask(taskId, { status }))
      if (!result.error) {
        events.reloadGroup()
      }
    },

    apiRequest: async (params) => {
      return new Promise(async (resolve) => {
        params.sortField = 'date'
        if(sortFilter == 'desc') {
          params.sortOrder = 'descend'
        } else {
          params.sortOrder = 'ascend'
        }
        if (!showCompleteTasks) {
          params['customQuery'] = { status: { $ne: 'complete' } }
        }
        if (priorityFilter) {
          params['priority'] = priorityFilter
        }
        if (statusFilter) {
          params['status'] = statusFilter
        }
        if (deadlineFilter) {
          params['deadline'] = deadlineFilter
        }

        params = { ...params, ...filters }
        const { data } = await dispatch(taskLists(params))
        console.log(data, "datat")
        const sortedData =  data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        if (sortFilter === 'asc') {
          data.data.sort((a, b) => new Date(a.date) - new Date(b.date));

        } else if (sortFilter === 'desc') {
          data.data.sort((a, b) => new Date(b.date) - new Date(a.date));

        }
        if (Array.isArray(data) && deadlineFilter) {
          const filteredData = data.filter(task => {
            const taskDeadlineDate = new Date(task.deadline).toISOString().split('T')[0]
            const deadlineFilterDate = new Date(deadlineFilter).toISOString().split('T')[0]
            return taskDeadlineDate === deadlineFilterDate
          })
          resolve({ data: filteredData })
        } else {
          resolve({data: sortedData})
        }

      })
    },
    apiRequestGrouped: async (params = {}) => {
      if (!showCompleteTasks) {
        params['customQuery'] = { status: { $ne: 'complete' } }
      }
      params = { ...params, ...filters }
      const { data } = await dispatch(getTaskGrouped(params))
      setGroupedTask(data.data)
    },
    showCommentDrawer: (taskId) => {
      setCommentState({
        taskId: taskId,
        visible: true
      })
    },
    hideCommentDrawer: () => {
      setCommentState({
        taskId: '',
        visible: false
      })
    },
    _updateFilter: (data) => {
      setFilters((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    loadDistinctUser: async () => {
      let resp = await taskUsersFxn()
      let newData = [
        { name: 'All Task', _id: null },
        ...resp
      ]
      setUserList(newData)
    }
  }
  console.log(userList, "user-list")
  const taskColumns = [
    {
      key: '_id',
      title: '#',
      dataIndex: '_id',
      render: (item, record, index) => {
        return (
          <>
            {pageState.count * (pageState.page - 1) + (index + 1)}
          </>
        )
      }
    },
    {
      title: 'Task',
      dataIndex: 'text',
      key: 'text',
      render: (text, record) => {
        let background = 'transparent'
        if (record.status === 'inprogress') {
          background = 'conic-gradient(red 0 75%, transparent 25%)'
        }
        if (record.status === 'in-review') {
          background = '#4466ff'
        }
        if (record.status === 'complete') {
          background = 'green'
        }
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '100%' }}>

            <div
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                border: record.status === 'pending' ? '1px solid black' : 'none',
                background: background,
                marginRight: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                flexShrink: 0
              }}>
              {record.status === 'complete' && <Icon type='check' style={{ color: 'white' }} />}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                maxWidth: '100%',
                flex: 1
              }}>
              <div style={{ width: '100%' }}>
                <RenderTask text={text} data={record} refreshTable={events.reloadGroup} />
              </div>
            </div>
          </div>
        )
      }
    },
    {
      title: 'Ticket No',
      dataIndex: 'ticketNo',
      key: 'ticketNo',
      width: 100
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status, record) => {
        return (
          <>
            {taskUpdateRights(currentUser) ? <>
              <Select defaultValue={status} className={'small-select'}
                      onChange={(value) => events.handleStatusChange(record._id, value)}>
                <Option value='pending'>Pending</Option>
                <Option value='inprogress'>In Progress</Option>
                <Option value='in-review'>In Review</Option>
                <Option value='complete'>Complete</Option>
              </Select>
            </> : <>
              {status == 'pending' ? 'Pending' : ''}
              {status == 'inprogress' ? 'In Progress' : ''}
              {status == 'in-review' ? 'In Review' : ''}
              {status == 'complete' ? 'Complete' : ''}
            </>}
          </>
        )
      }
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      width: 90,
      render: (taskPriority, record) => {
        const priorityColor = events.getPriorityColor(taskPriority)
        return (
          <Button type='link' onClick={() => {
            if (taskUpdateRights(currentUser)) {
              events.handleOpenPriorityModal(record._id)
            }
          }}>
            <Icon type={'flag'} theme={'filled'}
                  style={{ color: priorityColor, fontSize: '14px' }} />
            {taskPriority && <span style={{ marginLeft: '8px', fontSize: '13px' }}>{taskPriority}</span>}
          </Button>
        )
      }
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignee',
      width: 130,
      render: (assignees, record) => (
        <div onClick={() => handleOpenAssigneeModal(record._id)} className={'linkAA'}>
          {assignees && assignees.length > 0 ? (
            assignees.map((assignee) => (
              <div key={assignee} className={'aic'}>
                <Icon type={'user'} />&nbsp; {assignee}
              </div>
            ))
          ) : (
            <div onClick={() => handleOpenAssigneeModal(record._id)} className={'aic'}>
              <Icon type={'user'} />&nbsp; Assignee
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 90,
      render: (deadline, record) => (
        <div style={{ width: 120 }} onClick={() => {
          if (taskUpdateRights(currentUser)) {
            deadlineEvents.showDeadlineModal(record)
          }
        }}>
          <Button type='link' className={'font14'} >
            <Icon type='calendar' style={{ fontSize: '13px' }} />
            {deadline ? displayDate(deadline) : null}
          </Button>
        </div>
      )
    },
    ...(currentUser.userType === 'admin' ? [{
      title: 'Query by',
      dataIndex: 'queryBy',
      key: 'queryBy',
      width: 120,
      render: (queryBy, record) => (
          <div className={'aic'}>
            <Icon type={'user'} /> {queryBy}
          </div>
      )
    }] : []),
    {
      title: 'Date/Added by',
      dataIndex: 'date',
      key: 'date',
      width: 130,
      render: (date, record) => {
        let { addedByUserId } = record
        return (
          <>
            {moment(date).format('DD MMM YY')}<br />
            {addedByUserId && addedByUserId.name ? <div className={'font11 textCap'}>({addedByUserId.name})</div> : ''}
          </>
        )
      }
    },
    {
      title: 'Audio',
      dataIndex: 'audioRecord',
      key: 'audioRecord',
      width: 50,
      render: (audioRecord) => (
        <div>
          {audioRecord && (
            <Icon
              type='play-circle'
              style={{ color: 'blue', cursor: 'pointer', fontSize: '14px' }}
              onClick={() => tableEvents.openAudioModal(audioRecord.url)}
            />
          )}
        </div>
      )
    },
    {
      title: 'Screen',
      dataIndex: 'screenRecord',
      key: 'screenRecord',
      width: 50,
      render: (screenRecord) => (
        <div>
          {screenRecord && (
            <Icon
              type='play-circle'
              style={{ color: 'green', cursor: 'pointer', fontSize: '14px' }}
              onClick={() => tableEvents.openScreenModal(screenRecord.url)}
            />
          )}
        </div>
      )
    },
    {
      key: 'actions',
      width: 30,
      render: (item, record) => (
        <div className={'actionBtnGroup'}>
          <Tooltip title={'View Comment'}>
            <a className={'btn'} onClick={() => {
              events.showCommentDrawer(record._id)
            }}>
              <img src='/dist/img/view.svg' alt='' />
            </a>
          </Tooltip>

          {taskDeleteRights(currentUser) ?
            <>
              <Popconfirm
                title='Are you sure you want to delete this task?'
                onConfirm={() => events.handleDeleteTask(record._id)}
                okText='Yes'
                cancelText='No'
                placement='topRight'>
                <Button type={'link'}>
                  <img src='dist/img/cross.svg' alt='' className='crossBtn' />
                </Button>
              </Popconfirm>
            </> : null}
        </div>
      )
    }
  ]
  return (
    <>
      {screenRecording && (
        <div className='mini-screen-container'>
          <div className='mini-screen'>
            {screenRecording ? (
              <video
                ref={liveVideoRef}
                controls
                autoPlay
                className='live-video'
                srcObject={videoOn ? videoStream : null}
              />
            ) : null}
            {screenRecording && (
              <div className='stop-icon'>
                <Icon type='close' onClick={screenEvents.stopScreenRecording} style={{
                  color: 'white',
                  fontSize: '18px',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '10px',
                  right: '10px'
                }} />
                {audioOn ? (
                  <div className='audio-icon' onClick={audioEvents.toggleAudio}>
                    <Icon type={'audio'} style={{
                      color: 'white',
                      fontSize: '18px',
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '10px',
                      right: '40px'
                    }} />
                  </div>
                ) : (
                  <Icon type={'audio'} style={{
                    color: 'red',
                    fontSize: '18px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '10px',
                    right: '40px'
                  }} onClick={audioEvents.toggleAudio} />
                )}
                {videoOn ? (
                  <div className='video-icon' onClick={screenEvents.toggleVideo}>
                    <Icon type={'video-camera'} style={{
                      color: 'white',
                      fontSize: '18px',
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '10px',
                      right: '70px'
                    }} />
                  </div>
                ) : (
                  <Icon type={'video-camera'} style={{
                    color: 'red',
                    fontSize: '18px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '10px',
                    right: '70px'
                  }} onClick={screenEvents.toggleVideo} />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <div className='row'>

        <div className='col-lg-12'>
          <div className='form-box mt-4'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Task Manager</h5>
            </div>
            <div className='card unizportal'>
              <div className={'row'}>
                <div className={'col-md-10'}>
                  <TextArea
                    placeholder='Enter new task'
                    value={newTask}
                    style={{ minHeight: 170 }}
                    onChange={(e) => setNewTask(e.target.value)}
                    className='input-hover'
                  />

                </div>

                <div className={'col-md-2'}>
                  <div className={'row'}>
                    <div className={'col-md-12'}>
                      <DatePicker
                        className='date-picker input-hover dateH35'
                        value={deadline ? moment(deadline) : null}
                        placeholder='Deadline'
                        onChange={(date) => setDeadline(date)}
                      />
                    </div>

                    <div className={'col-md-12 mt10'}>
                      <Input
                        placeholder='Priority'
                        value={priority}
                        disabled={true}
                        onChange={(e) => setPriority(e.target.value)}
                        suffix={<Icon type={'flag'} style={{ color: '#eca500', fontSize: '16px' }}
                                      onClick={() => {
                                        events.handleOpenPriorityModal()
                                      }} />}
                      />

                    </div>
                    <div className={'col-md-12 mt5'}>
                      <Input
                        placeholder='Assignee'
                        value={assignee}
                        disabled={true}
                        onChange={(e) => setAssignee(e.target.value)}
                        suffix={<Icon type={'usergroup-add'} style={{ color: '#0f52ba', fontSize: '16px' }}
                                      onClick={() => {
                                        events.handleOpenModal()
                                      }} />}
                      />

                    </div>
                    {currentUser.userType == 'admin' ?
                        <div className={'col-md-12 mt5'}>
                          <Select
                              placeholder='Select User'
                              value={selectedUser}
                              onChange={(value) => setSelectedUser(value)}
                              // suffixIcon={<Icon type={'user'} style={{ color: '#800000', fontSize: '14px'}} />}
                              style={{ width: '100%' }}
                          >
                            {queryByUser.map((user) => (
                                <Option key={user._id} value={user.name}>
                                  {user.name}
                                </Option>
                            ))}
                          </Select>
                        </div>
                    : null }


                    {audioFile && audioFile.type ? <div className={'col-md-12 mt5'}>
                      <Input
                        accept='audio/*'
                        value={audioFile ? audioFile.type : ''}
                        disabled={true}
                        suffix={<Icon type={'play-circle'} onClick={audioEvents.playAudioRecording}
                                      style={{ color: 'blue', cursor: 'pointer' }} />}
                      />
                    </div> : null}
                    {screenRecordingFile && screenRecordingFile.type ?
                      <div className={'col-md-12 mt5'}>
                        <Input accept='video/*' value={screenRecordingFile ? screenRecordingFile.type : ''}
                          // disabled={true}
                               suffix={<Icon type={'play-circle'} onClick={screenEvents.playScreenRecording}
                                             style={{ color: 'green', cursor: 'pointer' }} />}
                        />
                      </div> : null}
                  </div>

                </div>

              </div>
              <div className={'row mt10'}>
                <div className={'col-md-6 '}>
                  <div className='recorder-buttons'>
                    <>
                      {screenRecording ? (
                        <Button className='btn btn-danger' onClick={screenEvents.stopScreenRecording}
                                style={{ marginRight: '10px', fontSize: '12px' }}>Stop Screen Recording
                          <Icon type={'stop'} onClick={screenEvents.stopScreenRecording} className='btn-record'
                                disabled={screenRecording} />
                        </Button>
                      ) : (
                        <>
                          {!audioRecording && (
                            <Button className='btn btn-primary me3' onClick={screenEvents.startScreenRecording}
                                    style={{ marginRight: '10px', fontSize: '12px' }}>Start Screen Recording
                              <Icon type={'video-camera'} onClick={screenEvents.startScreenRecording}
                                    className='btn-record'
                                    disabled={audioRecording} />
                            </Button>
                          )}
                        </>
                      )}
                    </>

                    <>
                      {audioRecording ? (
                        <>
                          <Button className='btn btn-danger me3' onClick={audioEvents.stopAudioRecording}
                                  style={{ marginRight: '10px', fontSize: '12px' }}>Stop Audio
                            <Icon
                              type={'stop'}
                              onClick={audioEvents.stopAudioRecording}
                              className='btn-record'
                            />
                          </Button>

                          <Button className='audio-recording-icon' style={{
                            padding: '0',
                            border: 'none',
                            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
                            background: 'white',
                            textDecoration: 'underline'
                          }}>

                            <Icon type='audio' theme='twoTone' style={{ fontSize: '16px', color: 'darkBlue' }} />
                          </Button>

                        </>
                      ) : (
                        <>
                          {!screenRecording && (

                            <Button className='btn btn-primary me3' onClick={audioEvents.startAudioRecording}
                                    style={{ marginRight: '10px', fontSize: '12px' }}>Start Audio
                              <Icon
                                type={'audio'}
                                onClick={audioEvents.startAudioRecording}
                                className='btn-record'
                              />
                            </Button>
                          )}

                        </>
                      )}
                    </>


                  </div>
                </div>
                <div className={'col-md-6 alignRight'}>
                  <Button
                    type='primary'
                    style={{ backgroundColor: '#0f52ba', border: 'none', marginRight: 5, borderRadius: 5 }}
                    onClick={handleCreateTask}
                    className='primary-button-hover'>
                    Add Task
                  </Button>
                  <Button className='cancel-button-hover'>
                    Cancel
                  </Button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='col-lg-12'>
          <div className='form-box'>
            <div className='card unizportal'>

              <div className={'row mt5'}>
                {taskUpdateRights(currentUser) ? <div className={'col-md-2'}>
                  <>
                    <Select placeholder={'Filter by User'} allowClear={true}
                            value={filters.addedByUserId} className={'md-select'}
                            onChange={(value) => {
                              events._updateFilter({ addedByUserId: value })
                            }}>
                      {userList.map((item) => {
                        return (
                          <Option value={item._id} key={item.name}>{item.name}</Option>
                        )
                      })}
                    </Select>

                  </>
                  {/* {taskUpdateRights(currentUser) ? <div className={'select_btn_group'}>
                    <a onClick={() => events._updateFilter({ addedBy: 'AllTask' })}
                       className={`default_btn ${(filters.addedBy == 'AllTask' || filters.addedBy == undefined) ? 'selected' : ''}`}>
                      {(filters.addedBy == 'AllTask' || filters.addedBy == undefined) ?
                        <Icon type='check-circle' /> : null}
                      All Task
                    </a>
                    <a onClick={() => events._updateFilter({ addedBy: 'AdminTask' })}
                       className={`default_btn ${filters.addedBy == 'AdminTask' ? 'selected' : ''}`}>
                      {(filters.addedBy == 'AdminTask') ?
                        <Icon type='check-circle' /> : null}
                      Admin Task
                    </a>
                    <a onClick={() => events._updateFilter({ addedBy: 'OtherTask' })}
                       className={`default_btn ${filters.addedBy == 'OtherTask' ? 'selected' : ''}`}>
                      {(filters.addedBy == 'OtherTask') ?
                        <Icon type='check-circle' /> : null}
                      Other Task
                    </a>
                  </div> : null}*/}


                  {/* {taskUpdateRights(currentUser) ? <div className={'row'}>
                    <div className={'col-md-4'}>
                      <Select
                        className={'shadow-select'}
                        value={filters.addedBy}
                        placeholder={'Search by Added'}
                        onChange={(value) => {
                          events._updateFilter({ addedBy: value })
                        }}>
                        <Option value='All Task'>All Task</Option>
                        <Option value='Task By Other'>Task By Other</Option>
                        <Option value='Task By Admin'>Task By Admin</Option>
                      </Select>

                    </div>
                  </div> : null}*/}

                </div> : null}


                <div className={'col-md-2'}>
                  <Select placeholder={'Filter by Status'}
                          className={'md-select'}
                          allowClear={true}
                          value={statusFilter}
                          onChange={(value) => {
                            if (value) {
                              setStatusFilter(value)
                            } else {
                              setStatusFilter(null)
                            }
                          }}>
                    <Option value={'pending'}>Pending</Option>
                    <Option value={'inprogress'}>In Progress</Option>
                    <Option value={'in-review'}>In Review</Option>
                    <Option value={'complete'}>Complete</Option>
                  </Select>

                </div>
                <div className={'col-md-2'}>
                  <Select
                    placeholder={'Filter by Priority'}
                    className={'md-select'}
                    value={priorityFilter}
                    allowClear={true}
                    onChange={(value) => {
                      if (value) {
                        setPriorityFilter(value)
                      } else {
                        setPriorityFilter(null)
                      }
                    }}>
                    <Option value={'Urgent'}>Urgent</Option>
                    <Option value={'High'}>High</Option>
                    <Option value={'Normal'}>Normal</Option>
                    <Option value={'Low'}>Low</Option>
                  </Select>

                </div>
                <div className={'col-md-2'}>
                  <Select
                      placeholder={'Sort By'}
                      className={'md-select'}
                      allowClear={true}
                      value={sortFilter}
                      onChange={(value) => {
                        setSortFilter(value);
                      }}
                  >
                    <Option value={'asc'}>Old to New</Option>
                    <Option value={'desc'}>New to Old</Option>
                  </Select>
                </div>
                {/* <div className={'col-md-2'}>
                  <div className='md-select filter-select'>
                    <DatePicker
                      value={deadlineFilter ? moment(deadlineFilter) : null}
                      onChange={(date) => handleDeadlineFilterChange(date)}
                      format='YYYY-MM-DD'
                      placeholder='Filter by deadline'
                      style={{ width: '100%' }}
                    />
                  </div>

                </div>*/}

              </div>

              <div className={'row'}>
                <div className={'col-md-12'}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: 10
                  }}>
                    <Button
                      className='group-by-button-hover'
                      onClick={() => {
                        setShowGroupTask(!showGroupTask)
                      }}>
                      {showGroupTask ? 'Un - Group By' : 'Group By'}
                    </Button>

                    <Button
                      className='show-complete-tasks-button-hover'
                      onClick={handleShowCompleteTasks}>
                      {showCompleteTasks ? 'Hide Complete Tasks' : 'Show Complete Tasks'}
                    </Button>
                    <Button className='show-complete-tasks-button-hover'
                            onClick={events.handleShowFutureTasks}>
                      Future Task
                    </Button>


                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-md-12'}>
                  {showGroupTask ? (
                    <div>
                      <Collapse ref={conRef} reload={() => {
                        events.apiRequestGrouped()
                      }}>
                        {groupedTask && groupedTask.length ? groupedTask.map((item) => {
                          const formattedDate = moment(item.date).format('Do MMM YYYY')
                          return (
                            <Panel key={item._id} header={formattedDate}>
                              <Table
                                // style={tableStyles}
                                columns={taskColumns}
                                dataSource={item.tasks}
                                pagination={false}
                                size={'small'} bordered={true} />
                            </Panel>
                          )
                        }) : null}
                      </Collapse>
                    </div>
                  ) : (
                    <TableComp columns={taskColumns} style={tableStyles}
                               apiRequest={events.apiRequest}
                               pagination={DefaultTablePagination()}
                               ref={tableRef}
                               rowKey='_id' />
                  )}
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>


      {priorityState.visible ? <Modal
        title='Priority'
        visible={priorityState.visible}
        onCancel={events.handleClosePriorityModal}
        footer={null}
        width={200}>
        <List dataSource={priorityOptions}
              renderItem={(item, index) => (
                <List.Item
                  onClick={() => events.handleSelectPriority(item.key)}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0',
                    borderBottom: index === 3 ? '1px solid #d9d9d9' : 'none',
                    backgroundColor: priority === item.key ? '#e6f7ff' : 'inherit'
                  }}>
                  {item.icon && <span style={{ marginRight: '8px' }}>{item.icon}</span>}
                  <div style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    color: '#000'
                  }}>{item.label}</div>
                </List.Item>
              )}
        />

      </Modal> : null}

      {deadLineState.visible ?
        <Modal
          title='Select Deadline'
          visible={deadLineState.visible}
          onCancel={deadlineEvents.hideDeadlineModal}
          onOk={() => deadlineEvents.handleDeadlineChange(deadLineState)}>
          <DatePicker
            value={deadLineState.deadline ? moment(deadLineState.deadline) : null}
            onChange={(date) => deadlineEvents.chooseDate({ deadline: date })}
          />
        </Modal> : null}
      {showScreenRecordingModal && screenRecordingDataUrl ? <Modal
        title='Screen Recording'
        visible={showScreenRecordingModal}
        onCancel={screenEvents.toggleScreenRecordingModal}
        width={'60%'}
        centered
        style={{ margin: '50px' }}
        footer={null}>
        <video
          src={screenRecordingDataUrl}
          controls
          style={{ width: '100%', maxHeight: '80vh' }} />
        <div style={{ marginTop: '10px' }}>
          <Icon type={'delete'} onClick={screenEvents.deleteScreenRecording}
                style={{ marginRight: '10px', textAlign: 'end' }} />
          <Icon type={'download'} onClick={screenEvents.downloadScreenRecording}
                style={{ marginRight: '10px', textAlign: 'end' }} />
        </div>
      </Modal> : null}
      {showAudioRecordingModal && audioRecordingDataUrl ? <Modal
        title='Audio Recording'
        visible={showAudioRecordingModal}
        onCancel={audioEvents.toggleAudioRecordingModal}
        width={'50%'}
        centered
        style={{ margin: '50px' }}
        footer={null}
        className='audio-recording-modal'>
        <div>
          <audio
            src={audioRecordingDataUrl}
            controls
            style={{ width: '95%', maxHeight: '80vh' }}
          />
          {audioRecordingDataUrl && (
            <Icon type='delete' onClick={audioEvents.deleteAudioRecording}
                  className='delete-audio-btn' />
          )}
        </div>
      </Modal> : null}
      {assigneeState.visible ? (
        <Modal
          title='Add Users'
          visible={assigneeState.visible}
          onCancel={events.handleCloseModal}
          bodyStyle={{ maxHeight: '800px', overflow: 'auto' }}
          width={400}
          footer={[
            <Button key='submit' type='primary' onClick={events.handleCloseModal}>
              Submit
            </Button>
          ]}
        >
          <Select
            mode='multiple'
            style={{ width: '100%' }}
            placeholder='Select assignees'
            value={assignee}
            onChange={(selectedUsers) => setAssignee(selectedUsers)}
          >
            {assignedUsers.map((user) => (
              <Option key={user} value={user}>
                <Icon type={'user'} style={{ color: '#0f52ba' }} /> {user}
              </Option>
            ))}
          </Select>
        </Modal>
      ) : null}
      {audioPlayState.visible ? <Modal
        title='Audio Player'
        visible={audioPlayState.visible}
        onCancel={tableEvents.closeAudioModal}
        footer={null}
        width={'50%'}
        centered
        style={{ margin: '50px' }}>
        <audio controls style={{ width: '100%' }} autoPlay={true}>
          <source src={audioPlayState.audioUrl} />
          Your browser does not support the audio element.
        </audio>
      </Modal> : null}
      {screenPlayState.visible ? <Modal
        title='Screen Recording Player'
        visible={screenPlayState.visible}
        onCancel={tableEvents.closeScreenModal}
        width={'60%'}
        centered
        style={{ margin: '50px' }}
        footer={null}>
        <video controls style={{ width: '100%' }} autoPlay={true}>
          <source src={screenPlayState.screenUrl} />
          Your browser does not support the video element.
        </video>
      </Modal> : null}
      {assigneeStates.visible ? (
        <Modal
          title='Assignee'
          visible={assigneeStates.visible}
          onCancel={() => setAssigneeStates({ taskId: '', visible: false })}
          onOk={handleSubmitAssigneeModal}
          okText='Submit'
        >
          <Select
            mode='multiple'
            style={{ width: '100%' }}
            placeholder='Select assignees'
            value={assignee}
            onChange={(selectedUsers) => setAssignee(selectedUsers)}
          >

            {assignedUsers.map((user) => (
              <Option key={user} value={user}>
                <Icon type={'user'} style={{ color: '#0f52ba' }} /> {user}
              </Option>
            ))}
          </Select>
        </Modal>
      ) : null}
      {commentState.visible ?
        <CommentDrawer
          {...commentState}
          onClose={events.hideCommentDrawer}
          onSubmit={() => {
            events.hideCommentDrawer()
            events.reloadGroup()
          }}
          currentUser={currentUser} /> : ''}
    </>
  )

}


const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
})
export default connect(
  mapStateToProps,
  null
)(TaskManager)
