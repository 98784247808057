import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    departmentObj,
    oxfordUniversity, ukUniversityListForMarketing
} from "../../../components/_utils/appUtils";
import {getUrlPushWrapper} from "../../../routes";
import {LazyLoadImage} from "react-lazy-load-image-component";
import _ from "lodash"
import {ukUniversityRecordsCountFxn} from "../../reporting/actions";
import {Icon} from "antd";
import {getUserWithoutLoader} from "../../users/actions/user";
import OxfordUniversity from "../../evaluateProfile/oxford/oxfordUniversity";

const ButtonLink = (props) => {
    let {searchData, item, count, userCountryName, userData, applyForCourse, imgCls = ''} = props;
    const btnA = (
        <li className={'canadaDirectUniCount'} key={item.id}>
            <div className={'innerBox'}>
                <div className={'uniCountBox'} onClick={() => searchData(item)}>
                    <LazyLoadImage src={item.image} className={imgCls}/>
                    <h5>{count}</h5>
                </div>
                <div className={'btnBox'}>
                    <a onClick={() => {
                        applyForCourse(item.id)
                    }}>
                        <Icon type={'plus-circle'} size={13}/> Add Student
                    </a>
                </div>
            </div>
        </li>
    )
    let branchUserCheck = () => {
        let checkUniversityExists = _.find(userData.universities, (item1) => {
            return item1.toString() == item.id
        })
        return userData.department == 'Visa Approved Department' ? btnA : checkUniversityExists ? btnA : null
    }
    return (
        userCountryName == 'UK' ? <>
            {(userData.userType == 'admin' || userData.userType == 'agent' || userData.userType == 'subAgent' || userData.department == departmentObj.marketing) ? btnA : null}
            {(userData.userType == 'branchManager' && userCountryName == 'UK') ? btnA : null}
            {userData.userType == 'branchUser' ? branchUserCheck() : null}
        </> : null
    )
}

const UkUniversityRecordCount = (props) => {
    let {user} = props
    let [countRecords, setCountRecords] = useState({})
    let [userData, setUserData] = useState({})
    let dispatch = useDispatch()

    let [applyCourseVisibleOxford, setApplyCourseVisibleOxford] = useState({
        visible: false,
        universityId: ""
    })


    useEffect(() => {
        loadDirectCount()
        loadUserCountry()
    }, [])


    const loadUserCountry = async () => {
        let resp = await dispatch(getUserWithoutLoader(user._id))
        setUserData(resp)
    }


    let loadDirectCount = async () => {
        let resp = await dispatch(ukUniversityRecordsCountFxn())
        setCountRecords(resp);
    }

    let searchData = (item) => {
        if (item.id == oxfordUniversity) {
            dispatch(
                getUrlPushWrapper('application.oxfordApplicationsList', {
                    appUniversityId: oxfordUniversity
                }))

        }
    }

    const events = {
        showOxfordApplicationDrawer: (universityId) => {
            setApplyCourseVisibleOxford({
                visible: true, universityId: universityId
            })
        },
        hideOxfordApplicationDrawer: () => {
            setApplyCourseVisibleOxford({
                visible: false, universityId: ""
            })
            loadDirectCount()
        },

        applyForCourse: (universityId) => {
            if (universityId == oxfordUniversity) {
                events.showOxfordApplicationDrawer(universityId)
            }
        }
    }

    return (
        userData && userData._id ? <>
            <div className='card-pane-right card-pane-new listing-section w-100' style={{border: 'none'}}>
                <ul className={'count'}>
                    {ukUniversityListForMarketing.map((item) => {
                        return (
                            <ButtonLink
                                {...props}
                                item={item}
                                imgCls={'sm'}
                                userData={userData}
                                count={countRecords[item.id] ? countRecords[item.id] : 0}
                                searchData={searchData}
                                applyForCourse={events.applyForCourse}
                            />
                        )
                    })}
                </ul>
            </div>


            {applyCourseVisibleOxford.visible ? (
                <OxfordUniversity
                    visible={applyCourseVisibleOxford.visible}
                    selectedUniversity={applyCourseVisibleOxford.universityId}
                    onClose={events.hideOxfordApplicationDrawer}
                    {...props}
                />
            ) : null}

        </> : null
    )

}

export default UkUniversityRecordCount;
