import React from "react"
import PendingForSubmissionAppList from "../privateCollege/pendingForSubmissionAppList"
import {RoyalArtsCollege} from "../../../components/_utils/appUtils";

const CiraPendingForSubmission = () => {
    return (
        <>
            <PendingForSubmissionAppList
                appName={'Cira College'}
                universityId={RoyalArtsCollege}
                redirectKey={'cira.ciraFilePendingForSubmissions'}/>
        </>
    )
}
export default CiraPendingForSubmission
