import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import {
  royalArtsUploadDocumentUrl
} from '../apis/royalArts'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

export const royalArtsUploadDocumentFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(royalArtsUploadDocumentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Uploaded Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error '
    })
  }
  return data
}
