import React from "react"
import PrivateCollegePendingTTApplicationList from "../privateCollege/privateCollegePendingTTApplicationList"
import {fcmtUniversity} from "../../../components/_utils/appUtils";

const FcmtPendingTTApplicationList = () => {
    return (
        <PrivateCollegePendingTTApplicationList
            appName={'FCMT College'}
            universityId={fcmtUniversity}
            redirectKey={'fcmt.fcmtUploadTT'}
        />
    )
}
export default FcmtPendingTTApplicationList
