import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination, displayDate } from '../../../components/_utils/appUtils'
import { requestedCallBackListFxn, updateCallBackStatusFxn } from '../actions'
import { Icon, Modal, notification, Tooltip } from 'antd'
import TextArea from 'antd/es/input/TextArea'

const RenderComment = props => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  let text = item.details || item.reason
  return (
    item && (
      <div>
        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {text ? text : ''}
        </div>
        {text && text.length && text.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a
                onClick={() => {
                  setHideV(false)
                }}>
                Show More
              </a>
            ) : (
              <a
                onClick={() => {
                  setHideV(true)
                }}>
                Show Less
              </a>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  )
}


const RequestACallBackList = () => {
  const tableRef = useRef()
  let [total, setTotal] = useState(0)
  let [details, setDetails] = useState('')
  let [reason, setReason] = useState('')
  let [detailsModal, setDetailsModal] = useState({
    visible: false,
    id: ''
  })

  let [cancelModal, setCancelModal] = useState({
    visible: false,
    id: ''
  })

  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await dispatch(requestedCallBackListFxn({
        ...params,
        regExFilters: ['name', 'email', 'mobile', 'timezone']
      }))
      setTotal(resp.total)
      resolve(resp)
    })
  }

  const updateCallBackStatus = async (id, status) => {
    if (status == 'cancel' && !reason) {
      notification.warning({ message: 'Enter reason' })
      return
    }
    if (status == 'done' && !details) {
      notification.warning({ message: 'Enter details' })
      return
    }
    let data = await dispatch(updateCallBackStatusFxn({ id, status, details, reason }))
    if (data) {
      setDetailsModal({ visible: false, id: '' })
      setCancelModal({ visible: false, id: '' })
      tableRef.current.reload()
    }
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 100,
      render: (item, record) => {
        let countryCode = record.countryCode.split('_').pop()
        return (
          <>
            {countryCode} {item}
          </>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 100

    },
    {
      title: 'Timezone',
      dataIndex: 'timezone',
      key: 'timezone',
      width: 100

    },
    {
      title: 'Callback Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
      render: (item) => {
        return item ? displayDate(item) : ''
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item == 'done' ? 'Done' : item == 'cancel' ? 'Cancel' : item}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Details/Reason',
      key: 'details',
      dataIndex: 'details',
      width: 150,
      render: (item, record) => {
        return <RenderComment item={record} />
      }
    },
    /*   {
         title: 'Reason',
         key: 'reason',
         dataIndex: 'reason',
         width: 300
       },*/
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 70,
      render: (text, record) => {
        return <React.Fragment>
          {
            (record.status == 'Pending') ? (
              <>
                <Tooltip title='Done'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      setDetailsModal({ visible: true, id: record._id })
                    }}
                  >
                    <Icon type={'check'} />
                  </button>
                </Tooltip>
                <Tooltip title='Cancel'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      setCancelModal({ visible: true, id: record._id })
                    }}
                  >
                    <Icon type={'cross'} />
                  </button>
                </Tooltip>
              </>
            ) : null
          }
        </React.Fragment>
      }
    }
  ]


  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Request {total || 0}</h5>
              <div className='search-box-table'>

              </div>

              <div className='sort-box-table mark-btn'>
              </div>
            </div>
            <div className='card-body table-responsive'>
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         ref={tableRef}
                         pagination={DefaultTablePagination()}
              />
            </div>
            {
              detailsModal.visible ? (
                <Modal
                  title='Enter Detail'
                  visible={detailsModal.visible}
                  onOk={() => {
                    updateCallBackStatus(detailsModal.id, 'done')
                  }}
                  onCancel={() => {
                    setDetailsModal({ ...detailsModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                    placeholder='Enter Details..'
                  />
                </Modal>
              ) : null
            }
            {
              cancelModal.visible ? (
                <Modal
                  title='Enter Reason'
                  visible={cancelModal.visible}
                  onOk={() => {
                    updateCallBackStatus(cancelModal.id, 'cancel')
                  }}
                  onCancel={() => {
                    setCancelModal({ ...cancelModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder='Enter reason for Cancel'
                  />
                </Modal>
              ) : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestACallBackList
