import {notification} from 'antd';
import {getCommissionUrl, updateCommissionUrl, addCommissionUrl} from "../api/comission";
import {getToken, customAxios as axios} from '../../../request'
import {showPageLoad, hidePageLoad} from '../../../modules/actions'

export const addCommission = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addCommissionUrl(), valData, getToken())
    dispatch(hidePageLoad())

    if (!data.error) {
        // console.log('commission added successfully')
        // notification.success({
        //     message: data.message || 'Success'
        // })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data
}

export const getCommission = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(getCommissionUrl(), config);
    dispatch(hidePageLoad())
    return data.data
}
export const updateCommission = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateCommissionUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
