import React from 'react'
import PaidApplicationListComponent from '../privateCollege/privateCollegePaidApplicationList'
import { fcmtUniversity } from '../../../components/_utils/appUtils'

const FcmtPaidApplicationList = () => {
  return (
    <PaidApplicationListComponent
      appName={'FCMT College'}
      redirectKey={'fcmt.fcmtPaidTT'}
      universityId={fcmtUniversity} />
  )
}
export default FcmtPaidApplicationList
