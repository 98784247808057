import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  DefaultTablePagination,
  displayDate,
  dateFilterRange,
  departmentObj, filterOption
} from '../../../components/_utils/appUtils'
import { updateCallBackFxn, requestCallBackListFxn } from '../actions/dashboardAction'
import { Icon, Modal, notification, Tooltip, DatePicker, Row, Col, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import moment from 'moment'
import { InputBox } from '../../../components/_utils/appUtils'
import { listAllUsers } from '../../users/actions/user'
import { CheckUserRight } from '../views/DashboardUserWise'
import _ from 'lodash'

const { RangePicker } = DatePicker
const dateFormat = 'DD/MM/YYYY'

const initFilters = {
  fromDate: '',
  toDate: '',
  assignedTo: ''
}
let { Option } = Select

const RenderComment = (props) => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  let text = item.details || item.reason
  return (
    item && (
      <div>
        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {text ? text : ''}
        </div>
        {text && text.length && text.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a
                onClick={() => {
                  setHideV(false)
                }}>
                Show More
              </a>
            ) : (
              <a
                onClick={() => {
                  setHideV(true)
                }}>
                Show Less
              </a>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  )
}

const GicRequestCallback = () => {
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableRef = useRef()
  const dispatch = useDispatch()
  const [total, setTotal] = useState(0)
  const [details, setDetails] = useState('')
  const [reason, setReason] = useState('')
  const [filters, setFilters] = useState(initFilters)
  const [marketingUserList, setMarketingUserList] = useState([])

  const [detailsModal, setDetailsModal] = useState({
    visible: false,
    id: ''
  })
  const [cancelModal, setCancelModal] = useState({
    visible: false,
    id: ''
  })

  const updateFilter = (data) => {
    setFilters((prevState) => ({
      ...prevState,
      ...data
    }))
  }
  useEffect(() => {
    // loadMarketingUsers()
  }, [])

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let filterResp = await getParamsForList()
      params = { ...params, ...filterResp, type: 'gicCallback' }
      let { fromDate, toDate } = params

      if (fromDate) {
        let date = {}
        date.$gte = moment(fromDate).startOf('day')
        date.$lte = moment(toDate).endOf('day')
        params.date = date
        delete params.fromDate
        delete params.toDate
      }

      let resp = await dispatch(requestCallBackListFxn({
        ...params,
        regExFilters: ['name', 'email', 'mobile', 'status']
      }))
      setTotal(resp.total)
      resolve(resp)
    })
  }

  const getParamsForList = () => {
    return new Promise((resolve) => {
      let obj = {}
      Object.keys(initFilters).forEach((key) => {
        if (filters[key]) {
          obj[key] = filters[key]
        }
      })

      if (obj.toDate) {
        obj.toDate = moment(obj.toDate).format('MM-DD-YYYY')
      }
      if (obj.fromDate) {
        obj.fromDate = moment(obj.fromDate).format('MM-DD-YYYY')
      }

      resolve(obj)
    })
  }

  const updateCallBackStatus = async (id, status) => {
    if (status === 'cancel' && !reason) {
      notification.warning({ message: 'Enter reason' })
      return
    }
    let data = await dispatch(updateCallBackFxn({ id, status, details, reason }))
    if (data) {
      setDetailsModal({ visible: false, id: '' })
      setCancelModal({ visible: false, id: '' })
      tableRef.current.reload()
    }
  }
  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email', 'mobile'],
      block: [true, false]
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }

  const searchFxn = () => {
    if (tableRef.current) {
      tableRef.current.reload()
    }
  }

  const clearFxn = () => {
    setFilters(initFilters)
    setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.reload()
      }
    }, 500)
  }

  const FilterComponent = () => {
    return (
      <Row gutter={12} className={'filter_box'}>
        <Col span={6}>
          <div className='filter-box'>
            <InputBox title={'Search by date'}>
              <RangePicker
                format={dateFormat}
                value={[
                  filters.fromDate ? moment(filters.fromDate, dateFormat) : null,
                  filters.toDate ? moment(filters.toDate, dateFormat) : null
                ]}
                onChange={(dates) => {
                  updateFilter({
                    fromDate: dates[0],
                    toDate: dates[1]
                  })
                }}
                ranges={dateFilterRange}
              />
            </InputBox>

          </div>
        </Col>
        {/* <CheckUserRight user={user} rightUserType={['admin']}>
          <Col md={6} sm={6} xs={8} lg={6}>
            <InputBox title={'Search by Marketing User'}>
              <div className='sort-box-table custom-sort-box-new'>
                <Select
                  name='Search By Marketing User'
                  filterOption={filterOption}
                  allowClear={true}
                  showSearch={true}
                  placeholder={'Marketing User'}
                  onChange={item => {
                    updateFilter({
                      assignedTo: item
                    })
                  }}
                  value={filters.assignedTo || undefined}>
                  {marketingUserList && marketingUserList.length
                    ? marketingUserList.map((item, key) => {
                      return (
                        <Option value={item._id} key={key}>
                          {item.name}
                        </Option>
                      )
                    })
                    : null}
                </Select>
              </div>
            </InputBox>
          </Col>
        </CheckUserRight>*/}

        <Col span={24}>
          <div className={'btn_group'}>
            <a className={'default_btn'} onClick={searchFxn}>Search</a>
            <a className={'default_btn'} onClick={clearFxn}>Clear</a>
          </div>
        </Col>
      </Row>
    )
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Name',
      dataIndex: ['userId', 'name'],
      key: 'userName',
      width: 150
    },
    {
      title: 'Email',
      dataIndex: ['userId', 'email'],
      key: 'userEmail',
      width: 150
    },
    {
      title: 'Mobile',
      dataIndex: ['userId', 'mobile'],
      key: 'mobile',
      width: 150,
      render: (item, record) => {
        let countryCode = record.userId.countryCode
        return (
          <>
            {countryCode} {item}
          </>
        )
      }
    },
    {
      title: 'Agent Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name',
      width: 150
    },
    {
      title: 'Agent Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 120,
      searchTextName: 'phone',
      render: (item, record) => {
        let countryCode = record.countryCode
        return (
          <>
            {countryCode} {item}
          </>
        )
      }
    },
    {
      title: 'Agent Email',
      dataIndex: 'email',
      key: 'email',
      searchTextName: 'email',
      width: 150
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      searchTextName: 'assignedTo',
      width: 150,
      hidden: !(user.userType == 'admin'),
      render: (item) => {
        return (
          <>
            {item && item.name ? item.name : null}
            {item && item.email ? ` (${item.email})` : null}
          </>
        )
      }
    },
    {
      title: 'Callback Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
      render: (item) => {
        return item ? displayDate(item) : ''
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      searchTextName: 'status',
      width: 100,
      render: (item, record) => {
        let className = ''
        let labelText = ''

        if (item === 'done') {
          className = 'label label-success-green label-sm'
          labelText = 'Done'
        } else if (item === 'cancel') {
          className = 'label label-danger label-sm'
          labelText = 'Cancel'
        } else {
          className = 'label label-success label-sm'
          labelText = 'Pending'
        }

        return (
          <span className={className}>
            {labelText}
          </span>
        )
      }
    },
    {
      title: 'Details/Reason',
      key: 'details',
      dataIndex: 'details',
      width: 150,
      render: (item, record) => {
        return <RenderComment item={record} />
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 70,
      render: (text, record) => {
        return (
          <React.Fragment>
            {record.status === 'Pending' ? (
              <>
                <Tooltip title='Done'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      setDetailsModal({ visible: true, id: record._id })
                    }}>
                    <Icon type={'check'} />
                  </button>
                </Tooltip>
                <Tooltip title='Cancel'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      setCancelModal({ visible: true, id: record._id })
                    }}>
                    <Icon type={'cross'} />
                  </button>
                </Tooltip>
              </>
            ) : null}
          </React.Fragment>
        )
      }
    }
  ]
  const getColumns = () => {
    let newCol = _.filter(columns, (item) => {
      return !item.hidden
    })
    return newCol
  }
  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Request ({total || 0})</h5>
              <div className='search-box-table'>

              </div>

              <div className='sort-box-table mark-btn'>

              </div>
            </div>

            <div className='card-body table-responsive'>
              <FilterComponent />

              <TableComp columns={getColumns()}
                         apiRequest={apiRequest}
                         ref={tableRef}
                         pagination={DefaultTablePagination()}
                         extraProps={{ scroll: { x: 1000 } }}
              />
            </div>
            {
              detailsModal.visible ? (
                <Modal
                  title='Enter Detail'
                  visible={detailsModal.visible}
                  onOk={() => {
                    updateCallBackStatus(detailsModal.id, 'done')
                  }}
                  onCancel={() => {
                    setDetailsModal({ ...detailsModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                    placeholder='Enter Details..'
                    required={true}
                  />
                </Modal>
              ) : null
            }
            {
              cancelModal.visible ? (
                <Modal
                  title='Enter Reason'
                  visible={cancelModal.visible}
                  onOk={() => {
                    updateCallBackStatus(cancelModal.id, 'cancel')
                  }}
                  onCancel={() => {
                    setCancelModal({ ...cancelModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder='Enter reason for Cancel'
                    required={true}
                  />
                </Modal>
              ) : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default GicRequestCallback
