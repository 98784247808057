import React, {useEffect, useState} from "react"
import {Modal, Tooltip} from "antd";
import {RowTable} from "../_utils/RowTable";
import {useDispatch} from "react-redux";
import {loadUserMobileFxn} from "../../containers/users/actions/user";

const MobileEmailInfo = (props) => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    let {data, type, value} = props;
    let [visible, setVisible] = useState(false)

    let events = {
        handleOk: () => {
            setVisible(false)
        },
        handleCancel: () => {
            setVisible(false)
        },
        checkShowToUser: () => {
            let {userType, branchManagerType} = user;
            if (userType == 'admin') {
                return true
            } else if (userType == 'agent') {
                return true
            } else if (userType == 'branchManager' && !branchManagerType) {
                return true
            } else {
                return false
            }
        }
    }
    return (
        <>
            {events.checkShowToUser() ?
                <>
                    {value}
                </> :
                <>
                    {type == 'mobile' ? "xxxxxxxxx" : "xxxxxxxx@xxxxx.xxxxx"}
                    <Tooltip title={'View Info'}>
                        <a className={'eye_btn'} onClick={() => setVisible(true)}>
                            <i className={'fa fa-eye'}/>
                        </a>
                    </Tooltip>
                </>
            }

            {visible ? <MobileEmailInfoModal events={events} visible={visible} data={data}/> : null}

        </>
    )
}

const MobileEmailInfoModal = (props) => {
    let {data, visible, events} = props;
    let dispatch = useDispatch();
    let [userData, setUserData] = useState({})
    useEffect(() => {
        if (data._id) {
            loadData()
        }
    }, [data._id])
    let loadData = async () => {
        let {data: userData, success} = await dispatch(loadUserMobileFxn({
            userId: data._id,
            userType: data.userType ? data.userType : ""
        }))
        if (userData) {
            setUserData(userData)
        }
    }


    return (
        <>
            <Modal
                title={`${userData.name}`}
                visible={visible}
                footer={false}
                onOk={events.handleOk}
                onCancel={events.handleCancel}>
                <div className={'tableBox striped'}>
                    {userData.email ? <RowTable title={'Email'} value={<span>{userData.email}</span>}/> : null}
                    {userData.mobile ? <RowTable title={'Mobile No'} value={<span>{userData.mobile}</span>}/> : null}
                </div>
            </Modal>

        </>
    )
}

export default MobileEmailInfo
