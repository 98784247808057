import { apiUrl } from '../../../settings'

export const addSelectedCourseUrl = () => {
  return apiUrl + '/selectCourse'
}

export const addCourseInStudentUrl = () => {
  return apiUrl + '/api/selectCourse'
}

export const listStudentSelectedCourseUrl = () => {
  return apiUrl + '/selectCourse'
}

/*
export const singleSelectedCourseUrl = (id) => {
  return apiUrl + '/selectedCourse/' + id
}
*/

export const singleSelectedCourseUrl = () => {
  return apiUrl + '/selectedCourse'
}

export const getSelectedApplications = () => {
  return apiUrl + '/getSelectedApplications'
}

export const getSelectedCountryApplications = () => {
  return apiUrl + '/getSelectedCountryApplications'
}

export const addApplicationsPendency = () => {
  return apiUrl + '/addApplicationsPendency'
}

export const getApplicationNotesUrl = () => {
  return apiUrl + '/api/getApplicationNotes'
}

export const getApplicationSingleNotesUrl = () => {
  return apiUrl + '/api/getApplicationSingleNotes'
}

export const getApplicationActivityUrl = () => {
  return apiUrl + '/api/getApplicationActivity'
}

export const sendSmsUrl = () => {
  return apiUrl + '/api/sendSmsToStudent'
}

export const sendEmailUrl = () => {
  return apiUrl + '/api/sendEmailToStudent'
}

export const updateIntakeUrl = () => {
  return apiUrl + '/api/updateIntake'
}

export const communicationActivitiesUrl = () => {
  return apiUrl + '/api/communicationActivities'
}

export const emailActivitiesUrl = () => {
  return apiUrl + '/api/emailActivities'
}

export const smsActivitiesUrl = () => {
  return apiUrl + '/api/smsActivities'
}

export const studentDocumentsUrl = () => {
  return apiUrl + '/api/studentDocuments'
}

export const getStudentApplicationDocumentsUrl = () => {
  return apiUrl + '/api/getStudentApplicationDocumentsNew'
}

export const updateAppPendencyUrl = () => {
  return apiUrl + '/api/updateAppPendency'
}

export const transferApplicationUrl = () => {
  return apiUrl + '/api/transferApplication'
}

export const departmentUserByStatusUrl = () => {
  return apiUrl + '/api/getDepartmentUserByStatus'
}

export const studentOtherApplicationUrl = () => {
  return apiUrl + '/api/getOtherApplications'
}
export const addAppCommentUrl = () => {
  return apiUrl + '/api/addAppComment'
}
export const getSingleAppCommentUrl = () => {
  return apiUrl + '/api/getSingleAppComment'
}

export const exportApplicationsListUrl = () => {
  return apiUrl + '/api/exportApplicationsList'
}
export const calculateCommissionUrl = () => {
  return apiUrl + '/evaluate/calculateCommission'
}
export const submitCommissionUrl = () => {
  return apiUrl + '/api/submitCommission'
}
export const editCommissionUrl = () => {
  return apiUrl + '/api/editCommission'
}

export const updateCampusUrl = () => {
  return apiUrl + '/api/updateCampus'
}
export const requestLoaUrl = () => {
  return apiUrl + '/api/applicationRequestLoa'
}

export const requestLoaApprovedUrl = () => {
  return apiUrl + '/api/applicationRequestLoaApproved'
}
export const fcmtStatusUpdateUrl = () => {
  return apiUrl + '/api/fcmtStatusUpdate'
}
export const reCreateFcmtOfferLetterUrl = () => {
  return apiUrl + '/api/reCreateFcmtOfferLetter'
}
export const getFcmtDocumentsUrl = () => {
  return apiUrl + '/api/getFcmtAppDocuments'
}
export const reCreateFcmtLoaLetterUrl = () => {
  return apiUrl + '/api/reCreateFcmtLoaLetter'
}
export const reCreateFcmtPaymentReceiptUrl = () => {
  return apiUrl + '/api/reCreateFcmtPaymentReceipt'
}
export const fcmtSendMailUrl = () => {
  return apiUrl + '/api/fcmtSendMail'
}
export const fcmtSendReceiptMailUrl = () => {
  return apiUrl + '/api/fcmtSendReceiptMail'
}
export const fcmtSendOfferMailUrl = () => {
  return apiUrl + '/api/fcmtSendOfferMail'
}

export const transferApplicationToOtherDepartmentUrl = () => {
  return apiUrl + '/api/transferApplicationToOtherDepartment'
}

export const sendOpenApplicationMailUrl = () => {
  return apiUrl + '/api/sendOpenApplicationMail'
}
export const interviewStatusUpdateUrl = () => {
  return apiUrl + '/api/interviewStatusUpdate'
}

export const getStudentFcmtCourseUrl = () => {
  return apiUrl + '/getStudentFcmtCourse'
}

export const getPendingFCMTApplicationsUrl = () => {
  return apiUrl + '/pendingLOAFCMTApplications'
}
export const getInterviewStudentCoursesUrl = () => {
  return apiUrl + '/getInterviewStudentCourses'
}

export const exportAllApplicationsListUrl = () => {
  return apiUrl + '/api/exportAllApplicationsList'
}

export const exportFcmtApplicationsListUrl = () => {
  return apiUrl + '/exportStudentFcmtCourse'
}

export const exportCambieApplicationsListUrl = () => {
  return apiUrl + '/exportStudentCambieCourse'
}

export const getDirectUniversityApplicationsUrl = () => {
  return apiUrl + '/getDirectUniversityApplications'
}

export const deleteDocumentUrl = () => {
  return apiUrl + '/api/deleteDocument'
}

export const enrollOnShoreStudentUrl = () => {
  return apiUrl + '/api/enrollOnShoreStudent'
}

export const updatePaymentUrl = () => {
  return apiUrl + '/registerStudent/payment'
}

export const fcmtApplicationSendToApprovalUrl = () => {
  return apiUrl + '/api/fcmtApplicationSendToApproval'
}
export const updateGcKeyUrl = () => {
  return apiUrl + '/api/updateGcKey'
}

export const oshcApplicationsUrl = () => {
  return apiUrl + '/api/oshcApplications'
}

export const updateOshcInsuranceUrl = () => {
  return apiUrl + '/api/updateOshcInsurance'
}

export const ukTuitionFeeApplicationUrl = () => {
  return apiUrl + '/api/ukTuitionFeeApplication'
}
export const studentLoginExistsUrl = () => {
  return apiUrl + '/api/studentLoginExists'
}

export const generateStudentLoginUrl = () => {
  return apiUrl + '/api/generateStudentLogin'
}

export const getRyanAgentsUrl = () => {
  return apiUrl + '/getRyanAgents'
}

export const getStudentListCountryWiseUrl = () => {
  return apiUrl + '/getStudentListCountryWise'
}

export const exportStudentListCountryWiseUrl = () => {
  return apiUrl + '/api/exportStudentListCountryWise'
}

export const verificationCertificateAustraliaUrl = () => {
  return apiUrl + '/api/verificationCertificateAustralia'
}
export const exportDirectUniversityRecordsCountUrl = () => {
  return apiUrl + '/exportDirectUniversityRecordsCount'
}

export const exportMarketingRecordsCountUrl = () => {
  return apiUrl + '/exportMarketingRecordsCount'
}
export const getStudentOxFordCourseUrl = () => {
  return apiUrl + '/getStudentOxfordCourse'
}

export const getPendingOxFordApplicationsUrl = () => {
  return apiUrl + '/pendingLOAOxfordApplications'
}
export const uploadSignedVisaDraftUrl = () => {
  return apiUrl + '/api/uploadSignedVisaDraft'
}

export const oxfordSignedVisaSendForApprovalUrl = () => {
  return apiUrl + '/api/approveSignedVisaDraft'
}
export const applicationCopyOfDraftUrl = () => {
  return apiUrl + '/api/applicationCopyOfDraft'
}
export const visaSendToApprovalUrl = () => {
  return apiUrl + '/api/visaSendToApproval'
}
export const directUniversityApplicationReportUrl = () => {
    return apiUrl + '/directUniversityApplicationReport'
}

export const getStudentLesterCourseUrl = () => {
    return apiUrl + '/getStudentLesterCourse'
}
export const getCanadaLoaApplicationUrl = () => {
  return apiUrl + '/getCanadaLoaApplications'
}

export const directOnshoreApplyCourseUrl = () => {
  return apiUrl + '/directOnshore/selectCourse'
}
export const fcmtPendingTTApplicationsUrl = () => {
  return apiUrl + '/fcmtPendingTTApplications'
}

export const deleteTuitionFeesDocumentUrl = () => {
  return apiUrl + '/api/deleteTuitionFeesDocument'
}

export const rollbackToTTUploadUrl = () => {
  return apiUrl + '/api/rollbackToTTUpload'
}

export const marketingUserListForTTUrl = () => {
  return apiUrl + '/api/marketingUserListForTT'
}

export const privateCollegePendingForSubmissionsAppUrl = () => {
    return apiUrl + '/privateCollegePendingForSubmissionsApp'
}

export const reCreateEnrollmentLetterUrl = () => {
    return apiUrl + '/api/reCreateEnrollmentLetter'
}

export const getPaidTTApplicationUrl = () => {
  return apiUrl + '/api/getPaidTTApplication'
}

export const calculateCommissionByIntakeUrl = () => {
  return apiUrl + '/calculateCommissionByIntake'
}
export const reCreateFcmtOnshoreLoaLetterUrl = () => {
  return apiUrl + '/api/reCreateFcmtOnshoreLoaLetter'
}

export const onshoreApplicationListUrl = () => {
  return apiUrl + '/api/onshore-application-list'
}
