import React, { useState, useEffect } from 'react'
import './style.css'
import { getAllLatestUpdatesFxn } from './actions'
import { Skeleton, Icon, Empty } from 'antd'
import { push } from 'connected-react-router'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import _ from 'lodash'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'

const LatestUpdate = (props) => {
  let { currentUser } = props
  let dispatch = useDispatch()
  const [updates, setUpdates] = useState([])
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)
  const [total, setTotal] = useState(0)
  let [pageObj, setPageObj] = useState({
    page: 1,
    count: 20
  })
  useEffect(() => {
    events.fetchList()
  }, [])

  let events = {
    addNew: () => {
      dispatch(push('/add-latest-updates'))
    },
    fetchList: async () => {
      let params = _.clone(pageObj)
      params.sortField = '_id'
      params.sortOrder = 'descend'
      setLoadMoreLoading(true)
      const { data, total } = await getAllLatestUpdatesFxn(params)
      setLoadMoreLoading(false)
      setUpdates((prevState) => {
        return [
          ...prevState,
          ...data
        ]
      })
      setPageObj((prevState) => {
        return {
          ...prevState,
          page: pageObj.page + 1
        }
      })
      setTotal(total)
    },
    loadMore: () => {
      events.fetchList()
    }
  }


  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center all-student-search'
                 style={{ height: 60 }}>
              <h5>Latest Updates (Total - {total})</h5>
              <div className='search-box-table' style={{ backgroundColor: 'transparent' }}>

              </div>
              <div className='sort-box-table mark-btn'>
                {currentUser && currentUser.userType == 'admin' ?
                  <a className={'btn btn-default roundNew'} onClick={events.addNew}>Add New Update</a> : null}
              </div>
            </div>

            <div className='card-body table-responsive'>
              <div className='latest-update'>
                {updates && updates.length ? updates.map((update) => {
                  return (
                    <div key={update._id} className='article'>
                      <>
                        {update.imageUrl && update.imageUrl.path && (
                          <LazyLoadImage
                            src={update.imageUrl.path}
                            alt={update.imageUrl.fileName}
                            className='article-image'
                          />
                        )}
                        <div className='article-details ml20'>
                          <h3 className='article-title'>
                            <Icon type='double-right' /> &nbsp;
                            {update.title}
                          </h3>
                          <hr className={'line-hr'} />
                          <div className='article-datetime mb10'>
                            <div className='article-date'>
                              <img
                                src={'../assets/icons/calendar.png'} /> &nbsp;{moment(update.createdAt).format('DD/MM/YYYY hh:mm')}
                            </div>
                            <div className='article-time'>

                            </div>

                          </div>

                          <p className='article-content'>{update.content}</p>
                        </div>
                      </>
                    </div>
                  )
                }) : <Empty className={'w-100'} description={'No Updates'}
                            style={{ height: 300, paddingTop: 80 }} />}

                {loadMoreLoading ? <Skeleton /> : null}
              </div>
              {total && updates.length < total ? <>
                <div className={'alignCenter p-2'}>
                  <a className={'btn btn-default roundNew lg'}
                     onClick={() => {
                       events.loadMore()
                     }}>Load More</a>
                </div>

              </> : null}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
})
export default connect(
  mapStateToProps,
  null
)(LatestUpdate)



