import { customAxios as axios, getToken } from '../../request'

import {
  accommodationListUrl,
  addEnquiryUrl,
  propertiesListUrl,
  searchDataUrl,
  singleCountryUrl,
  universityListUrl
} from '../apis'
import { showPageLoad, hidePageLoad } from '../../modules/actions'

export const getHeader = () => {
  return {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Credentials': true,
      'X-Requested-With': 'XMLHttpRequest',
      crossdomain: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0'
    }
  }
}

export const singleCountryFxn = async () => {
  let resp = await axios.get(singleCountryUrl())
  return resp
}
export const propertiesListFxn = async valData => {
  let { data } = await axios.get(propertiesListUrl(valData))
  return data
}
export const accomodationListFxn = async valData => {
  let { data } = await axios.get(accommodationListUrl(valData))

  return data
}
export const universityListFxn = async () => {
  let data = await axios.get(universityListUrl())
  return data.data
}
export const addEnquiryFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addEnquiryUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const searchDataFxn = async () => {
  let resp = await axios.get(searchDataUrl())
  return resp
}
