import React from "react"
import PendingForSubmissionAppList from "../privateCollege/pendingForSubmissionAppList"
import {fcmtUniversity} from "../../../components/_utils/appUtils";

const FcmtPendingForSubmission = () => {
    return (
        <>
            <PendingForSubmissionAppList
                appName={'FCMT College'}
                universityId={fcmtUniversity}
                redirectKey={'fcmt.fcmtFilePendingForSubmissions'}/>
        </>
    )
}
export default FcmtPendingForSubmission
