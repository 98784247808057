import React, {useState} from "react"

const GcKeyColumnComponent = (props) => {
    let {application} = props
    let [hideV, setHideV] = useState(true)
    return (
        <>
            <div className={'mb5'}>
                {application && (
                    <div>
                        <div className={hideV ? 'appCommentDotsReplace' : ''}>
                            {application.gcKey ? application.gcKey : ''}
                        </div>
                        {application.gcKey && application.gcKey.length && application.gcKey.length > 50 ? (
                            <div className={'alignRight'}>
                                {hideV ? (
                                    <a
                                        onClick={() => {
                                            setHideV(false)
                                        }}>
                                        Show More
                                    </a>
                                ) : (
                                    <a
                                        onClick={() => {
                                            setHideV(true)
                                        }}>
                                        Show Less
                                    </a>
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )}

                {application.acknowledgementFile && application.acknowledgementFile.path ? <div>
                    <a href={application.acknowledgementFile.path} target={'_blank'}>Download Acknowledgement</a>
                </div> : null}
            </div>


        </>
    )
}
export default GcKeyColumnComponent
