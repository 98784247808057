import React, { Component } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { connect } from 'react-redux'
import { listAllUsers } from '../actions/user'
import EditTeam from './editTeam'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { Tooltip } from 'antd'
class AllTeam extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedUser: {},
      visibleUpdateAgent: false
    }
    this.tableRef = React.createRef()
  }
  componentDidMount() {
  }

  apiRequest = (params) => {
    let { dispatch } = this.props
    return new Promise(async (resolve) => {
      params.userType = 'team'
      params.sortField = 'name'
      params.sortOrder = 'ascend'
      resolve(dispatch(listAllUsers({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email']
      })))
    })
  }

  closeEditTeam = () => {
    this.setState({
      selectedUser: {},
      visibleUpdateAgent: false
    }, () => {
      this.tableRef.current.reload()
    })
  }

  render() {
    let { selectedUser, visibleUpdateAgent } = this.state
    const columns = [
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email'
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile'
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        searchTextName: 'address'
      },
      {
        title: 'City/State/Country',
        dataIndex: 'cityName',
        key: 'cityName',
        render: (item, record) => {
          return (
            <React.Fragment>
              {item ? item : ''}
              {record && record.stateName ? <span>, {record.stateName}</span> : ''}
              {record && record.countryName ? <span>, {record.countryName}</span> : ''}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Selected Country',
        dataIndex: 'countryArr',
        key: 'countryArr',
        render: (item, record) => {
          const countries = item ? item.map(country => country.countryName).join(', ') : ''
          return (
            <React.Fragment>
              {countries}
            </React.Fragment>
          )
        }
      },
      /*{
        title: 'Logo',
        dataIndex: 'logo',
        key: 'logo',
        render: (val) => {
          return (
            <React.Fragment>
              {val && val.url ? <img src={val.url} className={'imgSm'}/> : null}
            </React.Fragment>
          )
        }
      },*/
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 170,
        render: (item, record) => {
          let { block } = record
          return (
            <div style={{ textAlign: 'left' }}>
              <div>
                {block ? (
                  <label className='label label-danger sm'>Not Activated</label>
                ) : null}
                {!block ? (
                  <label className='label label-success-green sm'>Activated</label>
                ) : null}
              </div>
            </div>
          )
        }
      },
      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        render: (val, record) => {
          return (
            <React.Fragment>
              <Tooltip title='Edit'>
                <a
                  className={'btn btn-default sm roundNew'}
                  onClick={() => {
                    this.setState({
                      visibleUpdateAgent: true,
                      selectedUser: record
                    })
                  }}>
                  Edit
                </a>
              </Tooltip>
            </React.Fragment>
          )
        }
      }
    ]
    return (
      <div>

        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>All Team</h5>
                <div className='search-box-table'>
                </div>
                <CheckMyRights rightList={['agent']}>
                  <div className='sort-box-table mark-btn'>
                    <a className='btn' href={'/addTeam'}>
                      <img src={'../dist/icons/plus.png'} className={'plus'} />
                      Add Team</a>
                  </div>
                </CheckMyRights>
              </div>
              <div className='card-body table-responsive'>
                <TableComp
                  columns={columns}
                  ref={this.tableRef}
                  apiRequest={this.apiRequest}
                />
              </div>
            </div>
          </div>
        </div>
        {visibleUpdateAgent ? <EditTeam
          visible={visibleUpdateAgent}
          onClose={() => this.closeEditTeam()}
          userData={selectedUser} /> : ''}
      </div>)
  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllTeam)
