import {
  Col,
  DatePicker,
  Icon, notification,
  Row,
  Select,
  Tooltip
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import {
  DefaultTablePagination,
  departmentObj,
  filterOption, InputBox,
  newFormatDisplayDate, dateFilterRange, expenseTypes
} from '../../../components/_utils/appUtils'
import { listAllUsers } from '../../users/actions/user'
import { expense_exportFxn, listDailyExpense } from '../actions/dailyExpense'
import UpdateStatus from './takeAction'
import ExpenseInfo from '../drawers/expenseInfo'
import { getUrlPushWrapper } from '../../../routes'
import moment from 'moment'

const { RangePicker } = DatePicker

const { Option } = Select
let initFilters = {
  marketingUserId: '',
  status: '',
  fromDate: '',
  toDate: '',
  expenseType: ''
}
const statusList = [
  'Pending',
  'Approved',
  'Cancel'
]
const dateFormat = 'DD/MM/YYYY'


const FilterComponent = (props) => {
  let dispatch = useDispatch()
  let { filterData, events } = props
  let { fromDate, toDate } = filterData
  let [marketingUserList, setMarketingUserList] = useState([])
  const loadMarketingUsers = async () => {
    let obj = {
      results: 100,
      select: ['name', 'email', 'mobile'],
      defaultCurrency: 'INR',
      customQuery: {
        $or: [
          { userType: 'branchUser', department: departmentObj.marketing },
          { userType: 'marketingManager' }
        ]
      }
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }
  useEffect(() => {
    loadMarketingUsers()
  }, [])

  return (
    <Row gutter={12} className={'filter_box'}>
      <Col span={4}>
        <InputBox title={'Search by date'}>
          <RangePicker
            format={dateFormat}
            defaultValue={[filterData.fromDate, filterData.toDate]}
            value={[fromDate ? moment(fromDate, dateFormat) : null, toDate ? moment(toDate, dateFormat) : null]}
            onChange={val => {
              console.log(val)
              events.updateFilter({ fromDate: val[0], toDate: val[1] })
            }}
            ranges={dateFilterRange}
          />
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by marketing user'}>
          <Select
            name='Marketing User'
            filterOption={filterOption}
            allowClear={true}
            placeholder={'Marketing User'}
            onChange={item => {
              events.updateFilter({ marketingUserId: item })
            }}
            value={filterData.marketingUserId || undefined}>
            {marketingUserList && marketingUserList.length
              ? marketingUserList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>
                    {item.name}
                  </Option>
                )
              })
              : null}
          </Select>
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by expense type'}>
          <Select
            name='Expense type'
            filterOption={filterOption}
            allowClear={true}
            placeholder={'Expense type'}
            onChange={item => {
              events.updateFilter({ expenseType: item })
            }}
            value={filterData.expenseType || undefined}>
            {expenseTypes.map((item, key) => {
              return (
                <Option value={item} key={key}>
                  {item}
                </Option>
              )
            })}
          </Select>
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by status'}>
          <Select
            name='Status'
            filterOption={filterOption}
            allowClear={true}
            placeholder={'Status'}
            onChange={item => {
              events.updateFilter({ status: item })
            }}
            value={filterData.status || undefined}>
            {statusList.map((item, key) => {
              return (
                <Option value={item} key={key}>
                  {item}
                </Option>
              )
            })}
          </Select>
        </InputBox>
      </Col>
      <Col span={24}>
        <div className={'btn_group'}>
          <a className={'default_btn'} onClick={events.searchFxn}>Search</a>
          <a className={'default_btn'} onClick={events.clearFxn}>Clear</a>
          <a className={'default_btn'} onClick={events.expense_export_fxn}>Export</a>
        </div>
      </Col>
    </Row>
  )
}

const DailyExpenseList = props => {
  const { Option } = Select

  let tableRef = useRef()
  const dispatch = useDispatch()
  const [fromDate, setFromDate] = useState()
  const [visible, setVisible] = useState(false)
  let [expense, setExpense] = useState({})
  let [updateType, setUpdateType] = useState('')
  let [filterData, setFilterData] = useState(initFilters)
  let [total, setTotal] = useState(0)

  let [expenseInfoState, setExpenseInfoState] = useState({
    visible: false,
    data: {}
  })

  const events = {
    showInfoDrawer: (data) => {
      setExpenseInfoState({
        visible: true, data
      })
    },
    hideInfoDrawer: () => {
      setExpenseInfoState({
        visible: false, data: {}
      })
    }
  }

  useEffect(() => {
    filterEvents.setFieldByParams()
  }, [])

  /*  useEffect(
        () => {
            tableRef.current.reload()
        },
        [fromDate, marketingUserId]
    )*/


  const apiRequest = params => {
    return new Promise(async resolve => {
      let filterResp = await filterEvents.getParamsForApplicationList()
      params = { ...params, ...filterResp }
      let { toDate, fromDate } = params
      if (fromDate) {
        let date = {}
        date.$gte = new Date(fromDate)
        date.$lte = new Date(toDate)
        params.date = date
        delete params['fromDate']
        delete params['toDate']
      }
      if (filterResp.marketingUserId) {
        params.userId = filterResp.marketingUserId
        delete params['marketingUserId']
      }
      params.sortField = '_id'
      params.sortOrder = 'descend'
      let respData = await dispatch(
        listDailyExpense({
          ...params,
          regExFilters: ['name', 'agentName', 'expenseType']
        })
      )
      setTotal(respData.total)
      resolve(respData)
    })
  }

  let filterEvents = {
    getParams: (field) => {
      let searchParams = new URLSearchParams(window.location.search)
      return searchParams.get(field) ? searchParams.get(field) : ''
    },
    updateFilter: (data) => {
      setFilterData((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    setFieldByParams: async () => {
      let { getParams } = filterEvents
      let obj = {}
      _.each(initFilters, (item, key) => {
        if (getParams(key)) {
          obj[key] = getParams(key)
        }
      })
      setFilterData((prevState) => {
        return {
          ...prevState,
          ...obj
        }
      })
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 100)
    },
    getParamsForApplicationList: () => {
      return new Promise(resolve => {
        let obj = {}

        _.each(initFilters, (item, key) => {
          if (filterData[key]) {
            obj[key] = filterData[key]
          }
        })

        if (obj.toDate) {
          obj.toDate = moment(obj.toDate).format('MM-DD-YYYY')
        }
        if (obj.fromDate) {
          obj.fromDate = moment(obj.fromDate).format('MM-DD-YYYY')
        }
        resolve(obj)
      })
    },
    searchFxn: async () => {
      let obj = {}
      _.each(initFilters, (item, key) => {
        if (filterData[key]) {
          obj[key] = filterData[key]
        }
      })
      if (obj.toDate) {
        obj.toDate = moment(obj.toDate).format('MM-DD-YYYY')
      }
      if (obj.fromDate) {
        obj.fromDate = moment(obj.fromDate).format('MM-DD-YYYY')
      }
      dispatch(getUrlPushWrapper('expense.dailyExpenseList', { ...obj }))
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 200)
    },
    clearFxn: () => {
      let obj = {}
      dispatch(getUrlPushWrapper('expense.dailyExpenseList', { ...obj }))
      setFilterData((prevState) => {
        return {
          ...prevState,
          ...initFilters
        }
      })
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)
    },
    expense_export_fxn: () => {
      return new Promise(async resolve => {

        let filterResp = await filterEvents.getParamsForApplicationList()
        let params = { ...filterResp, results: total }
        let { toDate, fromDate } = params
        if (fromDate) {
          let date = {}
          date.$gte = new Date(fromDate)
          date.$lte = new Date(toDate)
          params.date = date
          delete params['fromDate']
          delete params['toDate']
        }
        if (filterResp.marketingUserId) {
          params.userId = filterResp.marketingUserId
          delete params['marketingUserId']
        }
        params.sortField = '_id'
        params.sortOrder = 'descend'
        let respData = await dispatch(
          expense_exportFxn({
            ...params,
            regExFilters: ['name', 'agentName', 'expenseType']
          })
        )
        if (respData && respData.success) {
          notification.success({ message: respData.message })
        } else {
          notification.error({ message: respData.message })
        }
      })
    }

  }

  const columns = [
    {
      title: 'Sr .No',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (item, record, index) => {
        return <div style={{ width: 50 }}>{index + 1}</div>
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 90,
      render: (val, record) => {
        return (
          <>
            <div style={{ width: 90 }}>
              {newFormatDisplayDate(val)}
            </div>
          </>
        )
      }
    },
    {
      title: 'Generated By',
      dataIndex: 'userId',
      key: 'userId',
      width: 150,
      render: (val, record) => {
        let { expenseType } = record
        return (
          <div style={{ width: 150 }}>
            {val.name}
            <div style={{ marginTop: 8, marginBottom: 8 }}>
              <label className={'label label-sm label-default'}>{expenseType}</label>
            </div>
          </div>
        )
      }
    },

    {
      title: 'Hotel/Food/Car/Other',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name',
      render: (val, record) => {
        let { fromPlace, toPlace, agentName, expenseType } = record
        return (
          <>
            {(expenseType == 'Hotel' || expenseType == 'Food') ? <div>
              {val}
              <br />
              {record.city}({record.state})
            </div> : null}
            {expenseType == 'Car' ? <div>
              Place
              - {record.fromPlace ? `${record.fromPlace}, ` : ''}{record.city ? `${record.city}, ` : ''}{record.state}
              <br />
              To
              - {record.toPlace ? `${record.toPlace}, ` : ''}{record.toCity ? `${record.toCity}, ` : ''}{record.toState}
            </div> : null}
            {expenseType == 'Other Expense' ? <div>
              {'Gift To Agent'} <br />
              {agentName}
            </div> : null}

          </>
        )
      }
    },

    /*  {
          title: 'Car Details',
          dataIndex: 'fromPlace',
          key: 'fromPlace',
          // searchTextName: 'fromPlace',
          render: (val, record) => {
              return val ? (
                  <p>
                      From- {val} <br/>
                      To - {record.toPlace}
                  </p>
              ) : null
          }
      },

      {
          title: 'Other Details',
          dataIndex: 'agentName',
          key: 'agentName',
          // searchTextName: 'agentName',
          render: (val, record) => {
              return val ? (
                  <p>
                      {'Gift To Agent'} <br/>
                      {val}
                  </p>
              ) : null
          }
      },*/
    {
      title: 'Receipt',
      dataIndex: 'receipt',
      key: 'receipt',
      width: 80,
      render: (val, record) => {
        return val && val.path ? (
          <Tooltip title={val.name}>
            <a className={'btn'} target={'_blank'} href={val.path}>
              <Icon type={'eye'} />
            </a>{' '}
          </Tooltip>
        ) : (
          ''
        )
      }
    },
    {
      title: 'Amount',
      dataIndex: '_id',
      key: '_id',
      width: 120,
      render: (val, record) => {
        let { userId } = record
        return val ? (
          <p>
            {userId && userId.defaultCurrency ? userId.defaultCurrency : ''}&nbsp;
            {record.amount
              ? (record.amount).toFixed(2)
              : record && record.netAmount
                ? (record.netAmount).toFixed(2)
                : null}
          </p>
        ) : null
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 200,
      render: (val, record) => {
        let { reason } = record
        return (
          <React.Fragment>
            <label
              className={
                val == 'Cancel'
                  ? 'label label-danger label-sm'
                  : val == 'Pending'
                    ? 'label label-warning label-sm'
                    : val == 'Approved'
                      ? 'label label-success label-sm'
                      : 'label'
              }>
              {val}
            </label>
            <div className={'mt10'}>
              {reason}
            </div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      width: 180,
      render: (item, record) => {
        return (
          <>
            <div className={'btn_group'} style={{ width: 180 }}>
              <Tooltip title={'View Details'}>
                <a className={'default_btn'}
                   onClick={() => {
                     events.showInfoDrawer(record)
                   }}>
                  <img src='../uniBtn/eye.png' />
                  View
                </a>
              </Tooltip>

              {item == 'Pending' ?
                <>
                  <Tooltip title={'Cancel'}>
                    <a className={'default_btn'}
                       onClick={() => {
                         setExpense(record)
                         setVisible(true)
                         setUpdateType('Cancel')
                       }}>
                      <img src='../dist/img/cross.svg' />
                      Cancel
                    </a>
                  </Tooltip>
                  <Tooltip title={'Approve'}>
                    <a className={'default_btn'}
                       onClick={() => {
                         setExpense(record)
                         setVisible(true)
                         setUpdateType('Approved')
                       }}>
                      <img src='../dist/img/check-solid.svg' />
                      Approve
                    </a>
                  </Tooltip>
                </>
                : null}


            </div>
          </>
        )
      }
    }
  ]

  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Daily Expense List</h5>
              <div className='search-box-table' />
            </div>

            <div className='card-body table-responsive mt10'>
              {/* {filterBlock} */}

              <FilterComponent
                filterData={filterData}
                events={filterEvents} />

              <TableComp
                columns={columns}
                apiRequest={apiRequest}
                ref={tableRef}
                pagination={DefaultTablePagination()}
              />
            </div>
            {visible ? (
              <UpdateStatus
                updateType={updateType}
                expense={expense}
                visible={visible}
                onClose={() => {
                  setVisible(false)
                }}
                onSubmit={() => {
                  setVisible(false)
                  tableRef.current.reload()
                }}
              />
            ) : null}

            {expenseInfoState.visible ?
              <ExpenseInfo {...expenseInfoState} onClose={events.hideInfoDrawer} /> : null}

          </div>
        </div>
      </div>
    </>
  )
}

export default DailyExpenseList
