import React from "react"
import ReceivedCommissionList from "../privateCollege/receivedCommissionList";
import {RoyalArtsCollege} from "../../../components/_utils/appUtils";

const CiraReceivedCommApplicationList = () => {
    return (
        <ReceivedCommissionList
            appName={'Cira College'}
            redirectKey={'cira.ciraReceivedCommission'}
            universityId={RoyalArtsCollege}/>
    )
}
export default CiraReceivedCommApplicationList
