import { apiUrl } from '../../../settings'

export const addFeedbackUrl = () => {
  return apiUrl + '/feedback'
}

export const listFeedbackUrl = () => {
  return apiUrl + '/feedback'
}


export const singleFeedbackUrl = (id) => {
  return apiUrl + '/feedback/' + id
}

export const deleteFeedbackUrl = (id) => {
  return apiUrl + '/deleteFeedback'
}

export const assignToUrl = () => {
  return apiUrl + '/feedback/assign'
}

export const closeFeedbackUrl = () => {
  return apiUrl + '/feedback/resolve'
}

export const addFeedbackCommentUrl = () => {
  return apiUrl + '/feedback-comment/add'
}

export const feedbackCommentsUrl = () => {
  return apiUrl + '/feedback-comment/list'
}

export const addComplaintFeedbackUrl = () => {
  return apiUrl + '/feedback/submit-feedback'
}
export const singleFeedbackApiUrl = () => {
  return apiUrl + '/feedback/single-feedback'
}
