import { Button, Card, Drawer, Form, Icon, Input, notification } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import InputBox from '../../../../components/_utils/InputBox'
import { updateAdvanceCommissionFxn } from '../../actions/advanceCommission'


let initState = {
  status: '',
  reason: ''
}
const { TextArea } = Input

const UpdateInterestedList = props => {
  let dispatch = useDispatch()
  let { onClose, visible, requestedId, onSubmit } = props

  let [state, setState] = useState(initState)
  let events = {
    setLocalState: data => {
      setState(prevState => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    handleSubmit: async e => {
      e.preventDefault()
      if (!state.status) {
        notification.warn({ message: 'Please choose status.' })
        return
      }

      if (state.status == 'Rejected') {
        if (!state.reason) {
          notification.warn({ message: 'Enter Reason' })
          return
        }
      }
      let resp = await dispatch(
        updateAdvanceCommissionFxn({ ...state, requestedId })
      )
      if (resp && resp.success) {
        onSubmit()
      }
    }
  }

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      title={'Update Status'}
      width={'40%'}>
      <Card bordered={false}>
        <Form>
          <Form.Item>
            <InputBox title={'Status*'}>
              <select className={'form-control'} value={state.status} onChange={({ target }) => {
                events.setLocalState({ status: target.value })
              }}>
                <option value={''}>Choose Status</option>
                <option value={'Approved'}>Approved</option>
                <option value={'Rejected'}>Reject</option>
              </select>
            </InputBox>
          </Form.Item>

          {state.status == 'Rejected' ?
            <Form.Item>
              <InputBox title={'Reason*'}>
                <TextArea
                  rows={7}
                  className={'form-control customTextArea'}
                  placeholder={'Reason'}
                  onChange={({ target }) => {
                    events.setLocalState({ reason: target.value })
                  }}
                  value={state.reason}
                />
              </InputBox>
            </Form.Item> : null}
          <Form.Item className={'mt5'}>
            <Button type='primary' onClick={events.handleSubmit}>
              SUBMIT
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}
export default UpdateInterestedList
