import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  UserLogsUrl,
  UserRatingUrl,
  addEmployeeUrl,
  addUserUrl,
  agentAppCountUrl,
  agentListUrl,
  allNotificationUrl,
  allUsersUrl,
  blockAgentUrl,
  branchManagerAgentListBySearchUrl,
  branchManagerAgentListUrl,
  broadcastingUrl,
  checkBroadcastingStatusUrl,
  checkCertificationTokenUrl,
  checkEnquiryFeedbackUrl,
  closeAgentDealUrl,
  contactUsUrl,
  downloadCertificateUrl,
  educatorFeedbackUrl,
  enquiryFeedbackUrl,
  enquiryUrl,
  exportAgentUrl,
  footFallUrl,
  getAgentAppCountByParamsIdUrl,
  getAgentCertificateUrl,
  getAssignedUserForAccessmentDepartmentUrl,
  getBranchUserForAssignApplicationUrl,
  getBranchUserForDirectOnshoreUrl,
  getBranchUserForReActivateApplicationUrl,
  getDistinctStateOfOnlineAgentsUrl,
  getMarketingAgentAppCountUrl,
  getMarketingUserByUniversityUrl,
  getNotAssignedMarketingUserInAgentsListUrl,
  getOnlineAgentCountUrl,
  getRatingLogsUrl,
  getUserBirthdayListUrl,
  hideRegistrationMessageUrl,
  listEmployeeUrl,
  listUserUrl,
  loadDistinctStateOfFootFallUrl,
  loadNotificationCountUrl,
  marketingUserAgentListUrl,
  notificationReadyUrl,
  privateMessageReadyUrl,
  resetNotificationCountUrl,
  resetPrivateMessageCountUrl,
  searchUser,
  signeAgreementUrl,
  singleAgentForMarketingUrl,
  singleEmployeeUrl,
  singleUserProfileUrl,
  singleUserUrl,
  subAgentAppCountByAgentUrl,
  updateDefaultReferralUrl,
  updateEmployeeExtraInfoUrl,
  updateInsuranceRightUrl,
  updateMarketingInAgentsUrl,
  updateMarketingUserInAgentUrl,
  updatePasswordUrl,
  updateShareReferralAllowUrl,
  updateUserExtraInfoUrl,
  updateUserRightsUrl,
  updateUserUrl,
  uploadCertificateUrl,
  userTypeWiseUrl,
  loadUserMobileUrl,
  singleUserRightUrl,
  addAgentRightUrl,
  otherUsersUrl,
  addTeamUrl,
  addSubAgentUrl,
  addSubAgentOtpUrl, uploadRegCertUrl, getUserCanadaWallerUrl,
  sendAdvanceCommissionOtpUrl,
  advanceOtpVerificationUrl,
  getSingleUserUrl, getUserApi, addUpdateAgentRightUrl, getTeamApi
} from '../api/user'

export const addUser = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addUserUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const listAllUsers = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(listUserUrl(), config)
  return data
}

export const listAllEmployees = filters => async dispatch => {
  dispatch({ type: 'USER_SHOWLOADER' })
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listEmployeeUrl(), config)
  dispatch({ type: 'USER_HIDELOADER' })
  dispatch({ type: 'LIST_ALL_USER', payload: data })
  if (data.error) {
    // notification.error({
    //   message: data.message || 'No Data found'
    // })
  }
  return data
}

export const branchManagerAgentListBySearch = filters => async dispatch => {
  dispatch({ type: 'USER_SHOWLOADER' })
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(branchManagerAgentListBySearchUrl(), config)
  // console.log('aagya', data)
  dispatch({ type: 'USER_HIDELOADER' })
  dispatch({ type: 'LIST_ALL_USER', payload: data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getUser = id => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(singleUserUrl(id), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const getUserWithoutLoader = id => async dispatch => {
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(singleUserUrl(id), config)
  return data.data
}

export const getSingleEmployee = id => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(singleEmployeeUrl(id), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const updateUser = (valData, id) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(singleUserUrl(id), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}
export const updateUserExtraInfo = (valData, id) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(
    updateUserExtraInfoUrl(id),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}
export const addEmployee = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addEmployeeUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateEmployeeInfo = (valData, id) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(
    updateEmployeeExtraInfoUrl(id),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const userTypeWiseFxn = async userType => {
  let { data } = await axios.post(userTypeWiseUrl(), { userType }, getToken())
  return data
}

export const getAgentAppCount = async valData => {
  let { data } = await axios.post(agentAppCountUrl(), valData, getToken())
  return data
}
export const getAgentAppCountByParamsId = async id => {
  let { data } = await axios.post(
    getAgentAppCountByParamsIdUrl(),
    { id },
    getToken()
  )
  console.log('getAgentAppCountByParamsId.com', data)
  return data
}

export const subAgentAppCountByAgent = async () => {
  let { data } = await axios.post(subAgentAppCountByAgentUrl(), {}, getToken())
  return data
}

export const getBranchUserForAssignApplication = async query => {
  let { data } = await axios.post(
    getBranchUserForAssignApplicationUrl(),
    query,
    getToken()
  )
  return data
}
export const getBranchUserForReActivateApplication = async query => {
  let { data } = await axios.post(
    getBranchUserForReActivateApplicationUrl(),
    query,
    getToken()
  )
  return data
}

export const loadNotificationCountFxn = async () => {
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(loadNotificationCountUrl(), config)
  return data
}

export const resetNotificationCountFxn = async () => {
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(resetNotificationCountUrl(), config)
  return data
}
export const resetPrivateMessageCountFxn = async () => {
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(resetPrivateMessageCountUrl(), config)
  return data
}

export const notificationReadyFxn = async _id => {
  let { data } = await axios.post(notificationReadyUrl(), { _id }, getToken())
  return data
}

export const privateMessageReadyFxn = async _id => {
  let { data } = await axios.post(privateMessageReadyUrl(), { _id }, getToken())
  return data
}

export const updatePassword = async valData => {
  let { data } = await axios.post(updatePasswordUrl(), valData, getToken())
  return data
}
export const UserRating = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(UserRatingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const getUserRating = filters => async dispatch => {
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(UserRatingUrl(), config)
  return data
}

export const searchedUser = async filters => {
  let { data } = await axios.post(searchUser(), filters, getToken())
  return data
}

export const allNotification = filters => async dispatch => {
  // dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(allNotificationUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const blockAgent = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(blockAgentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const uploadCertificate = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(uploadCertificateUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const checkCertificationToken = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    checkCertificationTokenUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const contactUsFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(contactUsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const updateUserRights = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateUserRightsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getUserLogs = filters => async dispatch => {
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  dispatch(showPageLoad())
  let { data } = await axios.get(UserLogsUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const assignedUserForAccessmentDepartment = async query => {
  let { data } = await axios.post(
    getAssignedUserForAccessmentDepartmentUrl(),
    query,
    getToken()
  )
  return data
}

export const getExportAgent = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(exportAgentUrl(), { ...valData }, getToken())
  dispatch(hidePageLoad())
  return data
}
export const signeAgreementFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(signeAgreementUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getBranchManagerAgentList = filters => async dispatch => {
  dispatch({ type: 'USER_SHOWLOADER' })
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(branchManagerAgentListUrl(), config)
  dispatch({ type: 'USER_HIDELOADER' })
  dispatch({ type: 'LIST_ALL_USER', payload: data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const EnquiryFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(enquiryUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const AgentList = async filters => {
  let config = {
    params: { ...filters }
  }
  let { data } = await axios.get(agentListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const EducatorFeedbackFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(educatorFeedbackUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const EnquiryFeedbackFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(enquiryFeedbackUrl(), valData)
  dispatch(hidePageLoad())
  return data
}
export const CheckEnquiryFeedbackFxn = valData => async dispatch => {
  let { data } = await axios.post(checkEnquiryFeedbackUrl(), valData)
  return data
}

export const marketingUserAgentList = id => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let data = await axios.get(marketingUserAgentListUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getAgentCertificate = id => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getAgentCertificateUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateDefaultReferralApi = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    updateDefaultReferralUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateInsuranceRight = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    updateInsuranceRightUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateShareReferralAllowApi = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    updateShareReferralAllowUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getRatingLogsById = obj => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: obj,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getRatingLogsUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getUserBirthdayList = id => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getUserBirthdayListUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getSingleAgentCertificates = async id => {
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(singleUserUrl(id), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const BroadcastingFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(broadcastingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const checkBroadcastingStatusFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    checkBroadcastingStatusUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}
export const downloadCertificateFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(downloadCertificateUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const loadUserListFxn = filters => async dispatch => {
  // dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(listUserUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const getOnlineAgentCountFxn = async () => {
  let config = {
    params: {},
    ...(await getToken())
  }
  let { data } = await axios.get(getOnlineAgentCountUrl(), config)
  return data
}

export const LoadFootFallFxn = filters => async dispatch => {
  // dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(footFallUrl(), config)
  dispatch(hidePageLoad())
  return data
}
export const loadDistinctStateFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    loadDistinctStateOfFootFallUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const loadDistinctStateOfOnlineAgentsFxn = filters => async dispatch => {
  // dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getDistinctStateOfOnlineAgentsUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const getMarketingUserByUniversityFxn = async (valData) => {
  let { data } = await axios.post(getMarketingUserByUniversityUrl(), valData, getToken())
  return data
}
export const updateUserFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateUserUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const singleUserProfileFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleUserProfileUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const fetchUserListFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(listUserUrl(), config)
  return data
}

export const agentListForMarketingManagerFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(getNotAssignedMarketingUserInAgentsListUrl(), config)
  return data
}
export const updateMarketingInAgentsFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateMarketingInAgentsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}
export const singleAgentForMarketingFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleAgentForMarketingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const updateMarketingUserInAgentFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateMarketingUserInAgentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}
export const hideRegistrationMessageFxn = (valData = {}) => async dispatch => {
  let { data } = await axios.post(hideRegistrationMessageUrl(), valData, getToken())
  return data
}
export const getMarketingAgentAppCountFxn = async valData => {
  let { data } = await axios.post(getMarketingAgentAppCountUrl(), valData, getToken())
  return data
}
export const allUsersUrlFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(allUsersUrl(), config)
  dispatch(hidePageLoad())
  return data
}
export const getBranchUserForDirectOnshoreFxn = async query => {
  let { data } = await axios.post(getBranchUserForDirectOnshoreUrl(), query, getToken())
  return data
}
export const closeAgentDealFxn = async valData => {
  let { data } = await axios.post(closeAgentDealUrl(), valData, getToken())
  return data
}
export const loadUserMobileFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(loadUserMobileUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const singleUserRightFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleUserRightUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const addAgentRightFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addAgentRightUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}
export const otherUsersFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(otherUsersUrl(), config)
  return data
}

export const addTeamAction = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addTeamUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: 'Team Added Successfully'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addSubAgentAction = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addSubAgentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: 'Sub Agent Added Successfully'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addSubAgentOtpFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addSubAgentOtpUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const uploadRegCertFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(uploadRegCertUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: 'Registration Certificate Uploaded Successfully'
    })
  }
  return data
}

export const getUserCanadaWallet = id => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getUserCanadaWallerUrl(id), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.getCurrentUser
}

export const sendAdvanceCommissionOtpFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(sendAdvanceCommissionOtpUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const advanceOtpVerificationFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(advanceOtpVerificationUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getSingleUser = valData => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(getSingleUserUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const getUser2 = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleUserRightUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const getUserFxn = (valData) => async dispatch => {
  let { data } = await axios.post(getUserApi(), valData, getToken())
  return data
}
export const addUpdateAgentRightFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addUpdateAgentRightUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const getTeamFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getTeamApi(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
