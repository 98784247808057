import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../modules/actions'
import { customAxios as axios, getToken } from '../../request'
import {
  IntakesUrl,
  addCampusIntakeInCourseUrl,
  addCourseIntakeUrl,
  campusIntakesListUrl,
  deleteIntakesUrl,
  singleIntakesUrl,
  updateCampusIntakeTimeUrl,
  updateIntakeUrl,
  canadaOnshoreManagerUrl,
  onshoreCanadaCommissionUrl,
  onshoreCanadaQueryUrl,
  addCallRequestUrl,
  requestedCallBackListUrl,
  updateRequestedCallBackUrl
} from './apis'

export const addIntake = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(IntakesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const listIntakes = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(IntakesUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const getSingleIntake = id => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(singleIntakesUrl(id), config)
  console.log('ggg', data)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const updateIntake = (valData, id) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(updateIntakeUrl(id), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const addCourseIntake = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addCourseIntakeUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const deleteIntake = id => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.delete(deleteIntakesUrl(id), getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deleted Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error in deleting intake'
    })
  }
  return data
}

export const addCampusIntakeInCourseFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    addCampusIntakeInCourseUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const campusIntakesListFxn = async filters => {
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(campusIntakesListUrl(), config)
  return data.data
}

export const updateCampusIntakeTimeFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    updateCampusIntakeTimeUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const canadaOnshoreManagerFxn = async () => {
  let { data } = await axios.post(canadaOnshoreManagerUrl(), {}, getToken())
  return data
}
export const onshoreCanadaCommissionFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  dispatch(showPageLoad())
  let { data } = await axios.get(onshoreCanadaCommissionUrl(), config)
  dispatch(hidePageLoad())

  return data.data
}

export const onshoreCanadaQueryFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    onshoreCanadaQueryUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addCallRequest = valData => async dispatch => {
  dispatch(showPageLoad())
  console.log(valData,'data')
  let { data } = await axios.post(addCallRequestUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const requestedCallBackListFxn = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(requestedCallBackListUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const updateCallBackStatusFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateRequestedCallBackUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
