import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import PageLoading from '../../../components/PageLoading'
import { departmentObj } from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import UnizHomeEnquiryList from '../../unizHomeEnquiry/view/studentEnquiryList'

const WeeklyReport = React.lazy(() => import('../weeklyReport'))
const StudentDashboard = React.lazy(() => import('./studentDashboard'))
// const StudentDashboardBackup = React.lazy(() => import('./studentDashboardBackup'))
const MasterDashboard = React.lazy(() => import('./masterDashboard'))
const UserManagerDashboard = React.lazy(() => import('./userManagerDashboard'))
const UserDashboard = React.lazy(() => import('./userDashboard'))
const FcmtDashboard = React.lazy(() => import('./fcmtDashboard'))
const InterviewerDashboard = React.lazy(() => import('./interviewerDashboard'))
const WarningDashboard = React.lazy(() => import('./warningDashboard'))
const Fcmt2Dashboard = React.lazy(() => import('./fcmt2Dashboard'))
const UploadTTDashboard = React.lazy(() => import('./UploadTTDashboard'))
const OxfordDashboard = React.lazy(() => import('./oxford2Dashboard'))
const OxfordInterviewDashboard = React.lazy(() => import('./oxfordInterviewDashboard'))
const CambieLoaDashboard = React.lazy(() => import('./cambieLoaDashboard'))
const OnShoreDashboard = React.lazy(() => import('./onShoreDashboard'))
const Counsellor = React.lazy(() => import('../../counsellorDashboard/view'))
const Enrolled = React.lazy(() => import('../../enrolledDashboard'))
const ReceptionDashboard = React.lazy(() =>
  import('../../ReceptionDashboard/view/index')
)

const OnlineCountForAgentComponent = React.lazy(() =>
  import('../components/onlineCountForAgentComponent.js')
)
// const MarketingManagerDashboard = React.lazy(() => import('../marketingManagerDashboard'))
const MarketingManagerDashboardNew = React.lazy(() => import('../../dashboardMarketing/marketingManager'))
// const MarketingDashboard = React.lazy(() => import('../marketingDashboard'))
const MarketingUserDashboardNew = React.lazy(() => import('../../dashboardMarketing/marketingUser'))

const OnShoreCanadaStudent = React.lazy(() => import('../onshoreCanada/students'))
const CourseMasterDashboard = React.lazy(() => import('../courseMaster/courseList'))
const AllAgentCommissionList = React.lazy(() => import('../../agentCommission/views/allAgentCommissionList'))
// const AdminDashboard = React.lazy(() => import('../../UserDashboards/views/adminDashboard'))
const OnshoreCambieAppList = React.lazy(() =>
  import('../../applications/cambieOnshoreApplicationList/onshoreCambieAppList')
)
const CambieAgentsComponent = React.lazy(() =>
  import('../../CambieApp/views/cambieAgents')
)
const InstituteEventsList = React.lazy(() =>
  import('../../institutes/instituteEvents/InstituteEventsList')
)
const EventSlots = React.lazy(() =>
  import('../../institutes/eventSlots')
)

const CounsellingEventCalendar = React.lazy(() =>
  import('../../counselling/eventCalendar')
)
const CounsellorDashboard = React.lazy(() =>
  import('../../counselling/counsellorDashboard')
)
const GicDashboard = React.lazy(() =>
  import('../../dashboard/gic/gicList')
)
const CornerStoneOfferApplicationList = React.lazy(() => import('../../DirectColleges/cornerStoneCollege/offerApplicationList.js'))
const NationalCollegeApplicationList = React.lazy(() => import('../../DirectColleges/nationalCollege/offerApplicationList.js'))

const GicRequestCallback = React.lazy(() =>
  import('../../dashboard/list/gicRequestCallback')
)

const OnShoreApplicationListForCommission = React.lazy(() =>
  import('../../applications/onShoreApplicationForCommission/view/onShoreApplicationListForCommission')
)


const Dashboard = props => {
  let dispatch = useDispatch()
  let user = props.currentUser
  let {
    approveFCMTLoa,
    approveCambieLoa,
    showOnShoreAustralia,
    approveOxfordDraft,
    oxfordInterviewDepartment,
    enableUploadTt,
    showApprovedCommission
  } = user
  let userList = [
    'master',
    'university',
    'student',
    'branchUser',
    'reception',
    'marketingManager',
    'onshoreCanada',
    'courseMaster',
    'cambieMarketingManager',
    'cambieBranchUser',
    'institute',
    'eventManager',
    'counsellingManager',
    'unizhomeManager',
    'gicManager',
    'cornerStoneManager',
    'nationalCollegeManager',
    'gicMarketing',
    'onshoreAccountant'

  ]

  let counsellingFilter = (
    <>
      {user.counsellorType == 'Counsellor' ? (
        <Suspense fallback={<PageLoading />}>
          <Counsellor />
        </Suspense>
      ) : (
        <Suspense fallback={<PageLoading />}>
          <Enrolled />
        </Suspense>
      )}
    </>
  )

  let warningFilter = (
    <>
      {user.userType == 'branchUser' ? (
        user.department == departmentObj.warningDepartment ? ( // warning department dashboard
          <Suspense fallback={<PageLoading />}>
            <WarningDashboard />
          </Suspense>
        ) : user.department == departmentObj.counselling ? ( // counselling department dashboard
          <Suspense fallback={<PageLoading />}>
            <CounsellorDashboard />
          </Suspense>
        ) : user.department == departmentObj.marketing ? ( // marketing department dashboard
          <Suspense fallback={<PageLoading />}>
            <MarketingUserDashboardNew />
          </Suspense>
        ) : user.department == departmentObj.accountDepartment ? ( // accounting department dashboard
          <Suspense fallback={<PageLoading />}>
            {showApprovedCommission ? <AllAgentCommissionList /> : <UserDashboard />}
          </Suspense>
        ) : (// default dashboard
          <Suspense fallback={<PageLoading />}>
            <UserDashboard />
          </Suspense>
        )
      ) : null}
    </>
  )

  let interviewFilter = (
    <>
      {user.userType == 'branchUser' ? (
        enableUploadTt ? (
          <Suspense fallback={<PageLoading />}>
            <UploadTTDashboard />
          </Suspense>
        ) : approveFCMTLoa ? (
          <Suspense fallback={<PageLoading />}>
            <Fcmt2Dashboard user={user} />
          </Suspense>
        ) : approveCambieLoa ? (
          <Suspense fallback={<PageLoading />}>
            <CambieLoaDashboard />
          </Suspense>
        ) : showOnShoreAustralia ? (
          <Suspense fallback={<PageLoading />}>
            <OnShoreDashboard />
          </Suspense>
        ) : approveOxfordDraft ? (
          <Suspense fallback={<PageLoading />}>
            <OxfordDashboard />
          </Suspense>
        ) : oxfordInterviewDepartment ? (
          <Suspense fallback={<PageLoading />}>
            <OxfordInterviewDashboard />
          </Suspense>
        ) : user.department == departmentObj.interviewer ? (
          <Suspense fallback={<PageLoading />}>
            <InterviewerDashboard />
          </Suspense>
        ) : (
          warningFilter
        )
      ) : null}
    </>
  )

  let dashboardFilter = (
    <div>

      {user.userType == 'student' && (
        <Suspense fallback={<PageLoading />}>
          <StudentDashboard country={user.directRegistration ? 'Canada Onshore' : 'Canada'} />
          {/* <StudentDashboardBackup/> */}
        </Suspense>
      )}
      {user.userType == 'master' && (
        <Suspense fallback={<PageLoading />}>
          <MasterDashboard />
        </Suspense>
      )}
      {user.userType == 'courseMaster' && (
        <Suspense fallback={<PageLoading />}>
          <CourseMasterDashboard />
        </Suspense>
      )}
      {/*{user.userType == 'userManager' && <Suspense fallback={<PageLoading />}><UserManagerDashboard /></Suspense>}*/}
      {user.userType == 'university' && (
        <Suspense fallback={<PageLoading />}>
          <FcmtDashboard />
        </Suspense>
      )}
      {interviewFilter}
      {user.userType == 'reception' && (
        <Suspense fallback={<PageLoading />}>
          <ReceptionDashboard />
        </Suspense>
      )}
      {/* {user.userType == 'marketingManager' && user.email == "varun@unizportal.com" ? (
                <Suspense fallback={<PageLoading/>}>
                    <WeeklyReport/>
                </Suspense>
            ) : null}*/}
      {(user.userType == 'marketingManager') && (
        <Suspense fallback={<PageLoading />}>
          {/*{showNewDashboard ? <MarketingManagerDashboardNew/> : <MarketingManagerDashboard/>}*/}
          <MarketingManagerDashboardNew />
        </Suspense>
      )}
      {(user.userType == 'onshoreCanada') && (
        <Suspense fallback={<PageLoading />}>
          <OnShoreCanadaStudent />
        </Suspense>
      )}
      {(user.userType == 'cambieMarketingManager') && (
        <Suspense fallback={<PageLoading />}>
          <OnshoreCambieAppList />
        </Suspense>
      )}
      {(user.userType == 'cambieBranchUser') && (
        <Suspense fallback={<PageLoading />}>
          <CambieAgentsComponent />
        </Suspense>
      )}
      {(user.userType == 'institute') && (
        <Suspense fallback={<PageLoading />}>
          <InstituteEventsList />
        </Suspense>
      )}
      {(user.userType == 'eventManager') && (
        <Suspense fallback={<PageLoading />}>
          <EventSlots />
        </Suspense>
      )}
      {(user.userType == 'counsellingManager') && (
        <Suspense fallback={<PageLoading />}>
          <CounsellingEventCalendar />
        </Suspense>
      )}
      {(user.userType == 'unizhomeManager') && (
        <Suspense fallback={<PageLoading />}>
          <UnizHomeEnquiryList />
        </Suspense>
      )}

      {(user.userType == 'gicManager') && (
        <Suspense fallback={<PageLoading />}>
          <GicDashboard />
        </Suspense>
      )}

      {(user.userType == 'cornerStoneManager') && (
        <Suspense fallback={<PageLoading />}>
          <CornerStoneOfferApplicationList />
        </Suspense>
      )}
      {(user.userType == 'gicMarketing') && (
        <Suspense fallback={<PageLoading />}>
          <GicRequestCallback />
        </Suspense>
      )}
      {(user.userType == 'nationalCollegeManager') && (
        <Suspense fallback={<PageLoading />}>
          <NationalCollegeApplicationList />
        </Suspense>
      )}
      {(user.userType == 'onshoreAccountant') && (
        <Suspense fallback={<PageLoading />}>
          <OnShoreApplicationListForCommission />
        </Suspense>
      )}

      {userList.includes(user.userType) ? null : (
        <Suspense fallback={<PageLoading />}>
          <UserDashboard />
        </Suspense>
      )}
      {/* adding codes */}
    </div>
  )

  return <>
    {user && (user.userType !== 'admin' && user.userType !== 'student') ? (
      <Suspense fallback={<></>}>
        <div className={'row'}>
          <div className={'col-md-12'}>
            <OnlineCountForAgentComponent dispatch={dispatch} />
          </div>
        </div>
      </Suspense>
    ) : null}
    {dashboardFilter}
  </>
}

const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
