import React, { useEffect, useState } from 'react'
import {
  Drawer,
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  notification,
  Card,
  Modal,
  Popconfirm,
  Empty
} from 'antd'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
  addCounsellingByCounsellorFxn, getSlotByCounsellorFxn,
  getStudentCounsellingFxn,
  changeCounsellingDateFxn,
  updateCounsellingSlotFxn
} from './actions/counselling'
import { RowTable } from '../../components/_utils/RowTable'
import Calendar from 'rc-calendar'
import UpdateCounsellingComponent from './components/updateCounselling'
import { AllowComponentUserWise } from '../WebComponent/allowComponentRightsWise'
import { departmentObj } from '../../components/_utils/appUtils'
import FollowupComponent from './components/followup'

const { Option } = Select

const showActionRight = (user) => {
  let { userType, department } = user
  if ((userType == 'admin' || userType == 'counsellingManager') || (department == departmentObj.counselling)) {
    return true
  } else {
    return false
  }
}

const StudentCounsellingDrawer = (props) => {
  let { user } = props
  const [studentDetails, setStudentDetails] = useState([])
  const [selectedDate, setSelectedDate] = useState(moment())
  const [slotList, setSlotList] = useState([])
  const [disabledSlots, setDisabledSlots] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showChangeDateDrawer, setShowChangeDateDrawer] = useState({
    visible: false,
    counsellingId: null
  })
  const [showUpdateDrawer, setShowUpdateDrawer] = useState({
    visible: false,
    counsellingId: null,
    type: null
  })

  const [addFollowUpState, setAddFollowUpState] = useState({
    visible: false,
    counsellingId: ''
  })

  const [state, setState] = useState({
    visible: false,
    counsellingData: {}
  })

  const dispatch = useDispatch()


  const { form, onClose, visible, studentName, studentId } = props

  const handleReschedule = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleSubmitMeeting = async (counsellingId) => {
    let resp = await dispatch(updateCounsellingSlotFxn({
      counsellingId,
      status: 'Approved'
    }))
    if (resp && resp.success) {
      onClose()
    }
  }

  const events = {
    openDateDrawer: async (counsellingId) => {
      setShowChangeDateDrawer({
        visible: true,
        counsellingId: counsellingId

      })
    },
    closeDateDrawer: async () => {
      setShowChangeDateDrawer({
        visible: false,
        counsellingId: null
      })
      await apiRequest()
    },
    openUpdateDrawer: async (counsellingId, type) => {
      setShowUpdateDrawer({
        visible: true,
        counsellingId: counsellingId,
        type: type

      })
    },
    closeUpdateDrawer: async () => {
      setShowUpdateDrawer({
        visible: false,
        counsellingId: null,
        type: null
      })
      await apiRequest()
    },
    showUpdateDrawer: (data) => {
      setState({
        visible: true,
        counsellingData: data
      })
    },
    hideUpdateDrawer: () => {
      setState({
        visible: false,
        counsellingData: {}
      })
    },

    openAddFollowupDrawer: (counsellingId) => {
      setAddFollowUpState({
        visible: true,
        counsellingId: counsellingId
      })
    },
    closeAddFollowupDrawer: async () => {
      setAddFollowUpState({
        visible: false,
        counsellingId: ''
      })
    }
  }


  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = { month: 'long', day: 'numeric', year: 'numeric' }
    return date.toLocaleDateString('en-US', options)
  }

  const apiRequest = async () => {
    let params = {
      count: 100,
      results: 100,
      studentId: studentId
    }
    if (user.department == departmentObj.counselling) {
      params.counsellorId = user._id
    }

    let { data } = await dispatch(getStudentCounsellingFxn({ ...params }))
    setStudentDetails(data)
  }

  useEffect(() => {
    apiRequest()
  }, [])


  const fetchSlots = async (date) => {
    if (user && user._id) {
      const params = {
        count: 100,
        results: 100,
        counsellorId: user._id,
        date: date.format('YYYY-MM-DD')
      }

      try {
        const response = await dispatch(getSlotByCounsellorFxn(params))
        if (response && response.data) {
          const availableSlots = response.data.filter(slot => !slot.disabled)
          setDisabledSlots(availableSlots)
          setSlotList(response.data)
        }
      } catch (error) {
        console.error('Error fetching slots:', error)
      }
    }
  }
  useEffect(() => {
    if (selectedDate) {
      fetchSlots(selectedDate)
    }
  }, [selectedDate])

  const handleSubmitReschedule = async (slot) => {

    const valData = {
      counsellorId: user._id,
      studentId: studentDetails[0] ? studentDetails[0].studentId._id : studentId,
      date: selectedDate,
      slot: slot,
      status: 'Approved'
      // countryId : user.countryId
    }

    try {

      const resp = await dispatch(addCounsellingByCounsellorFxn(valData))
      if (resp.success) {
        notification.success({ message: 'Counselling Booked Successfully' })
        setShowModal(false)
        apiRequest()
      } else if (resp.error) {
        notification.warning({ message: 'You have already booked a counselling' })
        setShowModal(false)
      }
    } catch (error) {
      console.error('Error rescheduling counselling:', error)
    }
  }

  const disabledDate = (current) => {
    return current && current < moment().startOf('day')
  }

  const showUpdateBtn = (item) => {
    let currentDate = moment().endOf('day')
    if (item.status == 'Approved' && moment(item.date) < currentDate) {
      return true
    } else {
      return false
    }
  }

  function getStatusClass(status) {
    switch (status) {
      case 'Reject':
        return 'label-danger'
      case 'Not Done':
        return 'label-danger'
      case 'Pending':
        return 'label-success'
      case 'Approved':
        return 'label-success-green'
      case 'Done':
        return 'label-success-green'
      default:
        return ''
    }
  }

  return (

    <Drawer
      visible={visible}
      width={'80%'}
      placement='right'
      onClose={onClose}
      title={`Add Counselling - ${studentName}`}>
      <div className='form-box'>
        <div className='card unizportal counselling-details'>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h6>Student Details:</h6>
              <AllowComponentUserWise rightUserType={['admin', 'branchUser', 'counsellingManager']}>
                <Button type='primary' onClick={handleReschedule} style={{ float: 'right' }}>
                  Add New Counselling
                </Button>
              </AllowComponentUserWise>
            </div>
          {studentDetails && studentDetails.length ? <>
            <div>
              <Row gutter={16} className='card-container-slot'>
                {studentDetails.map((item, index) => {
                  return (
                    <Col span={24} key={index}>
                      <Card className='card-slot'>
                        <Row gutter={16}>
                          <Col span={4}>
                            <p className='time-container'>{item.slot}</p>
                            <p className='date-container'>{formatDate(item.date)}</p>
                            <p className='date-container mt10' style={{ color: 'black' }}>(Apply on
                              : {formatDate(item.createdAt)})</p>
                          </Col>
                          <Col span={1}></Col>
                          <Col span={4}>
                            <h6>{item.studentName}</h6>
                            <hr />
                            <p style={{ fontWeight: 'normal' }}>{item.studentId.email}</p>
                            <p>{item.studentId.mobile}</p>
                            <p>{item.studentId.address}</p>
                          </Col>
                          <Col span={4} style={{ marginTop: '30px', textAlign: 'right' }}>
                            <p>{item.countryId && item.countryId.countryName}</p>
                            <p>{item.marketingUser}</p>
                          </Col>
                          <Col span={1}></Col>
                          <Col span={8}>
                            {item && item.instituteId && (
                              <>
                                <h6>{item.instituteId.companyName}</h6>
                                <hr />
                                <p>Email: <span style={{ fontWeight: 'normal' }}>{item.instituteId.email}</span>
                                </p>
                                <p><span>Mobile: </span>{item.instituteId.mobile}</p>
                              </>
                            )}

                          </Col>
                          <Col span={2} className={'mt5 alignRight'}>
                            {item.status ? (
                              <label className={`label ${getStatusClass(item.status)}`}>
                                {item.status}
                              </label>
                            ) : null}
                          </Col>
                        </Row>

                        {showActionRight(user) ?
                          <>

                            {item.status == 'Pending' ? <>
                              <RowTable value={(
                                <div className='btn-counselling-group'>

                                  <Popconfirm title={'Are you sure, you want to Approved Counselling?'}
                                              onConfirm={() => handleSubmitMeeting(item._id)}>
                                    <a className='btn btn-success-green round-bt sm'>
                                      Approve
                                    </a>
                                  </Popconfirm>

                                  <a className='btn btn-danger round-bt sm'
                                     onClick={() => events.openUpdateDrawer(item._id, 'Reject')}>
                                    Cancel
                                  </a>
                                  <a className='btn btn-warning round-bt sm'
                                     onClick={() => events.openDateDrawer(item._id)}>
                                    Change Date
                                  </a>

                                  <a onClick={() => events.openAddFollowupDrawer(item._id)}
                                     className='btn btn-info round-bt sm'>
                                    Add Followup
                                  </a>
                                </div>
                              )} />
                            </> : null}

                            {showUpdateBtn(item) ? <>
                              <RowTable value={(
                                <div className='btn-counselling-group'>
                                  <a className={'btn btn-success round-bt sm float-right'}
                                     onClick={() => events.showUpdateDrawer(item)}
                                  >Update Meeting Status</a>
                                </div>
                              )} />
                            </> : null}
                          </> : null}
                      </Card>
                    </Col>
                  )
                })}

              </Row>
            </div>
          </> : <Empty />}


        </div>
      </div>

      <>
        {showModal ? (
            <Modal
                visible={showModal}
                onCancel={handleCloseModal}
                title='Reschedule Counselling'
                footer={null}
                style={{ minWidth: '1000px', maxHeight: '80vh' }}
            >
              <div className='calendar-container-manager'>
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    <div className='calendar-wrapper'>
                      <Calendar
                          onChange={handleDateChange}
                          value={selectedDate}
                          className='custom-calendar-1'
                          disabledDate={disabledDate}
                      />
                    </div>
                  </div>
                  <div className={'col-md-6'}>
                    <div className='event-cardsNew'>
                      <div className='selected-date'>{selectedDate.format('dddd, Do MMMM YYYY')}</div>
                      <div className='event-card-container'>
                        {slotList.map((slot, index) => {
                          const isSlotDisabled = slot.disabled || (slot.data && (slot.data.status === 'Approved' || slot.data.status === 'Pending'));

                          return (
                              <Popconfirm
                                  key={index}
                                  title={`Are you sure you want to add counselling for slot "${slot.slot}"?`}
                                  onConfirm={() => handleSubmitReschedule(slot.slot)}
                                  okText='Yes'
                                  cancelText='No'
                                  disabled={isSlotDisabled}
                              >
                                <div
                                    className={`event-card ${isSlotDisabled ? 'disabled' : ''}`}
                                    style={{
                                      cursor: isSlotDisabled ? 'not-allowed' : 'pointer',
                                      borderColor: isSlotDisabled ? '#aca7a7' : '#61d261',
                                      color: isSlotDisabled ? '#aca7a7' : '#61d261'
                                    }}
                                >
                                  <h6>{slot.slot}</h6>
                                </div>
                              </Popconfirm>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
        ) : null}

        {showChangeDateDrawer.visible && (
            <ChangeCounsellingDateDrawer
                visible={showChangeDateDrawer.visible}
                onClose={events.closeDateDrawer}
                disabledDate={disabledDate}
                slotList={slotList}
                disabledSlots={disabledSlots}
                form={form}
                counsellingId={showChangeDateDrawer.counsellingId}
            />
        )}
        {showUpdateDrawer.visible && (
            <UpdateCounsellingDrawer
                visible={showUpdateDrawer.visible}
                onClose={events.closeUpdateDrawer}
                counsellingId={showUpdateDrawer.counsellingId}
                type={showUpdateDrawer.type}
                form={form}
            />
        )}

        {state.visible ? (
            <UpdateCounsellingComponent
                {...state}
                onClose={events.hideUpdateDrawer}
                onSubmit={() => {
                  events.hideUpdateDrawer();
                  apiRequest();
                }}
            />
        ) : null}
      </>

      {addFollowUpState.visible ? (
        <FollowupComponent
          {...addFollowUpState}
          onClose={() => {
            events.closeAddFollowupDrawer()
          }}
        />
      ) : null}


    </Drawer>


  )
}
const ChangeCounsellingDateDrawer = (props) => {
  const { visible, onClose, slotList, disabledSlots, disabledDate, form: { getFieldDecorator }, counsellingId } = props
  const [selectedDate, setSelectedDate] = useState(moment())
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [eligibleSlots, setEligibleSlots] = useState([])
  const dispatch = useDispatch()
  const handleSlotChange = (value) => {
    setSelectedSlot(value)
  }

  const handleDateChange = date => {
    setSelectedDate(date)
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.counsellingId = counsellingId
        valData.slot = selectedSlot
        let resp = await dispatch(changeCounsellingDateFxn(valData))
        if (resp) {
          notification.success({
            message: 'Date Changed Successfully'
          })
        }
        form.resetFields()
        onClose()
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  const fetchSlots = async (date) => {
    const params = {
      count: 100,
      results: 100,
      date: date.format('YYYY-MM-DD')
    }

    try {
      const response = await dispatch(getSlotByCounsellorFxn(params))
      if (response && response.data) {
        const availableSlots = response.data.filter(slot => !slot.disabled)
        setEligibleSlots(availableSlots)
      }
    } catch (error) {
      console.error('Error fetching slots:', error)
    }
  }


  useEffect(() => {
    const filteredSlots = slotList.filter(slot => !slot.disabled)
    setEligibleSlots(filteredSlots)
  }, [slotList, selectedDate])

  useEffect(() => {
    fetchSlots(selectedDate)
  }, [selectedDate])

  return (
    <Drawer
      visible={visible}
      width={'45%'}
      placement='right'
      onClose={onClose}
      title={'Change Counselling Date'}
    >
      <div className='form-box'>
        <div className='card unizportal counselling-details'>
          <div className='card-body'>
            <h6>Change Counselling Date</h6>
            <Form onSubmit={handleSubmit} className='vertical-form'>
              <Row gutter={24}>
                <Col span={24} className={'mb10'}>
                  <Form.Item label='Date'>
                    {getFieldDecorator('date', {
                      initialValue: selectedDate,
                      rules: [{ required: true, message: 'Please select a date!' }]
                    })(
                      <DatePicker
                        onChange={handleDateChange}
                        className='form-control'
                        disabledDate={disabledDate}
                        format='YYYY-MM-DD'
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24} className={'mb10'}>
                  <Form.Item label='Slot'>
                    <Select
                      value={selectedSlot}
                      onChange={handleSlotChange}
                      placeholder='Select a slot'
                    >
                      {eligibleSlots.map(slot => (
                        <Option value={slot.slot}>
                          {slot.slot}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={18}>
                  <Form.Item>
                    <Button type='primary' htmlType='submit' className='btn'>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </Drawer>
  )

}
const UpdateCounsellingDrawer = (props) => {
  const { visible, onClose, counsellingId, type, form: { getFieldDecorator } } = props
  const [cancelReason, setCancelReason] = useState('')
  const [meetingDetails, setMeetingDetails] = useState({
    meetingLink: '',
    meetingPassword: '',
    meetingId: ''
  })
  const dispatch = useDispatch()
  const handleSubmitMeeting = async () => {
    if (
      meetingDetails.meetingLink &&
      meetingDetails.meetingPassword &&
      meetingDetails.meetingId
    ) {
      let resp = await dispatch(updateCounsellingSlotFxn({
        counsellingId,
        status: 'Approved',
        ...meetingDetails
      }))
      notification.success({
        message: 'Counselling Approved Successfully'
      })
      setMeetingDetails({
        meetingLink: '',
        meetingPassword: '',
        meetingId: ''
      })
      onClose()
    } else {
      notification.warning({
        message: 'Please fill required fields'
      })
    }
  }
  const handleCancelSubmit = async () => {
    if (cancelReason) {
      let resp = await dispatch(updateCounsellingSlotFxn({ counsellingId, status: 'Reject', reason: cancelReason }))
      if (resp) {
        notification.success({
          message: 'Counselling Rejected Successfully'
        })
      }
      setCancelReason('')
      onClose()
    } else {
      notification.warning({
        message: 'Reason is required for cancelling counselling'
      })
    }
  }
  return (
    <Drawer
      visible={visible}
      width={'45%'}
      placement='right'
      onClose={onClose}
      title={'Update Counselling'}
    >
      <div className='form-box'>
        <div className='card unizportal counselling-details'>
          <div className='card-body'>
            <h6>Update Counselling</h6>
            {type == 'Approved' && (
              <Form className='vertical-form'>
                <Row gutter={24}>
                  <Col span={24} className={'mb10'}>
                    <Form.Item label='Meeting Link' required>
                      <Input
                        value={meetingDetails.meetingLink}
                        className='form-control'
                        onChange={e => setMeetingDetails({ ...meetingDetails, meetingLink: e.target.value })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} className={'mb10'}>
                    <Form.Item label='Meeting Password' required>
                      <Input
                        value={meetingDetails.meetingPassword}
                        className='form-control'

                        onChange={e => setMeetingDetails({ ...meetingDetails, meetingPassword: e.target.value })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} className={'mb10'}>
                    <Form.Item label='Meeting ID' required>
                      <Input
                        value={meetingDetails.meetingId}
                        className='form-control'

                        onChange={e => setMeetingDetails({ ...meetingDetails, meetingId: e.target.value })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={18}>
                    <Form.Item>
                      <Button type='primary' htmlType='submit' className='btn' onClick={handleSubmitMeeting}>
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}

            {type == 'Reject' && (
              <Form className='vertical-form'>
                <Row gutter={24}>
                  <Col span={24} className={'mb10'}>
                    <Form.Item label='Reason'>
                      {getFieldDecorator('cancelReason', {
                        initialValue: cancelReason,
                        rules: [{ required: true, message: 'Please input the reason for cancellation!' }]
                      })(<Input.TextArea rows={4} value={cancelReason}
                                         onChange={e => setCancelReason(e.target.value)} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={18}>
                    <Form.Item>
                      <Button type='primary' htmlType='submit' className='btn' onClick={handleCancelSubmit}>
                        Cancel
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  )
}
const StudentCounselling = Form.create()(StudentCounsellingDrawer)
export default StudentCounselling
