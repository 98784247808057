import React from 'react'
import styles from './login.less'
import {
  Form, Icon, Input, Button, Checkbox, notification, Col, Row
} from 'antd'

import Request from '../../request'
import { showLoader, hideLoader, showPageLoad, hidePageLoad } from '../../modules/actions'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import lodash from 'lodash'
import { RegisterButton } from './registerButton'
import { Socket } from '../../socket'
import { showUpdatePasswordFxn } from '../dashboard/actions/dashboardAction'
import moment from 'moment'

class NormalLoginForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      isUser: false,
      dontAskOtp: false,
      last2Digit: '',
      email: '',
      ids: localStorage.getItem('dontAskOtpForIds') ? JSON.parse(localStorage.getItem('dontAskOtpForIds')) : []
    }
    props.dispatch(hidePageLoad())
    props.dispatch(hideLoader())
    this.checkIsLoggedIn()
  }

  checkIsLoggedIn() {
    let resp = localStorage.getItem('token')
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    if (resp && user && user._id) {
      let { dispatch } = this.props
      dispatch(push('/dashboard'))
    }
  }

  openForgotPassword = () => {
    let { dispatch } = this.props
    dispatch(push('/forgot-password'))
  }


  handleSubmit = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        dispatch(showPageLoad())
        let x = await Request.loginSendOtp(values)
        dispatch(hidePageLoad())
        if (!x.error) {

          this.setState({
            isUser: true,
            email: values.email,
            last2Digit: x.last2Digit
          })
        } else {
          notification.error({
            message: 'Invalid Login',
            description: x.message
          })

        }
      }
    })
  }

  handleOtpSubmit = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        dispatch(showPageLoad())
        let x = await Request.loginWithOtp({ ...values, email: this.state.email })
        if (!x.error) {
          localStorage.setItem('dontAskOtpForIds', JSON.stringify(this.state.ids))
          localStorage.setItem('token', x.token)
          localStorage.setItem('user', JSON.stringify(x.user))
          /* if (x.user && x.user._id) {
             dispatch({
               type: 'JOIN_SOCKET',
               value: true,
               userId: x.user._id
             })
           }*/
          dispatch({
            type: 'SET_CURRENT_USER',
            user: x.user
          })

          dispatch({
            type: 'REFRESH_MENU',
            value: moment()
          })
          setTimeout(() => {
            dispatch({
              type: 'START_LOAD_CURRENT_USER'
            })
          }, 300)
          if (!Socket.connected) {
            Socket.connect()
          }

          dispatch(hidePageLoad())
          setTimeout(() => {
            this.checkFirstTimeLogin(x.user)
          }, 1000)

          /* const params = new URLSearchParams(window.location.search);
           let returnUrl = parseInt(params.get("return"))
           if (returnUrl) {
               window.location.href = `/${returnUrl}`
           }*/

        } else {
          notification.error({
            message: 'Invalid Login',
            description: x.message
          })
          dispatch(hidePageLoad())
        }
      }
    })
  }


  checkFirstTimeLogin = (user) => {
    const { dispatch } = this.props
    showUpdatePasswordFxn().then((resp) => {
      if (resp && resp.showChangePassword) {
        // dispatch(push('/change-password'))
        window.location.href = '/update-password'
      } else {
        const params = new URLSearchParams(window.location.search)
        let returnUrl = params.get('return')
        if (returnUrl) {
          window.location.href = `/student-accommodation/${returnUrl}`
          //student-accommodation
        } else {
          window.location.href = '/dashboard'
        }
        // window.location.href = '/dashboard'
      }
    })
  }

  async resendOtp() {
    const { dispatch } = this.props
    dispatch(showPageLoad())
    let x = await Request.resendOtp({ email: this.state.email })
    if (!x.error) {
      notification.success({
        message: x.message
      })
      dispatch(hidePageLoad())

    } else {
      notification.error({
        message: x.message
      })
      dispatch(hidePageLoad())

    }
  }

  chooseIds = (dontAskOtp) => {
    let { email } = this.state
    let ids = lodash.clone(this.state.ids)
    if (dontAskOtp) {
      if (!ids) {
        ids = []
      }
      let findId = lodash.find(ids, (item) => {
        if (item) {
          return item == email.toLowerCase()
        }
      })
      if (!findId) {
        ids.push(email.toLowerCase())
        this.setState({ ids: ids })
      }
    } else {
      let findId = lodash.reject(ids, (item) => {
        return item == email
      })
      this.setState({ ids: findId })
    }
  }

  submitFinal = (e) => {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { ids } = this.state
    let email = getFieldValue('email')
    if (email && ids && ids.length) {
      let findId = lodash.find(ids, (item) => {
        if (item) {
          return item == email.toLowerCase()
        }
      })
      if (findId) {
        this.handleSubmit(e)
      } else {
        this.handleSubmit(e)
      }
    } else {
      this.handleSubmit(e)
    }
    // this.handleSubmit(e)

  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { loading, dispatch } = this.props
    const { isUser, dontAskOtp, dontAskOtpForIds } = this.state
    const loginFormField = [
      {
        key: 'email',
        label: 'Email address',
        required: true,
        placeholder: 'Email address',
        type: 'email',
        icon: (<LazyLoadImage src='dist/img/user-login.png' alt='' />)
      },
      {
        key: 'password',
        label: 'Email password',
        required: true,
        placeholder: 'Email password',
        type: 'password',
        icon: (<LazyLoadImage src='dist/img/key.png' alt='' />)
      }
    ]
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }
    return (
      <React.Fragment>
        <div id={'loginForm'} className={'hold-transition login-main'}>
          <header className=' w-100 navbar-fix'>

            <div className='container'>
              <div className='d-flex flex-column flex-md-row align-items-center pt-5'>
                <h5 className='my-0 mr-md-auto font-weight-normal'>
                  <a onClick={() => dispatch(push('/home'))}>
                    <LazyLoadImage src='dist/img/AdminLTELogo.png' alt='' />
                  </a>
                </h5>
                <nav className='my-2 my-md-0 d-flex align-items-center'>

                  <a className='p-2 text-dark'>Login</a>
                  {/*<a className="p-2 text-dark" href={'/agent-registration'}>Register</a>*/}
                  <RegisterButton customClass={'lite'} />
                </nav>
              </div>
            </div>

          </header>
          <div className='login-form w-100'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-4 mr-auto'>
                  <div className='login-box w-100'>
                    {!isUser ? <div>
                      <h5>Login to your account</h5>
                      <Form onSubmit={this.submitFinal} autocomplete='off'>
                        <div className='form-group'>
                          <Form.Item>
                            {getFieldDecorator('email', {
                              rules: [{
                                required: true,
                                message: 'Please input your email address!'
                              }]
                            })(
                              <Input placeholder='Email address' type={'email'}
                                     id={'new-email'} name={'new-email'}
                                     autoComplete='new-email' />
                            )}
                          </Form.Item>
                          <LazyLoadImage src='dist/img/user-login.png' alt='' />
                        </div>

                        <div className='form-group'>
                          <Form.Item>
                            {getFieldDecorator('password', {
                              rules: [{
                                required: true,
                                message: 'Please input your Password!'
                              }]
                            })(
                              <Input placeholder='Password' type='password'
                                     id={'new-password'} name={'new-password'}
                                     autoComplete='new-password' />
                            )}
                          </Form.Item>
                          <LazyLoadImage src='dist/img/key.png' alt='' />
                        </div>

                        <div className='d-flex align-items-center'>
                          <a onClick={() => this.openForgotPassword()}>forgot password?</a>
                          <Button className='btn ml-auto' type='button' loading={loading}
                                  htmlType='submit'>login</Button>
                        </div>

                      </Form>
                    </div> : null}

                    {isUser && <div>
                      <h6> Welcome, {this.state.email}</h6>
                      {this.state.last2Digit ?
                        <h6 style={{ textAlign: 'center', marginBottom: 10 }}> A text message with
                          a
                          4-digit verification
                          code has been
                          sent to your phone <br /> number ending in **{this.state.last2Digit}.
                        </h6> :
                        <h6 style={{ textAlign: 'center', marginBottom: 10 }}>
                          A text message with a 4-digit verification code has been
                          sent to your email.
                        </h6>}
                      <Form onSubmit={this.handleOtpSubmit}>
                        <div>
                          <div className='form-group'>

                            <Form.Item>
                              {getFieldDecorator('otp', {
                                rules: [{
                                  required: true,
                                  message: 'Please input your 4 digit otp!'
                                }]
                              })(
                                <Input
                                  type='password'
                                  minLength={4} maxLength={4}
                                  autoComplete='otp'
                                  placeholder='OTP – Check your mobile phone'
                                />
                              )}
                            </Form.Item>

                          </div>
                          {/* <div className="form-group lightFont">
                                                        <Checkbox value={dontAskOtp} className={'authCheckbox'}
                                                                  onChange={(e) => {
                                                                      this.setState({dontAskOtp: !dontAskOtp}, () => {
                                                                          this.chooseIds(this.state.dontAskOtp)
                                                                      })
                                                                  }}>Don't ask me again on this computer.</Checkbox>
                                                    </div>*/}
                          <div className='d-flex align-items-center'>
                            <Button className='btn' type='button'
                                    htmlType='submit'>Submit</Button>
                          </div>
                        </div>
                      </Form>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm)


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalLoginForm)
