import { apiUrl2 } from '../../../settings'

export const addLatestUpdateUrl = () => {
  return apiUrl2 + '/api/latestUpdates'
}

export const getAllLatestUpdatesUrl = () => {
  return apiUrl2 + '/api/latestUpdates'
}

export const getLatestUpdateCountUrl = () => {
  return apiUrl2 + '/api/latest-update-count'
}

export const resetLatestUpdateCountUrl = () => {
  return apiUrl2 + '/api/reset-latest-update-count'
}
