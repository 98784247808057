import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import { fcmtAgentListUrl, updateFcmtAgentStatusUrl } from '../apis'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

export const fcmtAgentListFxn = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(fcmtAgentListUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data

}

export const updateFcmtAgentStatusFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateFcmtAgentStatusUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data

}
