import { apiUrl } from '../../../settings'

export const enrollmentLetterUrl = () => {
  return apiUrl + '/api-cambie-onshore/createEnrollmentLetter'
}
export const paymentReceiptUrl = () => {
  return apiUrl + '/api-cambie-onshore/createPaymentReceipt'
}

export const createLoaLetterUrl = () => {
  return apiUrl + '/api-cambie-onshore/createLoaLetter'
}

export const updateStudentUrl = () => {
  return apiUrl + '/updateStudent'
}
