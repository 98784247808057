import React, { useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { useDispatch } from 'react-redux'
import { useRef } from 'react'
import { DefaultTablePagination, displayDate } from '../../../components/_utils/appUtils'
import { fcmtAgentListFxn } from '../actions'
import UpdateStatusDrawer from './updateStatusDrawer'
import _ from 'lodash'

let statusList = [
    { name: 'Pending', value: 'Pending' },
    { name: 'Approved', value: 'Approved' },
    { name: 'Rejected', value: 'Rejected' }
]
const FcmtAgentList = (props) => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    const dispatch = useDispatch()
    const tableRef = useRef()
    let [total, setTotal] = useState(0)
    let [drawerState, setDrawerState] = useState({
        visible: false,
        agentId: ''
    })
    const events = {
        reload: () => {
            tableRef.current.reload()
        },
        showDrawer: (agentId) => {
            setDrawerState({
                visible: true,
                agentId: agentId
            })
        },
        hideDrawer: () => {
            setDrawerState({
                visible: false,
                agentId: ''
            })
        }
    }

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await dispatch(fcmtAgentListFxn({
                ...params,
                regExFilters: ['name', 'companyName', 'countryName', 'stateName', 'address', 'pincode', 'cityName']
            }))
            setTotal(resp.total)
            resolve(resp)
        })
    }


    const columns = [
        {
            title: 'S.no',
            dataIndex: 'sno',
            width: 50,
            key: 'sno',
            render: (item, record, index) => {
                return <div style={{}}>{index + 1}</div>
            }
        },
        {
            title: 'name',
            dataIndex: 'name',
            width: 100,
            key: 'name',
            searchTextName: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: 150,
            key: 'email',
            searchTextName: 'email'
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobile',
            width: 100,
            key: 'mobile',
            searchTextName: 'mobile'
        },
        {
            title: 'Company',
            dataIndex: 'companyName',
            width: 150,
            key: 'companyName',
            searchTextName: 'companyName'
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            // searchTextName: 'address',
            width: 200,
            render: (item, record) => {
                let { cityName, stateName, countryName, pincode } = record
                return (
                  <>
                      {item}, {cityName}, {stateName}({pincode}), {countryName}
                  </>
                )
            }
        },
        /*  {
              title: 'City Name',
              dataIndex: 'cityName',
              key: 'cityName',
              searchTextName: 'cityName',
              width: 100,
              render: (item) => {
                  return (
                      <ColumnWidth width={80}>{item}</ColumnWidth>
                  )
              }
          },

          {
              title: 'State Name',
              dataIndex: 'stateName',
              key: 'stateName',
              searchTextName: 'stateName',
              width: 100,
              render: (item) => {
                  return (
                      <ColumnWidth width={80}>{item}</ColumnWidth>
                  )
              }
          },
          {
              title: 'Country Name',
              dataIndex: 'countryName',
              key: 'countryName',
              searchTextName: 'countryName',
              width: 100,
              render: (item) => {
                  return (
                      <ColumnWidth width={80}>{item}</ColumnWidth>
                  )
              }
          },
          {
              title: 'Pincode',
              dataIndex: 'pincode',
              key: 'pincode',
              searchTextName: 'pincode',
              width: 100,
              render: (item) => {
                  return (
                      <ColumnWidth width={80}>{item}</ColumnWidth>
                  )
              }
          },*/

        {
            title: 'Date',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (record, item) => {
                return (
                  <>
                      {displayDate(item)}<br />
                  </>
                )
            }
        },

        {
            title: 'Comm.',
            width: 75,
            dataIndex: 'commission',
            key: 'commission',
            render: (item) => {
                return (
                  <>
                      {item ? `${item}%` : ''}
                  </>
                )
            }
        },


        {
            title: 'Status',
            dataIndex: 'status',
            width: 80,
            key: 'status',
            filters: statusList.map(x => ({ value: x.value, text: x.name })),
            render: (item, record) => {
                return (
                  <div style={{ width: '100px' }}>
                      {item == 'Pending' ? <label className={'label label-warning label-sm'}>Pending</label> : ''}
                      {item == 'Approved' ? <label className={'label label-success label-sm'}>Approved</label> : ''}
                      {item == 'Rejected' ? <label className={'label label-danger label-sm'}>Rejected</label> : ''}
                  </div>
                )
            }
        },
        {
            title: 'Referral Code',
            dataIndex: 'referralCode',
            width: 80,
            key: 'referralCode',
            render: (item, record) => {
                return (
                  <div style={{ width: '100px' }}>
                      {item ? <a className={'btn btn-link btn-xs'}>{item}</a> : ''}
                  </div>
                )
            }
        },

        {
            title: 'Certificate',
            dataIndex: 'certificate',
            width: 100,
            key: 'certificate',
            render: (item, record) => {
                return (
                  <div style={{ width: '100px' }}>
                      {item && item.url ? <a href={item.url} target={'_blank'}>Download</a> : null}
                  </div>
                )
            }
        },

        {
            title: 'FCMT Certificate / Contract',
            dataIndex: 'agentFcmtCertificate',
            width: 150,
            key: 'agentFcmtCertificate',
            render: (item, record) => {
                let { agentContract } = record
                return (
                  <>
                      <div style={{ width: '150px' }}>
                          <div>
                              {item && item.path ?
                                <a href={item.path} target={'_blank'}>Download Certificate</a> : null}
                          </div>
                          <div className={'mt5'}>
                              {agentContract && agentContract.path ?
                                <a href={agentContract.path} target={'_blank'}>Download Contract</a> : null}
                          </div>
                      </div>
                  </>
                )
            }
        },
        /* {
             title: 'Contract',
             dataIndex: 'agentContract',
             width: 100,
             key: 'agentContract',
             render: (item, record) => {
                 return (
                     <div style={{width: '100px'}}>
                         {item && item.path ? <a href={item.path} target={'_blank'}>Download</a> : null}
                     </div>
                 )
             }
         },*/

        /* {
             title: 'Agent',
             dataIndex: 'agentId',
             width: 100,
             key: 'agentId',
             hidden: user.userType == "agent",
             render: (item, record) => {
                 return (
                     <div style={{width: '100px'}}>
                         {item && item.name ? item.name : null}
                     </div>
                 )
             }
         },*/


        {
            title: ' Action',
            dataIndex: 'action',
            width: 100,
            render: (text, record) => {
                let { status } = record
                return (
                  <div>
                      {status == 'Pending' ?
                        <a className={'btn btn-default btn-sm'}
                           onClick={() => {
                               events.showDrawer(record._id)
                           }}>Approve Agent</a> : null}
                  </div>

                )
            }
        }
    ]

    const loadColumns = () => {
        let columnList = []
        _.each(columns, (item) => {
            if (!item.hidden) {
                columnList.push(item)
            }
        })
        return columnList
    }


    return (
      <>
          <div className='row mt-4'>
              <div className='col-lg-12'>
                  <div className='card'>
                      <div className='table-head d-flex align-items-center'>
                          <h5>All FCMT Agents: {total}</h5>
                          <div className='search-box-table'>

                          </div>
                          <div className='sort-box-table mark-btn'>

                          </div>
                      </div>
                      <div className='card-body table-responsive'>
                          <TableComp columns={loadColumns()}
                                     apiRequest={apiRequest}
                                     ref={tableRef}
                                     pagination={DefaultTablePagination()}
                          />
                      </div>
                  </div>
              </div>
              {drawerState.visible ?
                <UpdateStatusDrawer
                  {...drawerState} onClose={events.hideDrawer}
                  onSubmit={() => {
                      events.hideDrawer()
                      events.reload()
                  }} /> : null}
          </div>

      </>
    )
}

export default FcmtAgentList
