import { casitaUrl, partnerCode, apiUrl } from '../../settings'

export const singleCountryUrl = () => {
  let url = `student-accommodation/uk?partnerCode=${partnerCode}`
  return `${casitaUrl}/${url}`
}
export const universityListUrl = () => {
  return apiUrl + '/unizhome/universityList'
}
export const accommodationListUrl = (data) => {
  let { property_slug } = data
  return (
    casitaUrl + `/student-accommodation/${property_slug}?partnerCode=${partnerCode}`
  )
}
export const addEnquiryUrl = () => {
  return apiUrl + '/unizhome/enquiry'
}
export const searchDataUrl = () => {
  let url = `student-accommodation/search-data?partnerCode=${partnerCode}`
  return `${casitaUrl}/${url}`
}

export const propertiesListUrl = (data) => {
  let {
    page,
    provider_type_id,
    city,
    roomType,
    cancellationPolicy,
    facilities,
    covid,
    novisa,
    noplace
  } = data

  let baseUrl = `/student-accommodation/uk/${city}?partnerCode=${partnerCode}&page=${page}`
  if (provider_type_id) {
    baseUrl += `&provider_type_id=${provider_type_id}`
  }

  if (roomType) {
    baseUrl += `&room-types=${roomType}`
  }

  if (facilities) {
    baseUrl += `&facilities=${facilities}`
  }

  if (covid || novisa || noplace) {
    baseUrl += `&covid=${covid}&no-visa=${novisa}&no-place=${noplace}`
  }
  return casitaUrl + baseUrl
}
